import { FC } from "react";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";

import styles from "./index.module.scss";

interface Data {
  title: ITitle;
  cards: Array<{
    icon: string;
    text: string;
    href: string;
  }>;
  appearance: Appearance;
}

export const MainInsuranceCases: FC<StrapiData<Data>> = ({ data: { title, cards, appearance } }) => {
  const renderCards = () => {
    return cards?.map((card, index: number) => (
      <a href={card.href} key={`insurance-case-card-${index}`} className={styles.card}>
        <div className={styles.container}>
          <div className={styles.iconWrapper}>
            <img src={card.icon} alt="icon" className={styles.icon} />
          </div>
          <h5 className={styles.text}>{card.text}</h5>
        </div>
      </a>
    ));
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.mainInsuranceCases}>
        <Title className={styles.title} {...title} />
        <div className={styles.cards}>{renderCards()}</div>
      </div>
    </AppearanceWrapper>
  );
};
