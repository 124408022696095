import React, { FunctionComponent, useEffect, useState } from "react";
import { New } from "@mafin/icons";

import styles from "./dropdown.module.scss";

interface IProps {
  title: string;
  iconNew?: boolean;
  children: React.ReactNode;
  isHidden?: boolean;
  opened?: boolean;
  onClick?: (opened: boolean) => void;
}

export const Dropdown: FunctionComponent<IProps> = ({ title, iconNew, children, isHidden, opened, onClick }) => {
  const [display, setDisplay] = useState(opened);

  useEffect(() => {
    setDisplay(opened);
  }, [opened]);

  let itemContentStyle;
  let itemArrowStyle;
  let itemVisibilityStyle;
  let titleStyle;

  if (isHidden) {
    itemVisibilityStyle = {
      display: `none`,
    };
  } else {
    itemVisibilityStyle = {
      display: `block`,
    };
  }

  if (display) {
    itemContentStyle = {
      opacity: 1,
      height: "auto",
      marginTop: 24,
    };
    itemArrowStyle = {
      transform: `rotate(180deg)`,
    };
  } else {
    itemContentStyle = {
      opacity: 0,
      height: 0,
      overflow: "hidden",
    };
  }

  const toggleItem = () => {
    setDisplay((prev) => !prev);
    if (!onClick) return;

    onClick(!!opened);
  };

  return (
    <div
      className={styles.item_box}
      style={itemVisibilityStyle}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div className={styles.item_wrapper}>
        <div className={styles.item}>
          <div className={styles.content}>
            <div className={styles.item__top} onClick={toggleItem}>
              <h3 className={styles.item__title} style={titleStyle} itemProp="name">
                {title}
                {iconNew && <New />}
              </h3>
              <div className={styles.item__icon}>
                <div className={styles.item__icon__arrow} style={itemArrowStyle} />
              </div>
            </div>
            <div
              className={styles.item__content}
              style={itemContentStyle}
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
            >
              <div itemProp="text">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
