import React, { ReactElement, useState } from "react";
import classnames from "classnames";

import TabTitle from "./components/tab-title";
import styles from "./index.module.scss";

interface Props {
  children: ReactElement[];
}

export const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={styles.tabs}>
      <ul className={classnames(styles.list, { [styles.list__none]: children.length === 1 })}>
        {children?.length &&
          children.map((item, index) => (
            <TabTitle
              key={index}
              title={item.props.title}
              iconNew={item.props.iconNew}
              index={index}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
      </ul>
      {children?.length && children[selectedTab]}
    </div>
  );
};
