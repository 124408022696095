import { Row, RowProps } from "@/components/sections/product-comparison/components/row";
import { FC } from "react";
import { Appearance, StrapiData } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@/components/shared/typography";

import styles from "./index.module.scss";

export interface ProductComparisonData {
  leftProduct: string;
  rightProduct: string;
  rows: RowProps[];
  appearance: Appearance;
}

export const ProductComparison: FC<StrapiData<ProductComparisonData>> = ({
  data: { appearance, rightProduct, leftProduct, rows },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <div className={styles.titlesWrapper}>
          <Typography className={styles.title} type="h2" useParagraph>
            {leftProduct}
          </Typography>
          <Typography className={styles.title} type="h2" useParagraph>
            {rightProduct}
          </Typography>
        </div>
        <div className={styles.rows}>
          {rows.map((i, index) => (
            <Row {...i} key={String(index)} />
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
