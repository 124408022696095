import React, { FC } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title as ITitle, StrapiData, Appearance } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { Typography } from "@/components/shared/typography";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { Partner } from "@/utils/globalContext/types";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  partners: Partner[];
  newTab: boolean;
  relNofollow: boolean;
  appearance: Appearance;
}

export const PartnersPSO: FC<StrapiData<Data>> = ({ data: { title, partners, newTab, relNofollow, appearance } }) => {
  const sortedPartners = partners
    .sort((a, b) => a.name.localeCompare(b.name, "en"))
    .sort((a, b) => b.orderWeight - a.orderWeight);

  return sortedPartners.length ? (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <Title className={styles.title} {...title} />

        <div className={styles.partners} data-testid="partnersPso">
          {sortedPartners.map((i) => (
            <Link key={i.slug} href={`/kasko/${i.slug}`} scroll={false}>
              <a
                {...strapiLinkComponentToAnchorProps({
                  text: "",
                  url: `/kasko/${i.slug}`,
                  newTab,
                  relNofollow,
                })}
              >
                <Typography type="h3" color="primary">
                  {i.name}
                </Typography>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  ) : null;
};
