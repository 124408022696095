import { useRouter } from "next/router";
import { getStrapiMedia } from "@/utils/media";
import { useContext } from "react";

import { Image, StrapiData } from "../types";
import { GlobalContext } from "../../../pages/_app";

import styles from "./index.module.scss";

export interface Data {
  logo: Image;
}

export const InsuranceCompany = ({ data: { logo } }: StrapiData<Data>) => {
  const { partners } = useContext(GlobalContext);
  const { asPath } = useRouter();

  const getCurrentPartner = () => {
    for (const partner of partners) {
      const re = new RegExp(`^${partner.href}([#/].*)*$`);
      const result = asPath.match(re);

      if (result) {
        return partner;
      }
    }
  };

  const currentPartner = getCurrentPartner();

  return (
    <div className={styles.insurance_company}>
      <img
        className={styles.image}
        src={getStrapiMedia(currentPartner?.icon?.url || logo?.url)}
        alt={currentPartner?.icon?.url || logo?.alternativeText || "Partners logo"}
      />
    </div>
  );
};
