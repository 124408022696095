export const sleep = (ms: number): Promise<void> =>
  new Promise((res) => {
    setTimeout(() => {
      res();
    }, ms);
  });

export const range = (start: number, end?: number, step = 1) => {
  const output = [];

  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }

  for (let i = start; i < end; i += step) {
    output.push(i);
  }

  return output;
};

export const withRetryHandling =
  <TArgs extends unknown[], TReturn>({
    callback,
    returnValidator,
    delay,
    totalTime,
  }: {
    callback: (...args: TArgs) => TReturn;
    returnValidator: Function;
    delay: number;
    totalTime: number;
  }) =>
  (...args: TArgs) => {
    const endTime = Date.now() + totalTime;

    const retry = async (): Promise<TReturn | undefined> => {
      const response = await callback(...args);

      if (response && returnValidator(response)) {
        return response;
      }

      if (Date.now() < endTime) {
        return new Promise((resolve) => setTimeout(() => resolve(retry()), delay));
      }

      return;
    };

    return retry();
  };
