import { memo, useEffect } from "react";
import { Button, Checkbox, Dropdown, Link, Tooltip } from "@mafin/ui-kit";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Information } from "@/components/shared/information";
import {
  DOCUMENTS,
  NUMBER_OF_PARTICIPANTS,
} from "@/components/sections/insurance-case/insurance-case-information/constants";

import cn from "classnames";

import styles from "./index.module.scss";

const DOCUMENT_DEFAULT_VALUE = DOCUMENTS[0];
const NUMBER_OF_PARTICIPANTS_DEFAULT_VALUE = NUMBER_OF_PARTICIPANTS[0];

export interface InsuranceCaseInformationForm {
  numberOfParticipants: { id: string; name: string };
  document: { id: string; name: string };
  noOSAGO: boolean;
  damagedProperty: boolean;
  carIsBroken: boolean;
}

interface InsuranceCaseInformationProps {
  information: InsuranceCaseInformationForm | null;

  onSubmit(data: InsuranceCaseInformationForm): void;

  onValid(valid: boolean): void;
}

export const InsuranceCaseInformation = memo<InsuranceCaseInformationProps>(function InsuranceCaseInformation({
  onSubmit,
  onValid,
  information,
}) {
  const { control, handleSubmit, watch, reset } = useForm<InsuranceCaseInformationForm>();

  const numberOfParticipants = watch("numberOfParticipants", NUMBER_OF_PARTICIPANTS_DEFAULT_VALUE);
  const document = watch("document", DOCUMENT_DEFAULT_VALUE);
  const noOSAGO = watch("noOSAGO");
  const carIsBroken = watch("carIsBroken");

  // если только один участник в дтп, или выбран иной документ, или если у других участников нет осаго, то оправляем в колл центр
  const isCaseForCallCenter =
    numberOfParticipants?.id === NUMBER_OF_PARTICIPANTS[2].id || document?.id === DOCUMENTS[3].id || noOSAGO;

  const canContinue = !carIsBroken && !isCaseForCallCenter;

  const handleFormSubmit: SubmitHandler<InsuranceCaseInformationForm> = (data) => onSubmit(data);

  const renderInformation = () => {
    if (carIsBroken) {
      return (
        <Information className={styles.information}>
          Рекомендуем направить запрос в Страховую компанию, используя{" "}
          <Link
            target="_blank"
            href="https://www.absolutins.ru/strahovoj-sluchaj/fizicheskie-lica/obshchaya-informatsiya/"
          >
            форму Обратной связи на сайте компании Абсолют Страхование
          </Link>{" "}
          в разделе «Страховой случай»
        </Information>
      );
    }

    if (isCaseForCallCenter) {
      return (
        <Information className={styles.information}>
          Вы можете заявить событие через службу поддержки Mafin <Link href="tel:88005556439">8 (800) 555-64-39</Link>
        </Information>
      );
    }

    return null;
  };

  useEffect(() => {
    onValid(canContinue);
  }, [canContinue, onValid]);

  useEffect(() => {
    if (!information) {
      reset({
        document: DOCUMENT_DEFAULT_VALUE,
        numberOfParticipants: NUMBER_OF_PARTICIPANTS_DEFAULT_VALUE,
      });
    }
  }, [information, reset]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Controller
          name="numberOfParticipants"
          control={control}
          defaultValue={NUMBER_OF_PARTICIPANTS_DEFAULT_VALUE}
          render={({ field }) => (
            <Dropdown
              options={NUMBER_OF_PARTICIPANTS}
              label="Количество участников ДТП"
              chooseOption={field.onChange}
              selectedOption={field.value}
            />
          )}
        />

        <Controller
          name="document"
          control={control}
          defaultValue={DOCUMENT_DEFAULT_VALUE}
          render={({ field }) => (
            <Dropdown
              options={DOCUMENTS}
              label="На месте ДТП был оформлен документ"
              chooseOption={field.onChange}
              selectedOption={field.value}
            />
          )}
        />

        <Button
          disabled={!canContinue}
          onClick={handleSubmit(handleFormSubmit)}
          className={cn(styles.nextButton, styles.desktop)}
        >
          Далее
        </Button>
      </div>
      <div className={styles.row}>
        <div className={styles.checkboxWithTooltip}>
          <Controller
            name="noOSAGO"
            control={control}
            render={({ field }) => <Checkbox {...field} label="У другого участника нет ОСАГО" checked={field.value} />}
          />
          <Tooltip direction="up" mobileBtnText="Закрыть">
            <div className={styles.tooltipContent}>
              <h4>Где посмотреть наличие полиса ОСАГО у второго участника</h4>
              <p>
                <b>В европротоколе</b> — вписывается в столбце о втором участнике
              </p>
              <p>
                <b>В протоколе об административном правонарушении</b> — указан в описании ДТП (посередине)
              </p>
              <p>
                <b>Действительность полиса второго участника</b> можно проверить на сайте Российского союза
                автостраховщиков
              </p>
            </div>
          </Tooltip>
        </div>
        <div className={styles.checkboxWithTooltip}>
          <Controller
            name="damagedProperty"
            control={control}
            render={({ field }) => <Checkbox {...field} label="Повреждено иное имущество" checked={field.value} />}
          />
          <Tooltip direction="up" mobileBtnText="Закрыть">
            <div className={styles.tooltipContent}>
              <p>Столб, стена, забор и т.д.</p>
            </div>
          </Tooltip>
        </div>
        <Controller
          name="carIsBroken"
          control={control}
          render={({ field }) => <Checkbox {...field} label="Автомобиль не на ходу" checked={field.value} />}
        />
        <Button
          disabled={!canContinue}
          onClick={handleSubmit(handleFormSubmit)}
          className={cn(styles.nextButton, styles.mobile)}
        >
          Далее
        </Button>
      </div>
      {renderInformation()}
    </div>
  );
});
