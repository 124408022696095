import { MortgageTest } from "@/components/elements/sravni-wl/MortgageTest";

import { Mortgage, Vrz, Ns, Realty } from "../../elements/sravni-wl";
import { StrapiData } from "../types";

interface Data {
  script_type: string;
}

export const ScriptSection = ({ data }: StrapiData<Data>) => {
  const { script_type } = data;

  switch (script_type) {
    case "vrz":
      return <Vrz />;
    case "ns":
      return <Ns />;
    case "mortgage":
      return <Mortgage />;
    case "mortgage_test":
      return <MortgageTest />;
    case "realty":
      return <Realty />;
  }

  return null;
};
