import React from "react";
import { IconLink } from "@mafin/ui-kit";
import { getStrapiMedia } from "@/utils/media";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";

import { Image, StrapiData, Link, Title as ITitle, Appearance } from "../types";

import styles from "./index.module.scss";

interface Data {
  row_1: string;
  row_2: string;
  list: string;
  image: Image;
  link: Link;
  title?: ITitle;
  appearance: Appearance;
}

export const PolicyGenuine = ({ data }: StrapiData<Data>) => {
  const { title, row_1, row_2, list, image, link, appearance } = data;

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.policy_genuine}>
        {title?.text && <Title {...title} className={styles.title} />}
        <HTMLFromRichTextEditor className={styles.row_1} htmlString={row_1} />
        <HTMLFromRichTextEditor className={styles.row_2} htmlString={row_2} />
        <HTMLFromRichTextEditor className={styles.row} htmlString={list} />
        <img className={styles.image} src={getStrapiMedia(image.url)} alt={title?.text} />
        <IconLink className={styles.icon_link} {...strapiLinkComponentToAnchorProps(link)} variant="tertiary">
          {link.text}
        </IconLink>
      </div>
    </AppearanceWrapper>
  );
};
