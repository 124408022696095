import { StrapiData } from "../types";
import { ContactCard, ContactCardProps } from "../../elements/contact-card";

import styles from "./index.module.scss";

interface ContactUsProps {
  title: string;
  contact_card: ContactCardProps[];
}

export const ContactUs = ({ data }: StrapiData<ContactUsProps>) => {
  const cards = data.contact_card.map((card, index) => <ContactCard key={`contact-card${index}`} {...card} />);

  return (
    <div className={styles.contact_us}>
      <h2 className={styles.title}>{data.title}</h2>
      <div className={styles.content}>{cards}</div>
    </div>
  );
};
