import { memo } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Card, CardProps } from "@/components/sections/flip-cards/components/Card";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { useWindowSize } from "@/utils/hooks/use-window-size";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { range } from "@/utils/common";

import styles from "./index.module.scss";

export interface FlipCardsData {
  appearance: Appearance;
  text: string;
  cards: CardProps[];
}

SwiperCore.use([Pagination]);

export const FlipCards = memo<StrapiData<FlipCardsData>>(({ data: { appearance, text, cards } }) => {
  const size = useWindowSize();
  const mobileBreakpoint = 979;

  const getBigCardsIndexes = () => {
    const cardsPerRow = 3;
    const bigCardsCount = cards.length % cardsPerRow;

    return range(1, bigCardsCount + 1).map((i) => cards.length - i);
  };

  const bigCards = getBigCardsIndexes();

  const renderDesktopCards = () => (
    <div className={styles.desktopCards}>
      {cards.map((i, index) => (
        <Card big={bigCards.includes(index)} key={String(index)} {...i} />
      ))}
    </div>
  );

  const renderMobileCards = () => (
    <Swiper
      slidesPerView={1.4}
      spaceBetween={8}
      className={styles.mobileCards}
      breakpoints={{
        368: {
          slidesPerView: 1.6,
        },
      }}
      pagination={{
        clickable: true,
      }}
    >
      {cards.map((i, index) => (
        <SwiperSlide className={styles.slide} key={`relevant-${index}`}>
          <Card key={String(index)} {...i} />
        </SwiperSlide>
      ))}
    </Swiper>
  );

  const renderCards = () => {
    if (!size.width || size.width > mobileBreakpoint) {
      return renderDesktopCards();
    }

    return renderMobileCards();
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <HTMLFromRichTextEditor className={styles.text} htmlString={text} />
        {renderCards()}
      </div>
    </AppearanceWrapper>
  );
});
