import { memo } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Input, Textarea } from "@mafin/ui-kit";
import { validateDate, validateTime } from "@/components/sections/insurance-case/circumstances/utils";

import styles from "./index.module.scss";

export interface CircumstancesFrom {
  date: string;
  time: string;
  place: string;
  whatHappened: string;
}

interface CircumstancesProps {
  errors: FieldErrors<CircumstancesFrom>;
  control: Control<CircumstancesFrom, Object>;
}

export const Circumstances = memo<CircumstancesProps>(function Circumstances({ control, errors }) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Controller
          name="date"
          rules={{
            required: { value: true, message: "Обязательно" },
            validate: { isDateValid: (value) => validateDate(value) || "Неверный формат" },
          }}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              error={errors.date?.message}
              maskedProps={{ mask: "99.99.2099" }}
              label="Дата происшествия"
              {...field}
            />
          )}
        />
        <Controller
          name="time"
          rules={{
            required: { value: true, message: "Обязательно" },
            validate: { isTimeValid: (value) => validateTime(value) || "Неверный формат" },
          }}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input error={errors.time?.message} maskedProps={{ mask: "99:99" }} label="Время происшествия" {...field} />
          )}
        />
        <Controller
          name="place"
          rules={{
            required: { value: true, message: "Обязательно" },
            maxLength: { value: 150, message: "Превышен лимит символов" },
            pattern: {
              value: /^[?!$%:"';#№@,.а-яА-ЯёЁ0-9\s]+$/,
              message: "Неверный формат (допустима только кириллица)",
            },
          }}
          control={control}
          defaultValue=""
          render={({ field }) => <Input error={errors.place?.message} label="Место происшествия" {...field} />}
        />
      </div>
      <Controller
        name="whatHappened"
        rules={{
          required: { value: true, message: "Обязательно" },
          maxLength: { value: 1000, message: "Превышен лимит символов" },
          pattern: {
            value: /^[?!$%:"';#№@,.а-яА-ЯёЁ0-9\s]+$/,
            message: "Неверный формат (допустима только кириллица)",
          },
        }}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Textarea
            error={errors.whatHappened?.message}
            title="Что произошло?"
            rows={5}
            maxLength={1000}
            placeholder="Опишите, что произошло?"
            {...field}
          />
        )}
      />
    </div>
  );
});
