import React, { FC } from "react";
import cn from "classnames";
import { IconLink } from "@mafin/ui-kit";
import { Link, StrapiData } from "@/components/sections/types";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

export interface Data {
  title?: string;
  link?: Link & { newTab?: boolean; hidden?: boolean; relNofollow?: boolean };
  text?: string;
}

export const MoreAboutRisk: FC<StrapiData<Data>> = ({ data: { link, title, text } }) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.container, link?.hidden && styles.hideLink)}>
        <h1 className={styles.title}>{title}</h1>
        {!link?.hidden && (
          <IconLink href={link?.url} variant="tertiary" className={cn(styles.link, link?.hidden && styles.hidden)}>
            {link?.text}
          </IconLink>
        )}
        {text && <HTMLFromRichTextEditor className={styles.text} htmlString={text} />}
      </div>
    </div>
  );
};
