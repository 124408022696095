import React, { FC } from "react";
import cn from "classnames";
import { getStrapiMedia } from "@/utils/media";
import { Link } from "@mafin/ui-kit";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { Link as ILink } from "@/components/sections/types";
import { Typography } from "@/components/shared/typography";

import styles from "./index.module.scss";

export interface KaskoProtectCardProps {
  cover: { url: string };
  title: string;
  description: string;
  link: ILink;
  className?: string;
}

export const KaskoProtectCard: FC<KaskoProtectCardProps> = ({ cover, title, description, link, className }) => {
  const url = getStrapiMedia(cover?.url);

  return (
    <div data-testid="KaskoProtectCard" className={cn(styles.wrapper, className)}>
      <Link {...strapiLinkComponentToAnchorProps(link)}>
        <div className={styles.container}>
          <div>
            <img className={styles.cover} src={url} alt="cover" />
            <div className={styles.infoContainer}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
          </div>
          <Typography type="littleButtonText" className={styles.link}>
            Подробнее
          </Typography>
        </div>
      </Link>
    </div>
  );
};
