import { FC } from "react";
import { Typography } from "@mafin/ui-kit";
import cn from "classnames";

import styles from "./index.module.scss";

export interface VariantChipProps {
  active?: boolean;
  title: string;
  onClick(): void;
}

export const VariantChip: FC<VariantChipProps> = ({ active, title, onClick }) => {
  return (
    <Typography
      onClick={onClick}
      className={cn(styles.chip, active && styles.active)}
      variant="semiboldSimpleText"
      color={active ? "var(--white)" : "var(--main)"}
    >
      {title}
    </Typography>
  );
};
