import React from "react";
import classnames from "classnames";
import { getStrapiMedia } from "@/utils/media";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { Image, Link, StrapiData } from "../types";

import styles from "./index.module.scss";

interface Data {
  title: string;
  subtitle: string;
  description: string;
  image: Image;
  download_link: Link;
}

export const DownloadApp = ({ data }: StrapiData<Data>) => {
  const { title, subtitle, description, image, download_link } = data;

  return (
    <div className={styles.download_app}>
      <div className={styles.desktop}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>
          <div className={styles.content__column}>
            <p className={styles.subtitle}>{subtitle}</p>
            <img src={getStrapiMedia(image.url)} alt={title} />
          </div>
          <div className={styles.content__column}>
            <HTMLFromRichTextEditor className={styles.description} htmlString={description} />
            <div className={styles.buttons}>
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a
                href="https://redirect.appmetrica.yandex.com/serve/315474353179400359"
                className={classnames(styles.link, styles.link__apple)}
                target="_blank"
                rel="noreferrer nofollow"
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a
                href="https://redirect.appmetrica.yandex.com/serve/315474354018248399"
                className={classnames(styles.link, styles.link__google)}
                target="_blank"
                rel="noreferrer nofollow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <ButtonLink {...strapiLinkComponentToAnchorProps(download_link)}>{download_link.text}</ButtonLink>
      </div>
    </div>
  );
};
