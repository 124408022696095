import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { Border } from "@/components/shared/border";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface TextBannerProps {
  appearance: Appearance;
}

export const StepsMobile: FC<StrapiData<TextBannerProps>> = ({ data: { appearance } }) => {
  const steps = [
    "<h4>Введите данные<br>авто и водителей</h4><p>Они обрабатываются<br>по законодательству РФ</p>",
    "<h4>Выберите лучшее<br>предложение</h4><p>Mafin покажет все доступные<br>варианты, условия и цены</p>",
    "<h4>Оплатите и получите<br>полис на email</h4><p>Полис придет вам на email и будет добавлен в базы ГИБДД и РСА</p>",
  ];

  const renderSteps = () =>
    steps.map((text, index) => (
      <div className={style.item} key={`StepsMobile${index}`}>
        <Typography variant="h2" className={style.icon}>
          {index + 1}
        </Typography>
        <HTMLFromRichTextEditor htmlString={text} className={style.text} />
      </div>
    ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Border>
          <Typography variant="h2" component="h2">
            Три простых шага — и полис ваш
          </Typography>
          <div className={style.items}>{renderSteps()}</div>
        </Border>
      </Container>
    </AppearanceWrapper>
  );
};
