import { FC } from "react";
import { useRouter } from "next/router";

export const PreviewModeBanner: FC = () => {
  const router = useRouter();

  return (
    <div>
      <div>
        Preview mode is on. <a href={`/api/exit-preview?redirect=${router.asPath}`}>Turn off</a>
      </div>
    </div>
  );
};
