import React, { memo } from "react";
import Image from "next/image";
import { Slide as ISlide } from "@/components/sections/types";
import cn from "classnames";
import { getStrapiMedia } from "@/utils/media";

import styles from "./index.module.scss";

interface SlideProps extends ISlide {
  isActive?: boolean;
}

export const Slide = memo<SlideProps>(function Slide({ subtitle, title, image, showMoreTitle, showMore, isActive }) {
  return (
    <div data-testid="Slider-Slide" className={styles.slide}>
      <Image
        className={styles.image}
        src={getStrapiMedia(image[0].url)}
        alt={title}
        sizes="(max-width: 979px) 1600px, 2560px"
        objectFit="cover"
        layout="fill"
        quality={70}
        priority
      />
      <div className={styles.inner}>
        {isActive && <h1 className={cn(styles.title, styles.h1)}>{title}</h1>}
        {!isActive && <h2 className={cn(styles.title, styles.h2)}>{title}</h2>}
        <p className={styles.subtitle}>{subtitle}</p>
        <a className={styles.link} href={showMore}>
          {showMoreTitle || "Подробнее"}
        </a>
      </div>
    </div>
  );
});
