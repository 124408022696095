import { KeyboardEvent, useState } from "react";
import cn from "classnames";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { Rating, Typography } from "@mafin/ui-kit";
import { Arrow } from "@mafin/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Keyboard } from "swiper";
import { format } from "date-fns";
import { CustomerReview } from "@/utils/customerReviews/types";
import { Title as ITitle, StrapiData, Appearance } from "@/components/sections/types";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  customerReviews: CustomerReview[];
  appearance: Appearance;
}

SwiperCore.use([Navigation, Pagination, Keyboard]);

export const CustomerReviews = ({ data: { title, customerReviews, appearance } }: StrapiData<Data>) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const getRatingInPercentage = (fivePointRating: number) => fivePointRating * 20;
  const separatorDot = "\u00A0\u00A0\u2022\u00A0\u00A0";

  const swiperParams: Swiper = {
    onSwiper: setSwiper,
    spaceBetween: 20,
    pagination: true,
    slidesPerView: "auto" as const,
    onPaginationRender: (_, el) => el.classList.add(styles.swiperPagination),
    breakpoints: {
      980: {
        pagination: false,
        navigation: {
          prevEl: `.${styles.arrowPrev}`,
          nextEl: `.${styles.arrowNext}`,
          disabledClass: styles.arrowDisabled,
        },
      },
    },
    updateOnWindowResize: true,
    keyboard: {
      enabled: true,
    },
  };

  const handleKeyDown = (callback?: () => void) => (e: KeyboardEvent<HTMLDivElement>) => {
    if (callback) {
      if (e.key === "13" || e.key === "Enter") {
        callback.bind(swiper)();
      }
    }
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title className={styles.title} {...title} />
        <div className={styles.arrows}>
          <div
            className={cn(styles.arrow, styles.arrowPrev)}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown(swiper?.slidePrev)}
          >
            <Arrow direction="left" size="small" />
          </div>
          <div
            className={cn(styles.arrow, styles.arrowNext)}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown(swiper?.slideNext)}
          >
            <Arrow direction="right" size="small" />
          </div>
        </div>
        <Swiper className={styles.swiperContainer} {...swiperParams}>
          {customerReviews?.map((card, index) => (
            <SwiperSlide
              key={`customerReview${index}`}
              className={styles.slide}
              data-testid="slide"
              itemScope
              itemType="https://schema.org/Review"
            >
              <div className={styles.slideTop}>
                <Typography
                  variant="h5"
                  color="var(--main)"
                  itemScope
                  itemProp="author"
                  itemType="https://schema.org/Person"
                >
                  <span itemProp="name">{card.userName}</span>
                </Typography>
                <div itemProp="itemReviewed" itemScope itemType="https://schema.org/Organization">
                  <meta itemProp="name" content="Mafin" />
                </div>
                <div itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
                  <Rating rating={getRatingInPercentage(card.rating)} className={styles.rating} />
                  <meta itemProp="ratingValue" content={String(card.rating)} />
                </div>
              </div>
              <Typography variant="littleLink" color="var(--additional)" className={styles.slideMiddle}>
                {card.userCity + separatorDot + format(new Date(card.reviewDate), "dd-MM-yy")}
                <meta itemProp="datePublished" content={format(new Date(card.reviewDate), "yyyy-MM-dd")} />
              </Typography>
              <Typography variant="h5" color="var(--main)" className={styles.slideReviewTitle} itemProp="name">
                {card.reviewTitle}
              </Typography>
              <Typography variant="simpleText" color="var(--main)" itemProp="reviewBody">
                {card.reviewText}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </AppearanceWrapper>
  );
};
