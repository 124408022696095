import { memo } from "react";

import { ActionsProps, ApplicationContainer } from "../../elements/ApplicationContainer";
import { StrapiData } from "../types";

export const Application = memo(function Application({ data }: StrapiData<ActionsProps>) {
  return (
    <div>
      <ApplicationContainer data={data} />
    </div>
  );
});
