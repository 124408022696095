import { getStrapiMedia } from "@/utils/media";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import React from "react";

import { Image, StrapiData } from "../types";

import styles from "./index.module.scss";

interface Data {
  title: string;
  description: string;
  image: Image[];
}

export const Safety = ({ data }: StrapiData<Data>) => {
  return (
    <div className={styles.safety}>
      <div className={styles.wrapper}>
        {data && data.image[0] && (
          <img className={styles.image} src={getStrapiMedia(data.image[0].url)} alt={data.image[0].alternativeText} />
        )}
        <div className={styles.content}>
          <p className={styles.title}>{data.title}</p>
          <HTMLFromRichTextEditor className={styles.text} htmlString={data.description} />
        </div>
      </div>
    </div>
  );
};
