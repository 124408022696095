import { useEffect, useState } from "react";
import { Expert } from "@/utils/api/types/main-site";
import { getExperts } from "@/utils/api/requests/main-site";

export const useExperts = () => {
  const [experts, setExperts] = useState<Expert[]>([]);

  useEffect(() => {
    getExperts().then((res) => {
      if (res) {
        setExperts(res);
      }
    });
  }, []);

  return experts;
};
