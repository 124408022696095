import { FC } from "react";
import { NewsList } from "@/components/mafin-media/sections/shared/news-list";
import { INews } from "@/utils/api/types/mafin-media";
import cn from "classnames";
import { Typography } from "@/components/shared/typography";
import { ButtonLink } from "@/components/elements/button-link";
import Link from "next/link";
import { MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";

import styles from "./index.module.scss";

interface NewsProps {
  news: INews[];
  className?: string;
  onlyCards?: boolean;
}

export const News: FC<NewsProps> = ({ news, className, onlyCards = false }) => {
  return (
    <div className={cn(styles.container, className)}>
      {!onlyCards && (
        <Typography type="h2" className={styles.title}>
          Новости
        </Typography>
      )}
      <NewsList news={news} sliderInMobile />
      {!onlyCards && (
        <Link href={MAFIN_MEDIA_ROUTES.news}>
          <ButtonLink className={styles.button} href={MAFIN_MEDIA_ROUTES.news}>
            Все новости
          </ButtonLink>
        </Link>
      )}
    </div>
  );
};
