import { FC, ReactNode } from "react";
import cn from "classnames";

import style from "./index.module.scss";

interface ContainerProps {
  className?: string;
  children: ReactNode;
}

export const Container: FC<ContainerProps> = ({ className, children }) => {
  return <div className={cn(style.container, className)}>{children}</div>;
};
