import React from "react";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

interface StepProps {
  step: number;
  title: string;
  content: string;
}

export const Step: React.FC<StepProps> = ({ step, title, content }) => {
  return (
    <div data-testid="Steps-Step" className={styles.step}>
      <div data-testid="step" className={styles.circle}>
        {step}
      </div>
      <p className={styles.title}>{title}</p>
      <HTMLFromRichTextEditor className={styles.content} htmlString={content} />
    </div>
  );
};
