import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import cn from "classnames";
import { Arrow } from "@mafin/icons";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { useWindowSize } from "@/utils/hooks/use-window-size";
import { Appearance, Link, StrapiData } from "@/components/sections/types";

import styles from "./index.module.scss";

export interface UsefulLinksData {
  title: { text: string };
  links: Link[];
  appearance: Appearance;
}

SwiperCore.use([Pagination, Navigation]);

export const UsefulLinks = ({ data }: StrapiData<UsefulLinksData>) => {
  const { title, links, appearance } = data;

  const size = useWindowSize();
  const mobileBreakpoint = 979;

  const dumbChunk = (arr: Link[]) => {
    const res = [];
    const arrCopy = [...arr];
    const limit = Math.ceil(links.length / 4);

    for (let i = 0; i < limit; i++) {
      const tmp = [];

      for (let j = 0; j < 4; j++) {
        if (arrCopy.length) {
          tmp.push(arrCopy.shift());
        }
      }
      res.push(tmp);
    }

    return res;
  };

  const linksBlock = dumbChunk(links);

  const mobileLinks = (
    <>
      <Swiper
        pagination={{
          //eslint-disable-next-line prefer-template
          el: "." + styles.pagination,
          type: "bullets",
          bulletElement: "span",
          bulletClass: styles.pagination_bullet,
          bulletActiveClass: styles.pagination_bullet_active,
          clickable: true,
        }}
        navigation={{
          nextEl: ".link-swiper-arrow-next",
          prevEl: ".link-swiper-arrow-prev",
        }}
        className={styles.slider}
        slidesPerView={1}
      >
        {linksBlock.map((links, index) => {
          return (
            <SwiperSlide key={`advantage_slidecard_${index}`} className={styles.slide}>
              <div className={styles.links_block}>
                {links.map((link, index) => (
                  <a
                    key={`useful_link${index}`}
                    className={styles.link}
                    {...strapiLinkComponentToAnchorProps(link as Link)}
                  >
                    {link?.text}
                  </a>
                ))}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={cn("link-swiper-arrow-prev", styles.arrow, styles.arrow__prev)}>
        <Arrow direction="left" />
      </div>
      <div className={cn("link-swiper-arrow-next", styles.arrow, styles.arrow__next)}>
        <Arrow direction="right" />
      </div>
      <div className={cn("link-swiper-pagination-bullets", styles.pagination)} />
    </>
  );

  const desktopLinks = links.map((link, index) => (
    <a key={`useful_link${index}`} className={styles.link} {...strapiLinkComponentToAnchorProps(link)}>
      {link.text}
    </a>
  ));

  const renderLinks = () => {
    if (size.width! > mobileBreakpoint || !size.width) {
      return <div className={styles.desktop}>{desktopLinks}</div>;
    }

    if (size.width! <= mobileBreakpoint) {
      return <div className={styles.mobile}>{mobileLinks}</div>;
    }
  };

  return (
    <AppearanceWrapper className={styles.container} appearance={appearance}>
      <div className={styles.useful_links}>
        {title && <h2 className={styles.title}>{title.text}</h2>}
        {renderLinks()}
      </div>
    </AppearanceWrapper>
  );
};
