import { FC } from "react";
import cn from "classnames";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { Tooltip } from "@mafin/ui-kit";
import { Typography } from "@/components/shared/typography";

import { ITab } from "../ProductDescriptionWithTabs";

import styles from "./index.module.scss";

export const TabContent: FC<ITab> = ({ mainCards, redCard, button }) => {
  return (
    <div className={styles.tabContent}>
      <div className={styles.mainCards}>
        {mainCards.map((mainCard, index) => (
          <HTMLFromRichTextEditor
            key={`descriptionCard${index}`}
            className={cn(styles.card, styles.mainCard)}
            htmlString={mainCard.row}
          />
        ))}
      </div>
      <div className={styles.redBlock}>
        <div className={cn(styles.card, styles.redCard)}>
          <HTMLFromRichTextEditor htmlString={redCard.text} />
          <ul>
            {redCard.listWithTooltip.map((item) => (
              <li key={item.text}>
                <div className={styles.dot} />
                <Typography type="simpleText" className={styles.liText}>
                  {item.text}
                </Typography>
                {item.tooltipText && (
                  <Tooltip direction="left" mobileBtnText="Закрыть" className={styles.tooltip}>
                    {item.tooltipText}
                  </Tooltip>
                )}
              </li>
            ))}
          </ul>
        </div>
        <ButtonLink {...strapiLinkComponentToAnchorProps(button)} className={styles.button}>
          {button.text}
        </ButtonLink>
      </div>
    </div>
  );
};
