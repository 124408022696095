import React, { useState } from "react";
import { Button } from "@mafin/ui-kit";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Dropdown } from "@/components/elements/dropdown";

import styles from "./index.module.scss";

interface Dropdown {
  title: string;
  content: string;
}

interface Data {
  title: string;
  dropdowns: Dropdown[];
  button: { text: string };
  appearance: Appearance | null;
}

export const Faq = ({ data: { title, dropdowns, button, appearance } }: StrapiData<Data>) => {
  const [showAll, setShowAll] = useState(false);

  const content = dropdowns?.map((item, index) => {
    return (
      <Dropdown key={`faq_dropdown_${index}`} title={item.title}>
        <HTMLFromRichTextEditor htmlString={item.content} />
      </Dropdown>
    );
  });

  const fiveItems = content.splice(0, 5);

  const onClick = () => {
    setShowAll(!showAll);
  };

  const renderButton = () => {
    if (dropdowns.length > 5) {
      return (
        <Button variant="secondary" className={styles.button} onClick={onClick}>
          {showAll ? "Скрыть" : button.text}
        </Button>
      );
    }
  };

  return (
    <AppearanceWrapper appearance={appearance || { background: "Bg", paddingTop: true, paddingBottom: true }}>
      <div className={styles.wrapper} itemScope itemType="https://schema.org/FAQPage">
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>
          {fiveItems}
          {showAll && content}
        </div>
        {renderButton()}
      </div>
    </AppearanceWrapper>
  );
};
