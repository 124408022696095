import { FC } from "react";
import { SeoMediaMaterial, SeoMediaMaterialProps } from "@/components/elements/seo-media-material";
import { Appearance, StrapiData, Title } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";

import styles from "./index.module.scss";

interface Data {
  title: Title;
  materials: SeoMediaMaterialProps[];
  appearance: Appearance;
}

export const SeoMediaBlock: FC<StrapiData<Data>> = ({ data: { title, materials, appearance } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title?.text}</h2>
        <div className={styles.materials}>
          {materials.map((material) => (
            <SeoMediaMaterial key={`material-${material.link?.text}`} {...material} />
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
