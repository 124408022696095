/* eslint-disable max-lines*/
import React from "react";

import classnames from "classnames";

import styles from "./index.module.scss";

export const EuroprotocolTemplate = () => {

  return (
    <div className={styles.europrotocol_template}>
      <div className={classnames(styles.v3_section__content, styles.v3_accident__content, styles.mobile_active)}>
        <div className={styles.v3_accident__instruction}>
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Лицевую сторону Извещения о&nbsp;ДТП
            заполняют совместно и&nbsp;подписывают оба водителя. Каждый водитель выбирает любую колонку
            (&laquo;А&raquo; или &laquo;В&raquo;) и&nbsp;вносит информацию о&nbsp;своем транспортном средстве.</p>
          <div className={styles.v3_spacer_m21} />
          <div className={styles.v3_accident__instruction_policy_side_1} />
          <div className={styles.v3_spacer_m21} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 1.</span>&nbsp;Место ДТП</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите город, улицу и&nbsp;дом
            (ближайшее к&nbsp;ДТП здание). Если авария произошла на&nbsp;перекрестке&nbsp;&mdash; пересечение улиц,
            если не&nbsp;в&nbsp;населенном пункте&nbsp;&mdash; название трассы, километр и&nbsp;направление
            движения.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 2.</span>&nbsp;Дата ДТП</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите дату и&nbsp;время ДТП.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 3.</span>&nbsp;Свидетели ДТП</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя
            и&nbsp;отчество (если оно есть),<br className={styles.mobile_active} />а также адрес свидетелей ДТП.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 4.</span>&nbsp;Марка,
            модель&nbsp;ТС</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Внесите данные из&nbsp;свидетельства
            о&nbsp;регистрации <br className={styles.mobile_active} />ТС (СТС).</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 5.</span>&nbsp;Собственник&nbsp;ТС
          </p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя, отчество
            и&nbsp;адрес собственника авто из&nbsp;СТС.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 6.</span>&nbsp;Водитель&nbsp;ТС</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя, отчество,
            дату рождения, адрес регистрации и&nbsp;телефон водителя, а&nbsp;также данные его водительского
            удостоверения.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 7.</span>&nbsp;Страховщик</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите страховую компанию
            из&nbsp;полиса ОСАГО, <br className={styles.mobile_active} />номер полиса и&nbsp;дату окончания его действия. Если
            у&nbsp;вас есть полис КАСКО, в&nbsp;пункте &laquo;ТС&nbsp;застраховано от&nbsp;ущерба&raquo; отметьте
            &laquo;да&raquo;.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 8.</span>&nbsp;Место
            первоначального удара</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите стрелкой место
            первоначального удара, изображать повреждения здесь не&nbsp;нужно.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 9.</span>&nbsp;Характер
            и&nbsp;перечень видимых поврежденных деталей и&nbsp;элементов</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Перечислите все видимые повреждения и
            опишите их характер, используйте термины «царапина», «вмятина», «деформация», «разрыв», «трещина».</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 10.</span>&nbsp;Замечания</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите, признаете&nbsp;ли
            вы&nbsp;свою вину или считаете себя невиновным в&nbsp;ДТП.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 11.</span>&nbsp;Обстоятельства ДТП
          </p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> При помощи комбинации пунктов
            опишите обстоятельства ДТП, каждый водитель отмечает клетки в&nbsp;соответствии со&nbsp;своей колонкой
            ( <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_normal, styles.v3_color_red)}> &laquo;А&raquo;</span> <br className={styles.mobile_active} />или  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_normal, styles.v3_color_red)}> &laquo;В&raquo;</span>).
            В&nbsp;последнем пункте укажите количество отмеченных клеток в&nbsp;своем столбике.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 12.</span>&nbsp;Схема ДТП</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Нарисуйте схему ДТП. Схематично
            изобразите дорогу с&nbsp;названием улицы и&nbsp;номером дома, линии разметки, расположение светофоров
            и&nbsp;дорожных знаков, другие ориентиры. Автомобили можно изобразить простыми прямоугольниками, укажите
            их&nbsp;расположение в момент столкновения и&nbsp;конечное положение, при <br className={styles.mobile_active} />этом
            обозначьте направления движения машин.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)}> 13.</span>&nbsp;Подписи водителей
          </p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Подписи водителей здесь обязательны:
            они удостоверяют отсутствие разногласий по обстоятельствам ДТП и&nbsp;причиненным повреждениям.</p>
        </div>
      </div>

      <div className={classnames(styles.v3_section__content, styles.v3_accident__content, styles.v3_accident__content_padd_bottom,
        styles.desktop_active)}>
        <div className={styles.v3_accident__instruction}>
          <div className={styles.v3_accident__instruction_policy_side_1}>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_1)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>
                  <span
                    className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} > 1.</span>&nbsp;Место
                  ДТП</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Укажите город, улицу и&nbsp;дом
                  (ближайшее к&nbsp;ДТП здание). Если авария произошла на&nbsp;перекрестке&nbsp;- пересечение
                  улиц, если не&nbsp;в населенном пункте&nbsp;- название трассы, километр и&nbsp;направление
                  движения.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_2)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>   <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >2.</span>&nbsp;Дата ДТП
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Укажите дату и&nbsp;время ДТП.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_3)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >3.</span>&nbsp;Свидетели
                  ДТП</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя
                  и&nbsp;отчество (если оно есть),<br className={styles.mobile_active} />а также адрес свидетелей ДТП.
                </p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_4)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >4.</span>&nbsp;Марка,
                  модель&nbsp;ТС</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Внесите данные
                  из&nbsp;свидетельства о&nbsp;регистрации <br className={styles.mobile_active} />ТС (СТС).</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_4_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >4.</span>&nbsp;Марка,
                  модель&nbsp;ТС</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Внесите данные
                  из&nbsp;свидетельства о&nbsp;регистрации <br className={styles.mobile_active} />ТС (СТС).</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_5)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >5.</span>&nbsp;Собственник&nbsp;ТС
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя,
                  отчество и&nbsp;адрес собственника авто из&nbsp;СТС.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_5_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_5)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >5.</span>&nbsp;Собственник&nbsp;ТС
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя,
                  отчество и&nbsp;адрес собственника авто из&nbsp;СТС.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_6)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >6.</span>&nbsp;Водитель&nbsp;ТС
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя,
                  отчество, дату рождения, адрес регистрации и&nbsp;телефон водителя, а&nbsp;также данные его
                  водительского удостоверения.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_6_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_6)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >6.</span>&nbsp;Водитель&nbsp;ТС
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите фамилию, имя,
                  отчество, дату рождения, адрес регистрации и&nbsp;телефон водителя, а&nbsp;также данные его
                  водительского удостоверения.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_7)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >7.</span>&nbsp;Страховщик
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите страховую
                  компанию из&nbsp;полиса ОСАГО, <br className={styles.mobile_active} />номер полиса и&nbsp;дату
                  окончания его действия. Если у&nbsp;вас есть полис КАСКО, в&nbsp;пункте
                  &laquo;ТС&nbsp;застраховано от&nbsp;ущерба&raquo; отметьте &laquo;да&raquo;.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_7_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_7)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >7.</span>&nbsp;Страховщик
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите страховую
                  компанию из&nbsp;полиса ОСАГО, <br className={styles.mobile_active} />номер полиса и&nbsp;дату
                  окончания его действия. Если у&nbsp;вас есть полис КАСКО, в&nbsp;пункте
                  &laquo;ТС&nbsp;застраховано от&nbsp;ущерба&raquo; отметьте &laquo;да&raquo;.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_8)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_8)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >8.</span>&nbsp;Место
                  первоначального удара</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите стрелкой место
                  первоначального удара, изображать повреждения здесь не&nbsp;нужно.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_8_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_8_right)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >8.</span>&nbsp;Место
                  первоначального удара</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите стрелкой место
                  первоначального удара, изображать повреждения здесь не&nbsp;нужно.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_large, styles.v3_accident_tooltip_9,)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_9,)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >9.</span>&nbsp;Характер
                  и&nbsp;перечень видимых поврежденных деталей и&nbsp;элементов</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Перечислите все видимые
                  повреждения и опишите их характер, используйте термины «царапина», «вмятина», «деформация»,
                  «разрыв», «трещина».</p>
              </div>
            </div>

            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_large, styles.v3_accident_tooltip_9_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_9_right)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >9.</span>&nbsp;Характер
                  и&nbsp;перечень видимых поврежденных деталей и&nbsp;элементов</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Перечислите все видимые
                  повреждения и опишите их характер, используйте термины «царапина», «вмятина», «деформация»,
                  «разрыв», «трещина».</p>
              </div>
            </div>

            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_10)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_10)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >10.</span>&nbsp;Замечания
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите,
                  признаете&nbsp;ли вы&nbsp;свою вину или считаете себя невиновным в&nbsp;ДТП.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_10_right)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_left, styles.v3_accident_tooltip__inner_10_right)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >10.</span>&nbsp;Замечания
                </p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Напишите,
                  признаете&nbsp;ли вы&nbsp;свою вину или считаете себя невиновным в&nbsp;ДТП.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_11)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_11)}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>   <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >11.</span>&nbsp;Обстоятельства
                  ДТП</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>При помощи комбинации пунктов
                  опишите обстоятельства ДТП, каждый водитель отмечает клетки в&nbsp;соответствии
                  со&nbsp;своей колонкой (  <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_normal, styles.v3_color_red)}> &laquo;А&raquo;</span>
                  <br className={styles.mobile_active} />или   <span className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_normal, styles.v3_color_red)}> &laquo;В&raquo;</span>).
                  В&nbsp;последнем пункте укажите количество отмеченных клеток в&nbsp;своем столбике.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_12)}>
              <div className={styles.v3_accident_tooltip__inner}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >12.</span>&nbsp;Схема
                  ДТП</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Нарисуйте схему ДТП.
                  Схематично изобразите дорогу с&nbsp;названием улицы и&nbsp;номером дома, линии разметки,
                  расположение светофоров и&nbsp;дорожных знаков, другие ориентиры. Автомобили можно
                  изобразить простыми прямоугольниками, укажите их&nbsp;расположение в момент столкновения
                  и&nbsp;конечное положение, при <br className={styles.mobile_active} />этом обозначьте направления
                  движения машин.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_13)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_13)}>
                <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>    <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >13.</span>&nbsp;Подписи
                  водителей</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Подписи водителей здесь
                  обязательны: они удостоверяют отсутствие разногласий по обстоятельствам ДТП
                  и&nbsp;причиненным повреждениям.</p>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className={styles.v3_spacer_m10} />

      <div className={classnames(styles.v3_section__content, styles.v3_accident__content, styles.mobile_active)}>
        <div className={styles.v3_accident__instruction}>

          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_normal, styles.v3_color_black)}> Оборотную сторону Извещения о&nbsp;ДТП каждый
            водитель заполняет самостоятельно. Любые исправления заверяются подписями обоих <br
              className={styles.mobile_active} />водителей. Пустые строки заполните прочерком <br className={styles.mobile_active} />или
            символом &laquo;Z&raquo;, чтобы туда нельзя было вписать дополнительные данные.</p>
          <div className={styles.v3_spacer_m21} />

          <div className={styles.v3_accident__instruction_policy_side_2} />

          <div className={styles.v3_spacer_m21} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span
            className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >14.</span>&nbsp;Транспортное
            средство</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите, за&nbsp;какое&nbsp;ТС
            вы&nbsp;заполняли лицевую сторону.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span
            className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >15.</span>&nbsp;Обстоятельства ДТП
          </p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Опишите обстоятельства ДТП
            от&nbsp;первого лица. Укажите, когда и&nbsp;во&nbsp;сколько, по&nbsp;какой улице и&nbsp;в&nbsp;каком
            направлении вы&nbsp;двигались (либо стояли). Опишите ваши маневры или их&nbsp;отсутствие, а&nbsp;также
            действия второго участника ДТП.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span
            className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >16.</span>&nbsp;ТС&nbsp;находилось
            под управлением</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Отметьте, кто именно находился
            за&nbsp;рулем <br className={styles.mobile_active} />в&nbsp;момент ДТП.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span
            className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >17.</span>&nbsp;Может&nbsp;ли
            транспортное средство передвигаться своим ходом?</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}> Укажите, на&nbsp;ходу&nbsp;ли
            транспортное средство.</p>
          <div className={styles.v3_spacer_m20} />
          <p className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>  <span
            className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >18.</span>&nbsp;Примечания
            участников ДТП, в&nbsp;том числе разногласия (при наличии)</p>
          <div className={styles.v3_spacer_m5} />
          <p className={classnames(styles.v3_text, styles.v3_text_12, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black, styles.no_bottom_margin)}> Укажите любые сведения о&nbsp;ДТП,
            которые вы&nbsp;считаете существенными. Напишите, зафиксировано&nbsp;ли ДТП с&nbsp;помощью системы
            ГЛОНАСС или мобильного приложения, обеспечивающего передачу данных в&nbsp;АИС ОСАГО. При отсутствии
            примечаний поставьте прочерк или символ &quot;Z&quot;в пустых строках.</p>
        </div>
      </div>
      <div className={classnames(styles.v3_spacer_d40, styles.desktop_active)} >

        <div className={classnames(styles.v3_section__content, styles.v3_accident__content, styles.desktop_active)} />
        <div className={styles.v3_accident__instruction}>
          <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Оборотную сторону Извещения о&nbsp;ДТП каждый
            водитель заполняет самостоятельно. Любые исправления заверяются подписями обоих <br
              className={styles.mobile_active} />водителей. Пустые строки заполните прочерком <br className={styles.mobile_active} />или
            символом &laquo;Z&raquo;, чтобы туда нельзя было вписать дополнительные данные.</p>
        </div>
      </div>

      <div className={classnames(styles.v3_section__content, styles.v3_accident__content, styles.v3_accident__content_padd_side, styles.desktop_active)} >
        <div className={styles.v3_accident__instruction}>
          <div className={styles.v3_accident__instruction_policy_side_2}>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_14)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_14)}>
                <p
                  className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)} >  <span
                    className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >14.</span>&nbsp;Транспортное
                  средство</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Укажите, за&nbsp;какое&nbsp;ТС
                  вы&nbsp;заполняли лицевую сторону.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_15)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_15)}>
                <p
                  className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)} >  <span
                    className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >15.</span>&nbsp;Обстоятельства
                  ДТП</p>
                <div className={styles.v3_spacer_m10} />
                <p
                  className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)} >Опишите обстоятельства ДТП
                  от&nbsp;первого лица. Укажите, когда и&nbsp;во&nbsp;сколько, по&nbsp;какой улице
                  и&nbsp;в&nbsp;каком направлении вы&nbsp;двигались (либо стояли). Опишите ваши маневры или
                  их&nbsp;отсутствие, а&nbsp;также действия второго участника ДТП.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_16)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_16)}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>   <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >16.</span>&nbsp;ТС&nbsp;находилось
                  под управлением</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Отметьте, кто именно находился
                  за&nbsp;рулем <br className={styles.mobile_active} />в&nbsp;момент ДТП.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_17)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_17)}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>   <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >17.</span>&nbsp;Может&nbsp;ли
                  транспортное средство передвигаться своим ходом?</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Укажите, на&nbsp;ходу&nbsp;ли
                  транспортное средство.</p>
              </div>
            </div>
            <div className={classnames(styles.v3_accident_tooltip, styles.v3_accident_tooltip_18)}>
              <div className={classnames(styles.v3_accident_tooltip__inner, styles.v3_accident_tooltip__inner_18)}>
                <p className={classnames(styles.v3_text, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_black)}>   <span
                  className={classnames(styles.v3_text, styles.v3_text_14, styles.v3_text_d16, styles.v3_text_bold, styles.v3_color_red)} >18.</span>&nbsp;Примечания
                  участников ДТП, в&nbsp;том числе разногласия (при наличии)</p>
                <div className={styles.v3_spacer_m10} />
                <p className={classnames(styles.v3_text, styles.v3_text_d14, styles.v3_text_normal, styles.v3_color_black)}>Укажите любые сведения о&nbsp;ДТП,
                  которые вы&nbsp;считаете существенными. Напишите, зафиксировано&nbsp;ли ДТП с&nbsp;помощью
                  системы ГЛОНАСС или мобильного приложения, обеспечивающего передачу данных в&nbsp;АИС ОСАГО.
                  При отсутствии примечаний поставьте прочерк или символ &quot;Z&quot;в пустых строках.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}