import { memo } from "react";
import { Link as ILink } from "@/components/sections/types";
import { Link } from "@mafin/ui-kit";

import styles from "./index.module.scss";

export interface SeeAlsoCardSmallProps {
  iconUrl: string;
  link: ILink & { hidden?: boolean; newTab?: boolean };
  title: string;
}

export const SeeAlsoCardBig = memo<SeeAlsoCardSmallProps>(({ link, title, iconUrl }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconContainer}>
        <img className={styles.icon} alt="Иконка" src={iconUrl} />
      </div>
      <h3 className={styles.title}>{title}</h3>
      {!link.hidden && (
        <Link className={styles.link} target={link?.newTab ? "_blank" : "_self"} href={link?.url}>
          {link?.text}
        </Link>
      )}
    </div>
  );
});
