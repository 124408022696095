/* eslint-disable jsx-a11y/anchor-has-content */
import classnames from "classnames";
import { getStrapiMedia } from "@/utils/media";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import { Image, StrapiData, Link } from "../types";

import styles from "./index.module.scss";

export interface MobileAppBottomData {
  title: string;
  subtitle: string;
  qr: Image;
  background: Image;
  button: Link;
}

export const MobileAppBottom = ({ data }: StrapiData<MobileAppBottomData>) => {
  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${getStrapiMedia(data.background.url)})` }}>
      <div className={styles.mobile_app_bottom}>
        <img className={styles.qr} src={getStrapiMedia(data.qr.url)} alt={data.title} />
        <div className={styles.content}>
          <p className={styles.title}>{data.title}</p>
          <p className={styles.subtitle}>{data.subtitle}</p>
          <div className={styles.buttons}>
            <a
              href="https://redirect.appmetrica.yandex.com/serve/315474353179400359"
              className={classnames(styles.link, styles.link__apple)}
              target="_blank"
              rel="noreferrer nofollow"
            />
            <a
              href="https://redirect.appmetrica.yandex.com/serve/315474354018248399"
              className={classnames(styles.link, styles.link__google)}
              target="_blank"
              rel="noreferrer nofollow"
            />
          </div>
          <div className={styles.mobile}>
            <ButtonLink {...strapiLinkComponentToAnchorProps(data?.button)}>{data.button.text}</ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
