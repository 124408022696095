import { getSberOffer, getSberOfferId } from "@/utils/api/requests/mortgage-life";
import { Dispatch, SetStateAction } from "react";
import { FormProps, IForm } from "@/components/sections/mortgage-life/components/form";
import { MORTGAGE_FORM, STRAHOVANIE_IPOTEKI_CALC } from "@/utils/constants";
import { withRetryHandling } from "@/utils/common";
import { SberOfferError, SberOfferResponse } from "@/utils/api/types/mortgage-life";
import TagManager from "react-gtm-module";

interface SberCaseHandlerArgs extends Omit<FormProps, "banks"> {
  withLife: boolean;
  data: IForm;
  setShowPopUp: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const MAX_IMT = 33;
const MIT_IMT = 17;
const TREE_SECONDS = 3000;
const TREE_MINUTES = 180000;

const sberOfferValidator = (response: SberOfferResponse | SberOfferError): boolean =>
  !!response.additional?.calculation && "policy_cost" in response.additional?.calculation;

export const sberCaseHandler = async ({
  variant,
  withLife,
  data: { weight, height, debt, gender, birthdate },
  setShowPopUp,
  setOffer,
  setLoading,
}: SberCaseHandlerArgs): Promise<void> => {
  setOffer(null);

  if (withLife) {
    const IMT = +weight! / (+height! / 100) ** 2;

    if (IMT < MIT_IMT || IMT > MAX_IMT) {
      setShowPopUp(true);

      TagManager.dataLayer({ dataLayer: { event: "Mortgage_ins", eventCategory: "error", eventAction: "bmi" } });
    }
  }

  TagManager.dataLayer({ dataLayer: { event: "Mortgage_ins", eventCategory: "offers", eventAction: "goto" } });

  const payload = {
    bank: "sberbank" as const,
    sum: +debt,
    gender: gender === "Женский" ? ("female" as const) : ("male" as const),
    birthday: birthdate,
    ...(withLife
      ? { risk: { life: true as const, realty: variant !== "Жизнь" }, height: +height!, weight: +weight! }
      : { risk: { life: false as const, realty: true as const } }),
  };

  setLoading(true);

  const sberOfferIdResponse = await getSberOfferId({ calculation: { payload } });

  if (!sberOfferIdResponse) {
    return setLoading(false);
  }

  TagManager.dataLayer({
    dataLayer: {
      event: "CalculationID",
      direction: "Mortgage_ins",
      calc_id: sberOfferIdResponse.id,
    },
  });

  const getSberOfferResponse = await withRetryHandling({
    callback: getSberOffer,
    returnValidator: sberOfferValidator,
    delay: TREE_SECONDS,
    totalTime: TREE_MINUTES,
  })(sberOfferIdResponse.id);

  setLoading(false);

  if (!getSberOfferResponse) {
    return;
  }

  setOffer({
    ...payload,
    id: sberOfferIdResponse.id,
    policyCost: (getSberOfferResponse as SberOfferResponse).additional?.calculation.policy_cost,
    policyFullCost: (getSberOfferResponse as SberOfferResponse).additional?.calculation.policy_full_cost,
  });

  TagManager.dataLayer({ dataLayer: { event: "Mortgage_ins", eventCategory: "offers", eventAction: "received" } });

  setLoading(false);
};

interface RestCaseHandlerArgs extends Pick<FormProps, "banks"> {
  data: IForm;
}

export const restCaseHandler = ({ banks, data: { debt, birthdate, gender, bank } }: RestCaseHandlerArgs): void => {
  const bankId = banks.find(({ name }) => name === bank)?.idSravni;

  TagManager.dataLayer({ dataLayer: { event: "Mortgage_ins", eventCategory: "error", eventAction: "not_sber" } });

  localStorage.setItem(
    MORTGAGE_FORM,
    JSON.stringify({ debt, birthdate, bankId, gender: gender === "Женский" ? "female" : "male" })
  );

  window.open(STRAHOVANIE_IPOTEKI_CALC, "_self", "noopener");
};
