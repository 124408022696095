import React from "react";
import Image from "next/image";
import { Breadcrumbs } from "@mafin/ui-kit";
import { getStrapiMedia } from "utils/media";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { Breadcrumb, Image as IImage, StrapiData } from "../types";

import styles from "./index.module.scss";

interface StaticBannerProps {
  breadcrumbs?: Breadcrumb[];
  image: IImage;
  title: string;
  subtitle: string;
  mobile_subtitle?: boolean;
}

export const StaticBanner = ({ data }: StrapiData<StaticBannerProps>) => {
  const breadcrumbs = data.breadcrumbs?.map((breadcrumb: Breadcrumb) => {
    return { link: breadcrumb.link, name: breadcrumb.name };
  });

  return (
    <div className={styles.container}>
      <Image
        className={styles.image}
        src={getStrapiMedia(data.image.url)}
        alt={data.title}
        sizes="(max-width: 979px) 1600px, 2560px"
        objectFit="cover"
        layout="fill"
        quality={70}
        priority
      />
      <div className={styles.content}>
        {breadcrumbs && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <h1 className={styles.title}>{data.title}</h1>
        <HTMLFromRichTextEditor
          className={data.mobile_subtitle ? styles.mobile_subtitle : styles.subtitle}
          htmlString={data.subtitle}
        />
      </div>
    </div>
  );
};
