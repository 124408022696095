import { FC, useState } from "react";
import { Typography } from "@/components/shared/typography";
import { SliderBlock } from "@/components/sections/sovcom-leadgeneration/shared/slider-block";
import { MIN_CAR_PRICE } from "@/components/sections/sovcom-leadgeneration/shared/utils/constants";
import { getPriceOfKasko } from "@/components/sections/sovcom-leadgeneration/shared/utils/helpers";
import { ButtonLink } from "@/components/elements/button-link";
import { Shield } from "@mafin/icons";

import styles from "./index.module.scss";

export const VariantA: FC = () => {
  const [value, setValue] = useState(MIN_CAR_PRICE);

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <Typography type="h2" useParagraph>
          Цена полиса зависит <br /> только от стоимости авто
        </Typography>
        <SliderBlock value={value} onChange={setValue} />
      </div>
      <div className={styles.centerBlock}>
        <Typography type="semiboldSimpleText">Цена полиса:</Typography>
        <div className={styles.price}>
          <Typography type="h1" className={styles.policePrice} useParagraph>
            {getPriceOfKasko(value).toLocaleString("ru")}
          </Typography>
          <Typography type="simpleText">руб</Typography>
        </div>
        <ButtonLink variant="secondary" className={styles.desktopButton} href="/kasko/calc?promo=SOVKOMBANK">
          Оформить
        </ButtonLink>
      </div>
      <div className={styles.rightBlock}>
        <div>
          <Shield size="massive" color="#FF0550" />
          <Typography className={styles.shieldTitle} type="h2" useParagraph>
            Быстро
          </Typography>
          <Typography type="simpleText">
            Оформление <br /> по двум документам
          </Typography>
        </div>
        <div>
          <Shield size="massive" color="#FF0550" />
          <Typography className={styles.shieldTitle} type="h2" useParagraph>
            Выгодно
          </Typography>
          <Typography type="simpleText">
            Цена зависит только <br />
            от стоимости авто
          </Typography>
        </div>
      </div>
    </div>
  );
};
