import { useWindowSize } from "@/utils/hooks/use-window-size";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { IconLink } from "@mafin/ui-kit";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { Card } from "@/components/elements/card";
import { Image, Link, StrapiData, VectorImage } from "@/components/sections/types";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

interface ICard {
  id: number;
  subtitle: string;
  text: string | null;
  image: VectorImage | Image;
}

export interface Data {
  about: Link;
  advantage_card: ICard[];
  title: { text: string };
}

export const Advantages = ({ data: { about, advantage_card, title } }: StrapiData<Data>) => {
  const size = useWindowSize();
  const mobileBreakpoint = 979;

  const desktopCards = (
    <div className={styles.columns}>
      {advantage_card?.map((card: ICard) => (
        <Card key={card.id} image={card.image.url} subtitle={card.subtitle} text={card.text || ""} />
      ))}
    </div>
  );

  const mobileCards = (
    <Swiper pagination className={styles.slider} slidesPerView={2}>
      {advantage_card?.map((card) => {
        return (
          <SwiperSlide key={card.id} style={{ marginBottom: "20px" }}>
            <Card image={card.image.url} subtitle={card.subtitle} text={card.text || ""} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  return (
    <div className={styles.advantages}>
      <div className={styles.grid}>
        <h2 className={styles.title}>{title.text}</h2>
        <IconLink {...strapiLinkComponentToAnchorProps(about)} variant="primary" className={styles.iconLink}>
          {about.text}
        </IconLink>
        {size.width! > mobileBreakpoint && desktopCards}
        {size.width! <= mobileBreakpoint && mobileCards}
      </div>
    </div>
  );
};
