import { FC } from "react";
import { Arrow } from "@mafin/icons";
import { Link } from "@mafin/ui-kit";
import { Link as ILink } from "@/components/sections/types";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import styles from "./index.module.scss";

export interface SeoMediaMaterialProps {
  text: string;
  link: ILink;
}

export const SeoMediaMaterial: FC<SeoMediaMaterialProps> = ({ text, link }) => {
  return (
    <div className={styles.wrapper}>
      <Link {...strapiLinkComponentToAnchorProps(link)}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{link?.text}</h3>
            <Arrow className={styles.titleIcon} color="#FF0550" direction="right" />
          </div>
          <div className={styles.text}>{text}</div>
        </div>
      </Link>
    </div>
  );
};
