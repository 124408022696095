import React, { FC } from "react";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { Image, StrapiData } from "../types";

import styles from "./history-and-development.module.scss";

export interface HistoryAndDevelopmentItem {
  year: string;
  image: Image;
  description: string;
}

export type HistoryAndDevelopmentProps = StrapiData<{
  items: HistoryAndDevelopmentItem[];
}>;

export const HistoryAndDevelopment: FC<HistoryAndDevelopmentProps> = ({ data }) => {
  const { items } = data;

  const renderItems = () =>
    items.map(({ year, image, description }) => (
      <div className={styles.item} key={image.url}>
        <div className={styles.year}>{year}</div>
        <div className={styles.item__content}>
          <img className={styles.image} src={image.url} alt="item" />
          <HTMLFromRichTextEditor className={styles.description} htmlString={description} />
        </div>
      </div>
    ));

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.vertical_line} />
          <div className={styles.text}>История и развитие</div>
        </div>
        <div className={styles.vertical_line} />
        {renderItems()}
      </div>
    </div>
  );
};
