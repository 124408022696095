import React from "react";
import { Title } from "@/components/shared/title";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { Appearance, StrapiData, Title as ITitle } from "../types";

import styles from "./index.module.scss";

export interface Data {
  title?: ITitle;
  steps: Array<{
    content: string;
  }>;
  appearance: Appearance;
}

export const StepsNew = ({ data: { title, steps, appearance } }: StrapiData<Data>) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        {title?.text && <Title {...title} className={styles.title} />}
        <div className={styles.steps}>
          {steps.map((step, index) => (
            <div key={`stepNew${index}`} className={styles.step} data-testid="step">
              <div className={styles.circle}>{index + 1}</div>
              <HTMLFromRichTextEditor className={styles.content} htmlString={step.content} />
            </div>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
