import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Border } from "@/components/shared/border";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface AdvantagesMobileProps {
  appearance: Appearance;
}

export const AdvantagesMobile: FC<StrapiData<AdvantagesMobileProps>> = ({ data: { appearance } }) => {
  const items: Array<{ icon: string; htmlText: JSX.Element }> = [
    { icon: "/icons/checkedBox.svg", htmlText: <>Удобный и прозрачный выбор</> },
    { icon: "/icons/womanAtLaptop.svg", htmlText: <>Быстрое онлайн-оформление</> },
    { icon: "/icons/handWithCoins.svg", htmlText: <>Самые выгодные предложения</> },
    {
      icon: "/icons/shield.svg",
      htmlText: (
        <>
          Безопасное
          <br />
          хранение данных
        </>
      ),
    },
  ];

  const renderItems = () =>
    items.map((i, index) => (
      <div className={style.item} key={i.icon}>
        <img src={i.icon} alt={`AdvantagesMobile${index}`} className={style.icon} />
        <Typography variant="h4">{i.htmlText}</Typography>
      </div>
    ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Border>
          <Typography variant="h2" component="h2">
            Преимущества получения полиса КАСКО с Mafin
          </Typography>
          <div className={style.items}>{renderItems()}</div>
        </Border>
      </Container>
    </AppearanceWrapper>
  );
};
