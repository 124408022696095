import React, { useCallback } from "react";
import classnames from "classnames";
import { New } from "@mafin/icons";

import styles from "./index.module.scss";

interface Props {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
  iconNew?: boolean;
}

const TabTitle: React.FC<Props> = ({ title, setSelectedTab, index, selectedTab, iconNew }) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  const hasBorder = index !== selectedTab + 1;

  return (
    <li className={classnames(styles.list_item, selectedTab === index && styles.active, hasBorder && styles.border)}>
      <div className={styles.btn} onClick={onClick}>
        <h5 style={{ textAlign: "center" }}>{title}</h5>
        {iconNew && <New />}
      </div>
    </li>
  );
};

export default TabTitle;
