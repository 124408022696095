import { FC } from "react";
import { IconLink } from "@mafin/ui-kit";
import { Link, StrapiData, Title } from "@/components/sections/types";
import { SeeAlsoCardBig, SeeAlsoCardSmallProps } from "@/components/elements/see-also-card-big";
import cn from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./index.module.scss";

interface Data {
  title: Title;
  link: Link & { newTab?: boolean; hidden?: boolean };
  cards: SeeAlsoCardSmallProps[];
}

export const SeeAlsoBig: FC<StrapiData<Data>> = ({ data: { title, cards = [], link } }) => {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.container, link?.hidden && styles.hideLink)}>
        <h2 className={styles.title}>{title?.text}</h2>
        {!link?.hidden && (
          <IconLink
            className={styles.link}
            variant="tertiary"
            target={link?.newTab ? "_blank" : "_self"}
            href={link?.url}
          >
            {link?.text}
          </IconLink>
        )}
        <div className={styles.desktopCards}>
          {cards.map((card) => (
            <SeeAlsoCardBig key={`see-also-card-${card.title}`} {...card} />
          ))}
        </div>
        <Swiper
          slidesPerView={1.7}
          spaceBetween={1}
          breakpoints={{
            345: {
              slidesPerView: 2,
              spaceBetween: 7,
            },
          }}
          pagination={{
            clickable: true,
          }}
          className={styles.swiper}
        >
          {cards.map((card) => (
            <SwiperSlide key={`see-also-card-${card.title}`}>
              <SeeAlsoCardBig {...card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
