import { FC, useState } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, Link, StrapiData, Title as ITitle } from "@/components/sections/types";
import cn from "classnames";
import { Title } from "@/components/shared/title";

import { TabContent } from "@/components/sections/product-description-with-tabs/tab-content/TabContent";

import styles from "./index.module.scss";

export interface ITab {
  tabTitle: string;
  mainCards: Array<{ row: string }>;
  redCard: {
    text: string;
    listWithTooltip: Array<{
      text: string;
      tooltipText: string | null;
    }>;
  };
  button: Link;
}

export interface Data {
  title: ITitle;
  tabs: ITab[];
  appearance: Appearance;
}

export const ProductDescriptionWithTabs: FC<StrapiData<Data>> = ({ data: { title, tabs, appearance } }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (tabIndex: number) => () => setActiveTab(tabIndex);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title {...title} className={styles.title} />
        <div className={styles.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={tab.tabTitle}
              onClick={handleTabClick(index)}
              className={cn(styles.tab, activeTab === index && styles.activeTab)}
            >
              <Title text={tab.tabTitle} type="h3" />
              <div className={cn(styles.tabUnderline, activeTab === index && styles.activeUnderline)} />
            </div>
          ))}
          <div className={styles.tabUnderline} />
        </div>
        <TabContent {...tabs[activeTab]} />
      </div>
    </AppearanceWrapper>
  );
};
