import React, { FunctionComponent } from "react";
import { passAttributes } from "@/utils/parse-attributes";

export const Ns: FunctionComponent = () => {
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const ns = document.createElement("script");
    const container = scriptContainerRef.current;

    passAttributes(
      ns,
      [
        ["data-product", "strahovanie-ns"],
        ["src", "https://sravni.ru/widgets/loader.js"],
        ["data-inFrame", "true"],
        ["data-theme", `palette='{"elementsColor":"35, 35, 199","activeElementsColor":"35, 35, 199"}'`],
        ["data-aff_id", "2123"],
        ["data-offer_id", "1266"],
        ["data-aff_sub", "19"],
        ["data-source", "2855"],
        ["data-utm_source", "mafin.ru_2855"],
        ["data-utm_campaign", "nsinsurance_whitelabel"],
        ["data-utm_medium", "cpa"],
        ["data-utm_content", "2123"],
      ],
      container
    );
  }, []);

  return <div ref={scriptContainerRef} />;
};
