import React, { FC, useMemo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Chip, IconLink } from "@mafin/ui-kit";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title as ITitle, StrapiData, Appearance, Link as ILink } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { City } from "@/utils/api/types/main-site";
import { Product } from "@/utils/globalContext/types";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  link?: ILink;
  city: City;
  cities: City[];
  newTab: boolean;
  product: Pick<Product, "slug">;
  relNofollow: boolean;
  appearance: Appearance;
}

const MAX_CHIPS_COUNT = 10;

export const CitiesChips: FC<StrapiData<Data>> = ({
  data: { title, link, city: currentCity, cities, newTab, relNofollow, product, appearance },
}) => {
  const router = useRouter();
  const chips = useMemo(
    () =>
      cities
        .filter((city) => city.id !== currentCity.id && city.products.find((i) => i.slug === product.slug))
        .sort((a, b) => a.name.localeCompare(b.name, "en"))
        .sort((a, b) => b.orderWeight - a.orderWeight)
        .sort((a, b) => +b.popular - +a.popular)
        .slice(0, MAX_CHIPS_COUNT),
    [cities, currentCity.id, product.slug]
  );

  const handleIconLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!link!.newTab) {
      e.preventDefault();
      router.push(link!.url);
    }
  };

  return chips.length ? (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <Title className={styles.title} {...title} />
        {link && (
          <IconLink
            variant="secondary"
            className={styles.link}
            {...strapiLinkComponentToAnchorProps(link)}
            onClick={handleIconLinkClick}
          >
            {link.text}
          </IconLink>
        )}
        <div className={styles.chips} data-testid="citiesChips">
          {chips.map((i) => (
            <Link key={i.slug} href={`/${product.slug}/${i.slug}`} scroll={false}>
              <a
                {...strapiLinkComponentToAnchorProps({
                  text: "",
                  url: `/${product.slug}/${i.slug}`,
                  newTab,
                  relNofollow,
                })}
              >
                <Chip label={i.name} className={styles.chip} />
              </a>
            </Link>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  ) : null;
};
