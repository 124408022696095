import { FC } from "react";

import styles from "./index.module.scss";

export const Documents: FC = () => {
  return (
    <div className={styles.container}>
      <p>
        Для первичной регистрации страхового события ОСАГО Вам необходимо самостоятельно предоставить оригиналы
        документов на точку Независимой экспертизы, а именно:
      </p>
      <ul>
        <li>
          Заявление о страховой выплате, которые Вы сформируете и подпишите на точке Независимой экспертизы (3 листа)
        </li>
        <li>Извещение о ДТП (бланк обязателен для заполнения, даже если оформлено ДТП сотрудниками ГИБДД)</li>
        <li>
          Оригиналы документов ГИБДД (определение либо постановление с указанием участников ДТП и повреждений ТС + полис
          ОСАГО участников)
        </li>
        <li>Документы на транспортное средство (СТС или ПТС)</li>
        <li>
          Личные документы (ВУ, паспорт) + доверенность на право представления интересов, если подает не собственник
          (рукописная)
        </li>
      </ul>
    </div>
  );
};
