import { FC } from "react";
import { StrapiData, Title } from "@/components/sections/types";
import { SeeAlsoCardSmall, SeeAlsoCardSmallProps } from "@/components/elements/see-also-card-small";

import styles from "./index.module.scss";

interface Data {
  title: Title;
  cards: SeeAlsoCardSmallProps[];
}

export const SeeAlsoSmall: FC<StrapiData<Data>> = ({ data: { title, cards = [] } }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title?.text}</h2>
        <div className={styles.cards}>
          {cards.map((card) => (
            <SeeAlsoCardSmall key={`see-also-card-${card.title}`} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};
