import { RefObject, useEffect, useMemo, useState } from "react";

export const useIsInViewport = (ref: RefObject<Element>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => {
    if (typeof window !== "undefined") {
      return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting));
    }
  }, []);

  useEffect(() => {
    observer?.observe(ref.current as Element);

    return () => {
      observer?.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default useIsInViewport;
