import { FC } from "react";
import Image from "next/image";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, Image as IImage, Link as ILink, StrapiData, Title as ITitle } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { getNextImageSize, MAX_MOBILE_WIDTH } from "@/utils/next-image-helpers";
import { getStrapiMedia } from "@/utils/media";
import { Typography } from "@/components/shared/typography";
import { useWindowSize } from "@/utils/hooks/use-window-size";
import { MortgageForm } from "@/components/sections/lead-generation-not-motor/components/mortgage-form";
import { Bank } from "@/utils/api/types/main-site";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  text: string;
  textBeforeButton?: string;
  button: ILink;
  desktop: IImage;
  mobile: IImage;
  type: "default" | "mortgage" | null;
  banks: Bank[];
  appearance: Appearance;
}

export const LeadGenerationNotMotor: FC<StrapiData<Data>> = ({
  data: { title, text, textBeforeButton, button, desktop, mobile, type, banks, appearance },
}) => {
  const size = useWindowSize();
  const getImageSrc = () => getStrapiMedia(Number(size.width) <= MAX_MOBILE_WIDTH ? mobile.url : desktop.url);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.textBlock}>
          <Title {...title} className={styles.title} />
          <Typography type="h4">{text}</Typography>
        </div>
        <div className={styles.image}>
          <Image
            src={getImageSrc()}
            alt={desktop.alternativeText}
            objectFit="cover"
            sizes={getNextImageSize(240)}
            layout="fill"
            quality={70}
            priority
          />
        </div>
        <div className={styles.buttonBlock}>
          {type === "mortgage" ? (
            <MortgageForm banks={banks} button={button} />
          ) : (
            <>
              {textBeforeButton && <Typography type="h4">{textBeforeButton}</Typography>}
              <ButtonLink {...strapiLinkComponentToAnchorProps(button)} className={styles.button}>
                {button.text}
              </ButtonLink>
            </>
          )}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
