import { FC } from "react";
import { Appearance, StrapiData } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

interface Card {
  text: string;
}

export interface ProductComparisonCardsData {
  text: string;
  leftCard: Card;
  rightCard: Card;
  appearance: Appearance;
}

export const ProductComparisonCards: FC<StrapiData<ProductComparisonCardsData>> = ({
  data: { text, leftCard, rightCard, appearance },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <HTMLFromRichTextEditor className={styles.content} htmlString={text} />
        <div className={styles.cards}>
          <div className={styles.card}>
            <HTMLFromRichTextEditor className={styles.content} htmlString={leftCard.text} />
          </div>
          <div className={styles.card}>
            <HTMLFromRichTextEditor className={styles.content} htmlString={rightCard.text} />
          </div>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
