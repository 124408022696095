import { FC, ReactNode } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

interface InformationProps {
  className?: string;
  children: ReactNode | string;
}

export const Information: FC<InformationProps> = ({ className, children }) => {
  return (
    <div className={cn(className, styles.container)}>
      <img className={styles.icon} alt="info icon" src="/icons/info.png" />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
