export const validateTime = (value: string): boolean => {
  const [hours, minutes] = value.split(":");

  return !(Number(hours) > 23 || Number(minutes) > 59);
};

export const validateDate = (value: string): boolean => {
  const [day, month, year] = value.split(".");
  const date = new Date();

  date.setMonth(+month - 1);
  date.setFullYear(+year);
  date.setDate(+day);

  return !(Number(day) > 31 || Number(month) > 12 || Number(year) < 2000 || date > new Date());
};
