import { FC, Fragment, useState } from "react";
import { VectorImage } from "@/components/sections/types";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { Partner } from "@/utils/globalContext/types";
import { DisclosureButton } from "@/components/shared/disclosure-button/DisclosureButton";
import { Divider } from "@/components/shared/divider";
import { Typography } from "@mafin/ui-kit";

import styles from "./index.module.scss";

export interface PartnerProgramCardProps {
  partner: Omit<Partner, "kaskoPsoCities" | "products">;
  programName?: string;
  discountText?: string;
  additionalText?: string;
  conditionSections: Array<{ id: number; image: VectorImage; subtitle: string; text: string }>;
}

export const PartnerProgramCard: FC<PartnerProgramCardProps> = ({
  partner,
  programName,
  discountText,
  additionalText,
  conditionSections,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDisclosure = () => setIsOpen((p) => !p);

  return (
    <div className={styles.card} data-testid="partner-program-card">
      <div className={styles.title}>
        <img src={partner.icon.url} alt={partner.name} className={styles.logo} />
        <div className={styles.description}>
          {programName && (
            <Typography variant="h4" color="var(--primary)">
              {programName}
            </Typography>
          )}
          {discountText && <HTMLFromRichTextEditor className={styles.text} htmlString={discountText} />}
          {additionalText && (
            <Typography variant="ultraLittleText" color="var(--additional)" className={styles.littleText}>
              {additionalText}
            </Typography>
          )}
        </div>
        <DisclosureButton
          isOpen={isOpen}
          onClick={toggleDisclosure}
          text={{ opened: "Скрыть", closed: "Подробнее о программе" }}
          className={styles.disclosureButton}
        />
      </div>
      {isOpen &&
        conditionSections.map((i) => (
          <Fragment key={i.image.id}>
            <Divider className={styles.divider} />
            <div className={styles.section}>
              <div className={styles.icon}>
                <img src={i.image.url} alt={i.image.alternativeText} />
              </div>
              <Typography variant="h2" className={styles.title}>
                {i.subtitle}
              </Typography>
              <HTMLFromRichTextEditor className={styles.text} htmlString={i.text} />
            </div>
          </Fragment>
        ))}
    </div>
  );
};
