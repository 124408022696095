import { FC } from "react";
import NextLink from "next/link";
import { Button, Modal, Typography } from "@mafin/ui-kit";
import { STRAHOVANIE_IPOTEKI_CALC } from "@/utils/constants";

import styles from "./index.module.scss";

export interface FormModalProps {
  showPopUp: boolean;
  handleModalClose(): void;
}

export const FormModal: FC<FormModalProps> = ({ handleModalClose, showPopUp }) => {
  return (
    <Modal title="Не удалось рассчитать стоимость" onClose={handleModalClose} shouldBeCloseBtn isOpen={showPopUp}>
      <Typography variant="simpleText">
        К сожалению, вы сможете оформить только полис страхование Квартиры. Полис страхования жизни и здоровья вы можете
        оформить{" "}
        <NextLink href={STRAHOVANIE_IPOTEKI_CALC}>
          <a>
            <Typography variant="simpleText" color="var(--corporate)">
              здесь
            </Typography>
          </a>
        </NextLink>
        .
      </Typography>
      <Button onClick={handleModalClose} className={styles.button}>
        Хорошо
      </Button>
    </Modal>
  );
};
