import React, { FunctionComponent } from "react";
import { passAttributes } from "@/utils/parse-attributes";
import { MORTGAGE_FORM } from "@/utils/constants";

export const Mortgage: FunctionComponent = () => {
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const mortgage = document.createElement("script");
    const container = scriptContainerRef.current;
    const { bankId, gender, debt, birthdate } = JSON.parse(localStorage.getItem(MORTGAGE_FORM) || "{}");

    localStorage.removeItem(MORTGAGE_FORM);

    passAttributes(
      mortgage,
      [
        ["data-product", "strahovanie-ipoteki"],
        ["src", "https://sravni.ru/widgets/loader.js"],
        ["data-inFrame", "true"],
        ["data-filters.bank", bankId || ""],
        ["data-filters.debt", debt || "3000000"],
        ["data-filters.gender", gender || "female"],
        ["data-filters.birthdate", birthdate || ""],
        ["data-filters.percent-rate", "10"],
        ["data-theme-Palette", '{"elementsColor":"35, 35, 199","activeElementsColor":"35, 35, 199"}'],
        ["data-aff_id", "2123"],
        ["data-offer_id", "1072"],
        ["data-aff_sub", "12"],
        ["data-source", "2855"],
      ],
      container
    );
  }, []);

  return <div ref={scriptContainerRef} />;
};
