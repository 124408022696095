import React, { FC } from "react";
import cn from "classnames";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { IconLink } from "@mafin/ui-kit";
import { Appearance, Image, StrapiData } from "@/components/sections/types";
import { FeedbackModal } from "@/components/sections/feedback/components/feedback-modal";
import { useRouter } from "next/router";
import { FeedbackModalType } from "@/components/sections/feedback/components/form";

import styles from "./index.module.scss";

interface Data {
  appearance: Appearance;
  agreementText: string;
  enableFiles?: boolean;
  applicationLinks: Array<{
    image: Image;
    href: string;
  }>;
}

const MODAL_QUERY_PARAM = "feedbackModal";

export const Feedback: FC<StrapiData<Data>> = ({
  data: { appearance, enableFiles, agreementText, applicationLinks },
}) => {
  const router = useRouter();
  const modalType = router.query[MODAL_QUERY_PARAM] as string;
  const isModalOpened = !!modalType;

  const appLinks = applicationLinks.map(({ image, href }) => (
    <a href={href} rel="nofollow" key={href}>
      <img className={styles.icon} src={image.url} alt="icon" />
    </a>
  ));

  const handleFeedbackModalClose = () => {
    const draft = { ...router.query };

    delete draft[MODAL_QUERY_PARAM];

    router.replace(
      {
        pathname: router.pathname,
        query: draft,
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const handleOpenFeedbackModalClick = (type: FeedbackModalType) => {
    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, [MODAL_QUERY_PARAM]: type },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <h2 className={styles.title}>Обратная связь</h2>
        <div className={styles.grid}>
          <div className={styles.column}>
            <h3>О сервисе Mafin</h3>
            <IconLink onClick={() => handleOpenFeedbackModalClick("buy")} className={styles.link} variant="tertiary">
              Написать нам
            </IconLink>
          </div>
          <div className={cn(styles.column, styles.middle)}>
            <h3>О сервисе Mafin</h3>
            <IconLink onClick={() => handleOpenFeedbackModalClick("review")} className={styles.link} variant="tertiary">
              Оставить отзыв
            </IconLink>
          </div>
          <div className={cn(styles.column, styles.last)}>
            <div>
              <h3>О приложении Mafin</h3>
              <div className={styles.appLinks}>{appLinks}</div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpened && (
        <FeedbackModal
          enableFiles={enableFiles}
          type={modalType as FeedbackModalType}
          onClose={handleFeedbackModalClose}
          agreementText={agreementText}
        />
      )}
    </AppearanceWrapper>
  );
};
