import { FC, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Input, SuggestOption, AmountInput, Dropdown } from "@mafin/ui-kit";
import { Link } from "@/components/sections/types";
import { GENDER_SUGGESTIONS, MORTGAGE_FORM, STRAHOVANIE_IPOTEKI_CALC } from "@/utils/constants";
import { Bank } from "@/utils/api/types/main-site";
import { birthdateValidator } from "@/components/sections/lead-generation-not-motor/components/mortgage-form/helpers";

import styles from "./index.module.scss";

export interface MortgageFormProps {
  banks: Bank[];
  button: Link;
}

interface Form {
  bank: string;
  gender: "Женский" | "Мужской";
  birthdate: string;
  debt: string;
}

export const MortgageForm: FC<MortgageFormProps> = ({ banks, button }) => {
  const { control, handleSubmit, setError, setValue } = useForm<Form>();

  const bankSuggestions = useMemo(
    () =>
      [...banks].sort((a, b) => b.orderWeight - a.orderWeight).map(({ idSravni, name }) => ({ id: idSravni, name })),
    [banks]
  );

  const [bank, setBank] = useState<SuggestOption>(bankSuggestions[0]);
  const [gender, setGender] = useState<SuggestOption>(GENDER_SUGGESTIONS[0]);

  const handleChooseOption = (type: "bank" | "gender") => (option: SuggestOption | null) => {
    if (option) {
      setValue(type, option.name);
      type === "bank" ? setBank(option) : setGender(option);
    }
  };

  const submit: SubmitHandler<Form> = (data) => {
    const bankId = banks.find(({ name }) => name === data.bank)?.idSravni;

    localStorage.setItem(
      MORTGAGE_FORM,
      JSON.stringify({ ...data, bankId, gender: data.gender === "Женский" ? "female" : "male" })
    );

    window.open(STRAHOVANIE_IPOTEKI_CALC, ...(button.newTab ? ["_blank", "noopener"] : ["_self"]));
  };

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.form} data-testid="mortgage-form">
      <Controller
        name="bank"
        control={control}
        defaultValue={banks[0]?.name || ""}
        rules={{
          required: { value: true, message: "Выберите банк" },
        }}
        render={({ field: { ref, ...field } }) => (
          <Dropdown
            {...field}
            label="Ипотечный банк"
            options={bankSuggestions}
            selectedOption={bank}
            chooseOption={handleChooseOption("bank")}
            autoTestId="mortgage-bank"
          />
        )}
      />

      <Controller
        name="debt"
        control={control}
        rules={{
          required: { value: true, message: "Укажите остаток кредита" },
          min: { value: 100000, message: "Даже если остаток меньше 100 000 ₽, укажите 100 000 ₽" },
          max: { value: 15000000, message: "Доступно оформление для кредитов не более 15 000 000 ₽" },
        }}
        render={({ field: { onChange, ...field }, fieldState }) => (
          <AmountInput
            {...field}
            label="Остаток кредита"
            onChange={(e) => {
              setValue("debt", e.target.value.replace(/ /g, ""));
              setError("debt", { message: undefined });
            }}
            error={fieldState.error?.message}
            maxLength={10}
            autoTestId="mortgage-debt"
          />
        )}
      />

      <Controller
        name="gender"
        control={control}
        defaultValue="Женский"
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <Dropdown
            {...field}
            label="Пол заёмщика"
            options={GENDER_SUGGESTIONS}
            chooseOption={handleChooseOption("gender")}
            selectedOption={gender}
            autoTestId="mortgage-gender"
          />
        )}
      />

      <Controller
        name="birthdate"
        control={control}
        defaultValue=""
        rules={{
          required: { value: true, message: "Укажите дату рождения" },
          validate: birthdateValidator,
        }}
        render={({ field: { ref, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            label="Дата рождения"
            placeholder="дд.мм.гггг"
            onChange={(e) => {
              setValue("birthdate", e.target.value);
              setError("birthdate", { message: undefined });
            }}
            error={fieldState.error?.message}
            maskedProps={{ mask: "99.99.9999" }}
            autoTestId="mortgage-birthdate"
          />
        )}
      />

      <Button>{button.text}</Button>
    </form>
  );
};
