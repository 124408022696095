import { memo } from "react";

import { DesktopSliderNavigation, ButtonsProps } from "../desktop-slider-navigation";
import { Frame } from "../frame";
import { Step } from "../step";
import { Phone } from "../ApplicationContainer";

import styles from "./index.module.scss";

export interface ActionProps {
  title: string;
  activeSlide: number;
  onSelectSlide: ButtonsProps["onSelectSlide"];
  slides: Phone[];
}

export const DesktopSlider = memo(function DesktopSlider({ title, activeSlide, onSelectSlide, slides }: ActionProps) {
  return (
    <div className={styles.action}>
      <DesktopSliderNavigation title={title} activeSlide={activeSlide} onSelectSlide={onSelectSlide} slides={slides} />
      <Frame image={slides[activeSlide].image.url} />
      <Step content={slides[activeSlide].content} />
    </div>
  );
});
