import React, { FC } from "react";
import Image from "next/image";
import { Button } from "@mafin/ui-kit";
import { getStrapiMedia } from "@/utils/media";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

export interface MaterialCardProps {
  cover: { url: string };
  cardTitle: string;
  cardDescription: string;
  cardShowMore: string;
}

export const MaterialCard: FC<MaterialCardProps> = ({ cover, cardTitle, cardDescription, cardShowMore }) => {
  const url = getStrapiMedia(cover.url);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <Image
          className={styles.cover}
          src={url}
          alt="cover"
          sizes="480px"
          objectFit="cover"
          layout="fill"
          quality={70}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>{cardTitle}</div>
          <HTMLFromRichTextEditor className={styles.description} htmlString={cardDescription} />
        </div>
        <div>
          <Button variant="secondary">
            <a href={cardShowMore}>Подробнее</a>
          </Button>
        </div>
      </div>
    </div>
  );
};
