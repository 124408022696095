import ErrorPage from "next/error";
import { getAdditionalSectionData, getGlobalData, getPageData } from "@/utils/api/requests/main-site";
import { useRouter } from "next/dist/client/router";
import React from "react";
import { Sections } from "@/components/sections";
import { getServerSideStrapiURL } from "@/utils/api/helpers";
import Seo from "@/components/elements/seo";
import { sleep } from "@/utils/common";
import { getCityPagePaths, getTemplatePageData, PageWithCollectionItems } from "@/utils/routing/routing";
import { PageContainer } from "@/components/layout";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { Page } from "@/utils/api/types/main-site";

export async function getStaticPaths() {
  // Get all pages from Strapi
  const pages: Page[] = await (await fetch(`${getServerSideStrapiURL("/pages")}?_limit=500&status=published`)).json();
  const cityPagePaths = await getCityPagePaths();

  const paths = pages
    .filter((page) => !page.slug.includes("generate"))
    .map((page) => {
      // Decompose the slug that was saved in Strapi
      const slugArray = page.slug.split("__");

      return {
        params: {
          slug: slugArray,
          metadata: { noindex: page.metadata.noindex },
          pageName: page.pageName,
        },
      };
    })
    .concat(cityPagePaths);

  return { paths, fallback: "blocking" };
}

// TODO: Декомпозировать
export const getStaticProps: GetStaticProps = async ({ params, preview = null }) => {
  const slug = params?.slug as string[];

  let chainedSlugs;

  if (!slug) {
    // To get the homepage, find the only page where slug is an empty string
    chainedSlugs = ``;
  } else {
    // Otherwise, find a page with a matching slug
    // Recompose the slug that was saved in Strapi
    chainedSlugs = slug.join("__");

    if (/[а-яА-ЯЁё№&]/u.test(chainedSlugs)) {
      chainedSlugs = "404";
    }
  }

  // Fetch pages. Include drafts if preview mode is on
  const pageData = ((await getTemplatePageData(slug, preview)) ||
    (await getPageData(chainedSlugs, !!preview))) as PageWithCollectionItems;
  // если в урле содержится два нижних подчеркивания, то переводим на 404 страницу, так как нижние подчеркивание используется в страпи вместо /
  const isServiceSlugForStrapi = slug?.join("").includes("__");

  if ((!params?.isCity && (!pageData || isServiceSlugForStrapi)) || !pageData) {
    // Giving the page no props will trigger a 404 page
    return {
      notFound: true,
      revalidate: 10,
    };
  }

  await sleep(1000);

  const global = await getGlobalData();

  return {
    props: {
      preview,
      sections: await getAdditionalSectionData(pageData),
      metadata: pageData.metadata,
      global,
      jsonLd: pageData.jsonLd || null,
    },
    revalidate: 10,
  };
};

const DynamicPage = ({
  sections,
  metadata,
  preview,
  global,
  jsonLd,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const router = useRouter();
  const { metadata: defaultMetadata } = global;

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  // Check if the required data was provided
  if (!router.isFallback && !sections?.length) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <PageContainer global={global} jsonLd={jsonLd}>
      <>
        {/* Add meta tags for SEO*/}
        <Seo metadata={metadata} defaultMetadata={defaultMetadata} />
        {/* Display content sections */}
        <Sections sections={sections} preview={preview} />
      </>
    </PageContainer>
  );
};

export default DynamicPage;
