import React, { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, Image, Link, StrapiData } from "@/components/sections/types";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import styles from "./index.module.scss";

export interface Data {
  textBeforeArrows: string;
  textAfterArrows: string;
  textDescription: string;
  phoneNumber: string;
  socials: Array<{
    icon: Image;
    link: Link;
  }>;
  appearance: Appearance;
}

export const FeedbackBanner: FC<StrapiData<Data>> = ({
  data: { textBeforeArrows, textAfterArrows, textDescription, phoneNumber, socials, appearance },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.container}>
          <div className={styles.text}>
            <div className={styles.textTitle}>
              <p>{textBeforeArrows}</p>
              <div className={styles.arrows} />
              <p>{textAfterArrows}</p>
            </div>
            <p className={styles.textDescription}>{textDescription}</p>
          </div>
          <div className={styles.contacts}>
            <ButtonLink href={`tel:${phoneNumber}`} variant="secondary" className={styles.button}>
              {phoneNumber}
            </ButtonLink>
            <div className={styles.socials}>
              {socials.map((social) => (
                <a key={social.link.text} {...strapiLinkComponentToAnchorProps(social.link)}>
                  <img src={social.icon.url} alt={social.link.text} />
                </a>
              ))}
            </div>
          </div>
          <div className={styles.logos} />
        </div>
      </div>
    </AppearanceWrapper>
  );
};
