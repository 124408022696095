import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { getStrapiMedia } from "@/utils/media";
import { Appearance, Image, StrapiData, Title as ITitle } from "@/components/sections/types";

import styles from "./index.module.scss";

export interface Data {
  text: string;
  image: Image;
  appearance: Appearance;
  title: ITitle;
}

export const TitleTextImage = ({ data: { text, image, appearance, title } }: StrapiData<Data>) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title text={title.text} type={title.type} className={styles.title} />
        <div className={styles.text}>{text}</div>
        <img className={styles.image} src={getStrapiMedia(image.url)} alt={title.text} />
      </div>
    </AppearanceWrapper>
  );
};
