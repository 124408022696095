import { differenceInYears, isValid, parse } from "date-fns";

export const birthdateValidator = (maxAge: number) => (value: string) => {
  const MIN_AGE = 18;
  const birthdate = parse(value, "dd.MM.yyyy", new Date());

  if (!isValid(birthdate)) {
    return "Некорректная дата";
  }

  const ageOfInsurer = differenceInYears(new Date(), birthdate);

  if (ageOfInsurer > maxAge) {
    return `Оформление доступно для максимального возраста – ${maxAge} лет`;
  }

  if (ageOfInsurer < MIN_AGE) {
    return `Минимальный возраст – ${MIN_AGE} лет`;
  }
};
