import { FC } from "react";
import { Appearance, StrapiData } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { VariantA } from "@/components/sections/sovcom-leadgeneration/sovcom-leadgeneration-compact/components/variant-a";
import { VariantB } from "@/components/sections/sovcom-leadgeneration/sovcom-leadgeneration-compact/components/variant-b";

import styles from "./index.module.scss";

export interface SovcomLeadgenerationCompactData {
  appearance: Appearance;
  variant: "A" | "B";
}

export const SovcomLeadgenerationCompact: FC<StrapiData<SovcomLeadgenerationCompactData>> = ({
  data: { appearance, variant },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>{variant === "A" ? <VariantA /> : <VariantB />}</div>
    </AppearanceWrapper>
  );
};
