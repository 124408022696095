import React from "react";

import { ButtonLink } from "../button-link";
import { Link } from "../../sections/types";

import styles from "./index.module.scss";

export interface ContactCardProps {
  title: string;
  telephone?: Link;
  telegram?: string;
  whats_app?: string;
  feedback?: Link;
}

export const ContactCard: React.FC<ContactCardProps> = ({ title, telephone, telegram, whats_app, feedback }) => {
  return (
    <div className={styles.contact_card}>
      <p className={styles.contact_card__title}>{title}</p>
      {telephone && (
        <a href={`tel:${telephone.url}`} className={styles.contact_card__phone}>
          {telephone.text}
        </a>
      )}
      <div className={styles.contact_card__socials}>
        {telegram && (
          <a href={telegram} target="_blank" rel="noreferrer">
            <div className={styles.contact_card__telegram} />
          </a>
        )}
        {whats_app && (
          <a href={whats_app} target="_blank" rel="noreferrer">
            <div className={styles.contact_card__whatsapp} />
          </a>
        )}
        {feedback && (
          <ButtonLink href={feedback.url} variant="secondary">
            {feedback.text}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};
