import type { FC } from "react";

import { StrapiData } from "../types";

import styles from "./Disclaimer.module.scss";

export interface DisclaimerProps {
  text: string;
}

export const Disclaimer: FC<StrapiData<DisclaimerProps>> = ({ data }) => (
  <noindex>
    <div className={styles.disclaimer}>{data.text}</div>
  </noindex>
);
