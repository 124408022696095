import React from "react";
import classnames from "classnames";
import { getStrapiMedia } from "@/utils/media";

import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

interface CardProps {
  image: string;
  subtitle: string;
  text: string;
}

export const Card: React.FC<CardProps> = ({ image, subtitle, text }) => {
  return (
    <div className={styles.card}>
      <div className={classnames(styles.image, styles.image__container)}>
        <img src={getStrapiMedia(image)} alt={subtitle} className={styles.image} />
      </div>
      <p className={styles.subtitle}>{subtitle}</p>
      <HTMLFromRichTextEditor className={styles.text} htmlString={text} />
    </div>
  );
};
