import { FC } from "react";
import { YMaps, Map as MapView, Placemark } from "react-yandex-maps";
import cn from "classnames";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { Typography } from "@/components/shared/typography";

import styles from "./index.module.scss";

interface Data {
  title: ITitle;
  appearance: Appearance;
}

export const OurAddress: FC<StrapiData<Data>> = ({ data: { title, appearance } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={cn(styles.header, styles.content)}>
        <Title {...title} />
        <Typography className={cn(styles.address, styles.content)} type="simpleText">
          ул. Ленинская слобода, д. 19, стр.6 этаж 4
        </Typography>
      </div>
      <YMaps>
        <MapView
          defaultState={{
            center: [55.707547, 37.651169],
            zoom: 15,
            behaviors: ["default", "scrollZoom"],
          }}
          className={styles.mapView}
        >
          <Placemark
            geometry={[55.707547, 37.651169]}
            options={{
              iconLayout: "default#image",
              iconImageHref: "icons/map-marker.png",
              iconImageSize: [38, 46],
            }}
          />
        </MapView>
      </YMaps>
    </AppearanceWrapper>
  );
};
