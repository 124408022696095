import { FC } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Border } from "@/components/shared/border";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface CrossProductMobileProps {
  appearance: Appearance;
}

export const CrossProductMobile: FC<StrapiData<CrossProductMobileProps>> = ({ data: { appearance } }) => {
  const items: Array<{ icon: string; product: string; url: string }> = [
    {
      icon: "/icons/productKasko.svg",
      product: "ОСАГО",
      url: "/osago",
    },
    {
      icon: "/icons/productHelth.svg",
      product: "Здоровье",
      url: "/strahovanie-ns",
    },
    {
      icon: "/icons/productIpoteka.svg",
      product: "Ипотека",
      url: "/strahovanie-ipoteki",
    },
  ];

  const renderItems = () =>
    items.map((i) => (
      <Link href={i.url} key={i.icon} passHref>
        <a>
          <Border className={style.item} shadow>
            <Typography variant="h3" className={style.chip}>
              {i.product}
            </Typography>
            <img src={i.icon} alt={i.product} className={style.icon} />
          </Border>
        </a>
      </Link>
    ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Typography variant="h2" component="h2" className={style.title}>
          Вы можете оформить и другие полисы:
        </Typography>
        <div className={style.items}>{renderItems()}</div>
      </Container>
    </AppearanceWrapper>
  );
};
