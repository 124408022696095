import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData } from "@/components/sections/types";
import { IArticle } from "@/utils/api/types/mafin-media";
import { RelevantMaterials } from "@/components/mafin-media/sections/shared/relevant-materials";

export interface Data {
  title?: string;
  titleType: "h2" | "h3";
  articles: IArticle[];
  appearance: Appearance;
}

export const ActualMediaArticles: FC<StrapiData<Data>> = ({ data: { title, titleType, articles, appearance } }) => {
  return articles.length ? (
    <AppearanceWrapper appearance={appearance}>
      <RelevantMaterials openMaterialsInNewTab title={title} titleType={titleType} materials={articles} />
    </AppearanceWrapper>
  ) : (
    <></>
  );
};
