import React from "react";
import classnames from "classnames";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { StrapiData } from "../../sections/types";

import styles from "./index.module.scss";

export interface AboutCompanyProps {
  title: string;
  content: string;
  ceo: string;
  ceo_url: string;
  request_title: string;
  request_url: string;
  adress: string;
  inn: string;
  ogrn: string;
  telephone: string;
  site: string;
  site_url: string;
  offices_url?: string;
  quality_control_url?: string;
}

export const AboutCompany = ({ data: { title, content, ceo, ceo_url, inn, ogrn } }: StrapiData<AboutCompanyProps>) => {
  return (
    <div className={classnames(styles.About)}>
      <h1 className={styles.About__title}>{title}</h1>
      <div className={styles.About__table}>
        <div className={styles.About__table_col}>
          <HTMLFromRichTextEditor className={classnames(styles.About__text, styles.mb_20)} htmlString={content} />
        </div>

        <div className={styles.About__table_col}>
          <p className={styles.About__text_bold}>Руководство компании</p>
          <p className={styles.About__text}>{ceo}</p>
          <a
            href={ceo_url}
            target="_blank"
            rel="noreferrer"
            className={classnames(styles.About__text_link, styles.mb_20)}
          >
            Узнать больше
          </a>

          <p className={styles.About__text_bold}>ИНН</p>
          <p className={classnames(styles.About__text, styles.mb_20)}>{inn}</p>

          <p className={styles.About__text_bold}>ОГРН</p>
          <p className={classnames(styles.About__text, styles.mb_20)}>{ogrn}</p>
        </div>
      </div>
    </div>
  );
};
