import React, { useState, MouseEvent, useRef } from "react";
import cn from "classnames";
import { InputNumberNew, Tooltip } from "@mafin/ui-kit";
import { useAuth } from "@mafin/utilities";
import { getGrzWithSpaces, isValidGrz, setGrzToLocalStorage } from "@/utils/product-calculators";
import { LeadGenerationFixed } from "@/components/elements/lead-generation-fixed";
import { useIsInViewport } from "@/utils/hooks/use-is-in-viewport";
import { useBreakpoints } from "@/utils/hooks/use-window-size";

import { ButtonLink } from "../../elements/button-link";
import { Dropdown } from "../../elements/dropdown";
import { AppearanceBackground, StrapiData } from "../types";

import { Tabs } from "./tabs";
import { Tab } from "./tabs/components";
import styles from "./index.module.scss";

interface TabInsure {
  ins: boolean;
  link_text: string;
  link_url: string;
  text: string;
  title: string;
  iconNew?: boolean;
}

interface TabAuto {
  auto: boolean;
  button_text: string;
  button_url: string;
  col_1: string;
  col_2: string;
  col_3: string;
  link_title: string;
  link_url: string;
  title: string;
  iconNew?: boolean;
  type: "kasko" | "osago" | "ekasko";
}

export interface Data {
  tab_auto: TabAuto[];
  tab_insure: TabInsure[];
  background?: AppearanceBackground;
  offset?: "default" | "halfOfHeight";
  lightPage?: boolean;
}

export const LeadGeneration = ({ data }: StrapiData<Data>) => {
  const { tab_auto, tab_insure, background, offset, lightPage } = data;
  const containerRef = useRef<HTMLDivElement>(null);
  const { mobileOrMobileMini } = useBreakpoints();

  const isInViewport = useIsInViewport(containerRef);

  const [activeTab, setActiveTab] = useState(0);
  const resetTabs = () => setActiveTab(-1);

  const [desktopGrz, setDesktopGrz] = useState<string>("");
  const [mobileOsagoGrz, setMobileOsagoGrz] = useState<string>("");
  const [mobileKaskoGrz, setMobileKaskoGrz] = useState<string>("");
  const [mobileEkaskoGrz, setMobileEkaskoGrz] = useState<string>("");

  const [isLogined, setIsLogined] = useState(true);
  const { user } = useAuth();
  const isAuth = useRef(isLogined);

  isAuth.current = !!user.profile?.phone && !!user.profile?.firstName;

  setTimeout(() => setIsLogined(isAuth.current), 1500);

  const getBackgroundStyle = () => {
    const backgroundStyles: Record<AppearanceBackground, string> = {
      Bg: styles.backgroundBg,
      BgLight: styles.backgroundBgLight,
      White: styles.backgroundWhite,
    };

    return backgroundStyles[background || "BgLight"];
  };

  const getOffsetStyle = () => (offset === "halfOfHeight" ? styles.halfOfHeight : null);

  const onButtonClick =
    (url: string, type?: string, isMobile = false) =>
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      let grz = "";

      if (!isMobile) {
        grz = desktopGrz;
      } else {
        if (type === "kasko") {
          grz = mobileKaskoGrz;
        } else if (type === "osago") {
          grz = mobileOsagoGrz;
        } else if (type === "ekasko") {
          grz = mobileEkaskoGrz;
        }
      }

      const grzWithSpaces = getGrzWithSpaces(grz);

      if (isValidGrz(grzWithSpaces)) {
        setGrzToLocalStorage(grzWithSpaces);
      }

      window.location.href = `${window.location.protocol}//${window.location.host}${url}`;
    };

  const handleFixedClick = () => {
    if (mobileOrMobileMini) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBlur = () => null;

  const desktopAuto = tab_auto?.map((tab: TabAuto, index: number) => (
    <Tab title={tab.title} iconNew={tab.iconNew} key={index}>
      <span className={styles.tab__column}>{tab.col_1}</span>
      <div className={styles.grzContainer}>
        <div
          className={styles.input_grz}
          data-autotestid={`desktop-inputnumber-${index}`}
          data-testid={`desktop-inputnumber-${index}`}
        >
          <InputNumberNew value={desktopGrz} onChange={setDesktopGrz} onBlur={handleBlur} />
        </div>
        {!isLogined && (
          <Tooltip direction="right" mobileBtnText="Закрыть">
            Автоматический поиск и заполнение полей по транспортному средству с использованием гос. номера доступен для
            авторизованных пользователей.
          </Tooltip>
        )}
      </div>
      <span className={styles.tab__column}>
        {tab.col_2}
        <a href={tab.link_url} data-autotestid={`desktop-link-${index}`} data-testid={`desktop-link-${index}`}>
          {tab.link_title}
        </a>
        {tab.col_3}
      </span>
      <ButtonLink
        href={tab.button_url}
        onClick={onButtonClick(tab.button_url)}
        data-autotestid={`desktop-button-${index}`}
        data-testid={`desktop-button-${index}`}
      >
        {tab.button_text}
      </ButtonLink>
    </Tab>
  ));

  const singleTab = tab_auto?.map((tab: TabAuto, index: number) => (
    <div key={`singleTab-${index}`} className={cn(styles.single__tab, lightPage && styles.lightPage)}>
      <div className={styles.mobile__container}>
        <span>{tab.col_1}</span>
        <div className={styles.grzContainer}>
          <div data-autotestid={`mobile-inputnumber-${index}`} data-testid={`mobile-inputnumber-${index}`}>
            {tab.button_url === "/kasko/calc" && (
              <InputNumberNew value={mobileKaskoGrz} onChange={setMobileKaskoGrz} onBlur={handleBlur} />
            )}
            {tab.button_url === "/ekasko/calc" && (
              <InputNumberNew value={mobileEkaskoGrz} onChange={setMobileEkaskoGrz} onBlur={handleBlur} />
            )}
            {tab.button_url === "/osago/calc" && (
              <InputNumberNew value={mobileOsagoGrz} onChange={setMobileOsagoGrz} onBlur={handleBlur} />
            )}
          </div>
          {!isLogined && (
            <Tooltip direction="right" mobileBtnText="Закрыть">
              Автоматический поиск и заполнение полей по транспортному средству с использованием гос. номера доступен
              для авторизованных пользователей.
            </Tooltip>
          )}
        </div>
        <span>
          {tab.col_2}
          <a href={tab.link_url} data-autotestid={`mobile-link-${index}`} data-testid={`mobile-link-${index}`}>
            {tab.link_title}
          </a>
          {tab.col_3}
        </span>
        <ButtonLink
          onClick={onButtonClick(tab.button_url, tab.type, true)}
          href={tab.button_url}
          data-autotestid={`mobile-button-${index}`}
          data-testid={`mobile-button-${index}`}
        >
          {tab.button_text}
        </ButtonLink>
      </div>
    </div>
  ));

  const multipleTabs = tab_auto?.map((tab: TabAuto, index: number) => {
    const isOpened = index === activeTab;
    const handleToggle = (opened: boolean) => (opened ? resetTabs() : setActiveTab(index));

    return (
      <Dropdown
        title={tab.title}
        iconNew={tab.iconNew}
        key={`dropdown-${index}`}
        opened={isOpened}
        onClick={handleToggle}
      >
        <div className={styles.mobile__container}>
          <span>{tab.col_1}</span>
          <div className={styles.grzContainer}>
            <div data-autotestid={`mobile-inputnumber-${index}`} data-testid={`mobile-inputnumber-${index}`}>
              {tab.button_url === "/kasko/calc" && (
                <InputNumberNew value={mobileKaskoGrz} onChange={setMobileKaskoGrz} onBlur={handleBlur} />
              )}
              {tab.button_url === "/ekasko/calc/questionnaire" && (
                <InputNumberNew value={mobileEkaskoGrz} onChange={setMobileEkaskoGrz} onBlur={handleBlur} />
              )}
              {tab.button_url === "/osago/calc" && (
                <InputNumberNew value={mobileOsagoGrz} onChange={setMobileOsagoGrz} onBlur={handleBlur} />
              )}
            </div>
            {!isLogined && (
              <Tooltip direction="right" mobileBtnText="Закрыть">
                Автоматический поиск и заполнение полей по транспортному средству с использованием гос. номера доступен
                для авторизованных пользователей.
              </Tooltip>
            )}
          </div>
          <span>
            {tab.col_2}
            <a data-autotestid={`mobile-link-${index}`} data-testid={`mobile-link-${index}`} href={tab.link_url}>
              {tab.link_title}
            </a>
            {tab.col_3}
          </span>
          <ButtonLink
            data-autotestid={`mobile-button-${index}`}
            data-testid={`mobile-button-${index}`}
            href={tab.button_url}
            onClick={onButtonClick(tab.button_url, tab.type, true)}
          >
            {tab.button_text}
          </ButtonLink>
        </div>
      </Dropdown>
    );
  });

  const mobileAuto = tab_auto?.length === 1 ? singleTab : multipleTabs;

  const desktopInsure = tab_insure?.map((tab: TabInsure, index: number) => (
    <Tab title={tab.title} iconNew={tab.iconNew} key={index}>
      <span>{tab.text}</span>
      <ButtonLink
        href={tab.link_url}
        data-autotestid={`desktop-insure-btn-${index}`}
        data-testid={`desktop-insure-btn-${index}`}
      >
        {tab.link_text}
      </ButtonLink>
    </Tab>
  ));

  const mobileInsureSingleTab = tab_insure?.map((tab: TabInsure, index: number) => (
    <div className={cn(styles.mobile__container, styles.single__tab)} key={`dropdowninsure_single-${index}`}>
      <span>{tab.text}</span>
      <ButtonLink
        href={tab.link_url}
        data-autotestid={`mobile-insure-btn-${index}`}
        data-testid={`mobile-insure-btn-${index}`}
      >
        {tab.link_text}
      </ButtonLink>
    </div>
  ));

  const renderFixedPanel = () => {
    const isOneMotorProduct = tab_auto?.length === 1 && (!tab_insure || tab_insure?.length < 1);

    if (isOneMotorProduct) {
      return (
        <LeadGenerationFixed
          className={cn(isInViewport && styles.hidden)}
          button={{ url: tab_auto[0].button_url, text: "Рассчитать" }}
          onClick={handleFixedClick}
        />
      );
    }
  };

  const mobileInsureMultipleTabs = tab_insure?.map((tab: TabInsure, index: number) => {
    const isFirst = index === 0;
    const tabIndex = tab_auto?.length ? tab_auto?.length + index : index;
    const isOpened = activeTab === tabIndex;
    const hasTabAuto = !!tab_auto?.length;
    const shouldOpen = (isFirst && !hasTabAuto) || isOpened;
    const handleToggle = (opened: boolean) => (opened ? resetTabs() : setActiveTab(tabIndex));

    return (
      <Dropdown
        key={`dropdowninsure_multiple-${index}`}
        title={tab.title}
        iconNew={tab.iconNew}
        opened={shouldOpen}
        onClick={handleToggle}
      >
        <div className={styles.mobile__container}>
          <span>{tab.text}</span>
          <ButtonLink
            href={tab.link_url}
            data-autotestid={`mobile-insure-btn-${index}`}
            data-testid={`mobile-insure-btn-${index}`}
          >
            {tab.link_text}
          </ButtonLink>
        </div>
      </Dropdown>
    );
  });

  const showSingleTab = tab_auto?.length <= 1 && tab_insure?.length === 1 && !tab_insure[0].title;
  const mobileInsure = showSingleTab ? mobileInsureSingleTab : mobileInsureMultipleTabs;
  const allDesktopData = desktopAuto.concat(desktopInsure);
  const allMobileData = mobileAuto.concat(mobileInsure);

  return (
    <div ref={containerRef} className={cn(styles.wrapper, getBackgroundStyle())}>
      <div className={cn(styles.desktop, getOffsetStyle())}>
        <Tabs>{allDesktopData}</Tabs>
      </div>
      <div className={styles.mobile}>{tab_auto?.length === 1 ? mobileAuto : allMobileData}</div>
      {renderFixedPanel()}
    </div>
  );
};
