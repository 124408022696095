import { Breadcrumbs } from "@mafin/ui-kit";
import cn from "classnames";

import { Breadcrumb, StrapiData } from "../types";

import styles from "./index.module.scss";

interface Data {
  breadcrumbs: Breadcrumb[];
  paddingBottom?: boolean;
  className?: string;
}

export const BreadcrumbsSection = ({ data }: StrapiData<Data>) => {
  const breadcrumbs = data.breadcrumbs?.map((breadcrumb: Breadcrumb) => {
    return { link: breadcrumb.link, name: breadcrumb.name };
  });

  return (
    <div className={cn(styles.content, data.className)}>
      <div className={cn(styles.breadcrumbs, data.paddingBottom && styles.paddingBottom)}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
    </div>
  );
};
