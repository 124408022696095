import { useState, useEffect, MouseEvent } from "react";
import cn from "classnames";
import { SkLicenses, SKLicensesProps } from "@/components/elements/sk-licenses";
import { AboutCompany, AboutCompanyProps } from "@/components/elements/about-company";
import { SkDocuments, SkDocumentsProps } from "@/components/elements/sk-documents";
import { SkContacts } from "@/components/elements/sk-contacts/SkContacts";

import styles from "./index.module.scss";

interface Data {
  data: Tabs;
}

interface Tabs {
  about: AboutCompanyProps;
  documents: SkDocumentsProps;
  licenses: SKLicensesProps;
}

const ABOUT_TAB = { slug: "about", title: "О компании" };
const DOCUMENTS_TAB = { slug: "documents", title: "Документы" };
const LICENSES_TAB = { slug: "licenses", title: "Лицензии" };
const CONTACTS_TAB = { slug: "contacts", title: "Контакты" };
const tabs = [ABOUT_TAB, DOCUMENTS_TAB, LICENSES_TAB, CONTACTS_TAB];

export const InsuranceTabs = ({ data: { about, documents, licenses } }: Data) => {
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    const activeTab = window.location.hash.slice(1);
    const isValidTab = tabs.some(({ slug }) => slug === activeTab);

    setSelectedTab(isValidTab ? activeTab : ABOUT_TAB.slug);
  }, []);

  const handleTabClick = (tab: string) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  return (
    <div className={styles.insurance_tabs}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            className={cn(styles.about, {
              [styles.opened]: selectedTab === ABOUT_TAB.slug,
            })}
          >
            <AboutCompany data={about} />
          </div>
          <div
            className={cn(styles.documents, {
              [styles.opened]: selectedTab === DOCUMENTS_TAB.slug,
            })}
          >
            <SkDocuments data={documents} />
          </div>
          <div
            className={cn(styles.licences, {
              [styles.opened]: selectedTab === LICENSES_TAB.slug,
            })}
          >
            <SkLicenses data={licenses} />
          </div>
          <div
            className={cn(styles.contacts, {
              [styles.opened]: selectedTab === CONTACTS_TAB.slug,
            })}
          >
            <SkContacts data={about} />
          </div>
          <div className={styles.tabs__links}>
            {tabs.map(({ slug, title }) => (
              <a key={slug} className={selectedTab === slug ? styles.activeTab : ""} onClick={handleTabClick(slug)}>
                {title}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
