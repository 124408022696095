export const INSURANCE_COMPANIES = [
  {
    id: "Absolut",
    name: "Абсолют Страхование",
  },
  {
    id: "Alpha",
    name: "Альфа страхование",
  },
  {
    id: "Ingo",
    name: "Ингосстрах",
  },
  {
    id: "Renesans",
    name: "Ренессанс",
  },
  {
    id: "Rosgostrah",
    name: "Росгосстрах",
  },
  {
    id: "Soglasie",
    name: "Согласие",
  },
  {
    id: "Tinkoff",
    name: "Тинькофф",
  },
  {
    id: "VSK",
    name: "ВСК",
  },
  {
    id: "SOGAS",
    name: "СОГАЗ",
  },
  {
    id: "Intouch",
    name: "Intouch",
  },
  {
    id: "Zetta",
    name: "Zetta",
  },
  {
    id: "MAX",
    name: "Макс Страхование",
  },
  {
    id: "Astro",
    name: "Астро-волга",
  },
  {
    id: "Ugoriya",
    name: "Югория",
  },
  {
    id: "OSK",
    name: "ОСК (Объединенная страховая компания)",
  },
  {
    id: "ASKO",
    name: "АСКО",
  },
  {
    id: "Sber",
    name: "Сбербанк Страхование",
  },
];

export const SERIES = [
  {
    id: "ССС",
    name: "ССС",
  },
  {
    id: "XXX",
    name: "XXX",
  },
  {
    id: "РРР",
    name: "РРР",
  },
  {
    id: "ННН",
    name: "ННН",
  },
  {
    id: "MMM",
    name: "MMM",
  },
  {
    id: "KKK",
    name: "KKK",
  },
  {
    id: "EEE",
    name: "EEE",
  },
  {
    id: "BBB",
    name: "BBB",
  },
];
