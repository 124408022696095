import { FC, ReactNode } from "react";
import cn from "classnames";

import style from "./index.module.scss";

interface BorderProps {
  shadow?: boolean;
  className?: string;
  children: ReactNode;
}

export const Border: FC<BorderProps> = ({ shadow, className, children }) => {
  return <div className={cn(style.container, shadow && style.shadow, className)}>{children}</div>;
};
