import React from "react";
import classnames from "classnames";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import { Link, StrapiData } from "../../sections/types";

import styles from "./index.module.scss";

interface Documents {
  subtitle: string;
  link: Link[];
}

export interface SkDocumentsProps {
  title: string;
  h1Title?: boolean;
  document: Documents[];
}

export const SkDocuments = ({ data: { title, document, h1Title } }: StrapiData<SkDocumentsProps>) => {
  const documents = document?.map((item, index) => {
    return (
      <div className={styles.section} key={`section${index}`}>
        <h3 className={styles.subtitle}>{item.subtitle}</h3>
        <div className={styles.links_block}>
          {item.link?.map((link, index) => {
            return (
              <a key={`link${index}`} className={styles.link} {...strapiLinkComponentToAnchorProps(link)}>
                {link.text}
              </a>
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className={classnames(styles.sk_documents)}>
      {h1Title ? <h1 className={styles.title}>{title}</h1> : <h2 className={styles.title}>{title}</h2>}
      {documents}
    </div>
  );
};
