import { useContext } from "react";
import { BankPartner } from "@/utils/globalContext/types";
import { useRouter } from "next/router";
import { Data } from "@/components/sections/partners-new";

import { GlobalContext } from "../../pages/_app";

export interface PartnerCard extends Omit<BankPartner, "href"> {
  href: string | null;
}

export const useBankPartnerCards = ({ linkType, linkPrefix }: Omit<Data, "appearance">) => {
  const { bank_partners, products, turboPageSlug } = useContext(GlobalContext);
  const router = useRouter();
  const slug = router?.query.slug || turboPageSlug;

  const getPartnersForCurrentProduct = (): BankPartner[] => {
    const currentProduct = Array.isArray(slug) ? slug[0] : null;
    const isProductPage = products.some(({ slug }) => slug === currentProduct);

    return currentProduct && isProductPage
      ? bank_partners.filter((bank) => bank.products.products.some((product) => product.slug === currentProduct))
      : bank_partners;
  };

  const setHrefByLinkType = (partnerCards: BankPartner[]): PartnerCard[] => {
    if (linkType === "noLinks") {
      return partnerCards.map((partner) => ({ ...partner, href: null }));
    } else if (linkType === "custom") {
      return partnerCards.map((partner) => ({ ...partner, href: `${linkPrefix}/${partner.slug}` }));
    }

    return partnerCards.map((partner) => ({ ...partner, href: partner.href }));
  };

  return setHrefByLinkType(getPartnersForCurrentProduct().sort((a, b) => b.orderWeight - a.orderWeight));
};
