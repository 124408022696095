import React from "react";
import classnames from "classnames";
import { AboutCompanyProps } from "@/components/elements/about-company";

import { StrapiData } from "../../sections/types";

import styles from "./index.module.scss";

export const SkContacts = ({
  data: { request_title, request_url, adress, telephone, site, offices_url, quality_control_url },
}: StrapiData<AboutCompanyProps>) => {
  return (
    <div className={classnames(styles.About)}>
      <h2 className={styles.About__title}>Контакты</h2>
      <div className={styles.About__table}>
        <div className={styles.About__table_col}>
          <p className={styles.About__text_bold}>Адрес регистрации</p>
          <p className={classnames(styles.About__text, styles.mb_20)}>{adress}</p>

          <p className={styles.About__text_bold}>Телефон</p>
          <p className={classnames(styles.About__text, styles.mb_20)}>{telephone}</p>

          <p className={styles.About__text_bold}>Официальный сайт</p>
          <p className={styles.site}>{site}</p>
        </div>

        <div className={styles.About__table_col}>
          <p className={styles.About__text_bold}>{request_title}</p>
          <a
            href={request_url}
            rel="noreferrer"
            target="_blank"
            className={classnames(styles.About__text_link, styles.mb_20)}
          >
            Перейти на сайт компании
          </a>

          {offices_url && (
            <>
              <p className={styles.About__text_bold}>Офисы компании</p>
              <a
                href={offices_url}
                rel="noreferrer"
                target="_blank"
                className={classnames(styles.About__text_link, styles.mb_20)}
              >
                Найти на карте
              </a>
            </>
          )}

          {quality_control_url && (
            <>
              <p className={styles.About__text_bold}>Контроль качества</p>
              <a
                href={quality_control_url}
                rel="noreferrer"
                target="_blank"
                className={classnames(styles.About__text_link, styles.mb_20)}
              >
                Подать обращение
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
