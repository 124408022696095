import React, { FC } from "react";
import { Link } from "@mafin/ui-kit";

import { getStrapiMedia } from "@/utils/media";

import Image from "next/image";

import styles from "./index.module.scss";

export interface MaterialCardMiniProps {
  cover: { url: string };
  cardTitle: string;
  cardShowMore: string;
}

export const MaterialCardMini: FC<MaterialCardMiniProps> = ({ cover, cardTitle, cardShowMore }) => {
  const url = getStrapiMedia(cover.url);

  return (
    <div className={styles.wrapper}>
      <Link className={styles.link} href={cardShowMore}>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.cover}
            src={url}
            alt="cover"
            sizes="(max-width: 979px) 169px, 230px"
            objectFit="cover"
            layout="fill"
            quality={70}
          />
          <div className={styles.container}>
            <div className={styles.title}>{cardTitle}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
