import { FC, HTMLProps, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

interface RangeSliderProps extends HTMLProps<HTMLInputElement> {
  value: number;
  min?: number;
  max?: number;
}

export const RangeSlider: FC<RangeSliderProps> = ({ className, value = 0, min = 0, max = 100, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref?.current?.style.setProperty("--value", String(value));
    ref?.current?.style.setProperty("--min", String(min));
    ref?.current?.style.setProperty("--max", String(max));
  }, [max, min, value]);

  return (
    <div className={styles.container}>
      <input
        ref={ref}
        type="range"
        min={min}
        max={max}
        value={value}
        {...props}
        className={cn(styles.slider, className)}
        id="myRange"
      />
    </div>
  );
};
