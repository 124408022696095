import * as React from "react";
import { memo } from "react";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

interface StepProps {
  content: string;
}

export const Step = memo(function Step({ content }: StepProps) {
  return (
    <div className={styles.description}>
      <HTMLFromRichTextEditor className={styles.text} htmlString={content} />
    </div>
  );
});
