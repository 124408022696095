import React, { FC } from "react";
import { Image as IImage } from "@/components/sections/types";
import { getStrapiMedia } from "@/utils/media";
import { formatPublicationDate } from "@/components/mafin-media/utils/helpers";
import { Typography } from "@/components/shared/typography";
import { MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";
import Link from "next/link";
import Image from "next/image";
import { getImageSizes } from "@/utils/next-image-helpers";

import styles from "./index.module.scss";

interface CardProps {
  image: IImage;
  title: string;
  publicationDate: string;
  rubricSlug: string;
  articleSlug: string;
  inNewTab?: boolean;
}

export const Card: FC<CardProps> = ({ image, title, publicationDate, articleSlug, rubricSlug, inNewTab = false }) => {
  const href = `${MAFIN_MEDIA_ROUTES.media}/${rubricSlug}/${articleSlug}`;

  return (
    <Link href={href}>
      <a target={inNewTab ? "_blank" : undefined}>
        <div className={styles.container}>
          <div className={styles.imageWrapper}>
            <Image
              src={getStrapiMedia(image)}
              objectFit="cover"
              objectPosition="right"
              placeholder="blur"
              blurDataURL={getStrapiMedia(image, "thumbnail")}
              layout="fill"
              quality={70}
              sizes={getImageSizes("286px", "166px", "228px", "267px")}
              alt={image?.alternativeText}
            />
          </div>
          <div className={styles.description}>
            <Typography className={styles.date} type="littleText" suppressHydrationWarning>
              {formatPublicationDate(publicationDate)}
            </Typography>
            <Typography type="h3" useParagraph>
              {title}
            </Typography>
          </div>
        </div>
      </a>
    </Link>
  );
};
