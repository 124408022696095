import { FunctionComponent, memo } from "react";
import { ContentSection, StrapiData } from "@/components/sections/types";
import { PreviewModeBanner } from "@/components/shared/preview-mode-banner";
import { SovcomLeadgenerationBanner } from "@/components/sections/sovcom-leadgeneration/sovcom-leadgeneration-banner";
import { SovcomLeadgenerationCompact } from "@/components/sections/sovcom-leadgeneration/sovcom-leadgeneration-compact";
import { ProductComparisonCards } from "@/components/sections/product-comparison-cards";
import { TitleWithText } from "@/components/sections/title-with-text";
import { FlipCards } from "@/components/sections/flip-cards";
import { ActualMediaArticles } from "@/components/sections/actual-media-articles";
import { NewsByTag } from "@/components/sections/news-by-tag";
import { CitiesChips } from "@/components/sections/cities-chips";
import { PartnersPSO } from "@/components/sections/partners-pso";
import { KaskoMobile } from "@/components/sections/kasko-mobile";
import { TransferProgram } from "@/components/sections/transfer-program";
import { MortgageLife } from "@/components/sections/mortgage-life";

import { Feedback } from "./sections/feedback/Feedback";
import { RichText } from "./sections/rich-text";
import { Banner } from "./sections/banner";
import { LeadGeneration } from "./sections/lead-generation";
import { Partners } from "./sections/partners";
import { ChooseProduct } from "./sections/choose-product";
import { Safety } from "./sections/safety";
import { Application } from "./sections/application";
import { Advantages } from "./sections/advantages";
import { MaterialCards } from "./sections/material-cards";
import { UsefulLinks } from "./sections/useful-links";
import { DownloadApp } from "./sections/download-app";
import { ScriptSection } from "./sections/script";
import { Faq } from "./sections/faq";
import { RichFaq } from "./sections/rich-faq";
import { PolicyGenuine } from "./sections/policy-genuine";
import { Epolicy } from "./sections/e-policy";
import { Steps } from "./sections/steps";
import { MobileAppBottom } from "./sections/mobile-app-bottom";
import { BreadcrumbsSection } from "./sections/breadcrumbs";
import { InsuranceCompany } from "./sections/insurance-company";
import { InsuranceTabs } from "./sections/insurance-tabs";
import { JuristicDocuments } from "./sections/juristic-documents";
import { PartnersDocsLicenses } from "./sections/partners-docs-licenses";
import { WorkWithPeople } from "./sections/work-with-people";
import { HistoryAndDevelopment } from "./sections/history-and-development";
import { ContactUs } from "./sections/contact-us";
import { StaticBanner } from "./sections/static-banner";
import { Disclaimer } from "./sections/disclaimer";
import { ProductAdvantages } from "./sections/product-advantages";
import { SeeAlsoBig } from "./sections/see-also-big";
import { SeeAlsoSmall } from "./sections/see-also-small";
import { CalculateTheCost } from "./sections/calculate-the-cost";
import { MainInsuranceCases } from "./sections/main-insurance-cases";
import { KaskoProtectCards } from "./sections/kasko-protect-cards";
import { ProductContactUs } from "./sections/product-contact-us";
import { ProductBanner } from "./sections/product-banner";
import { AvailableOptionsForPolicyProtection } from "./sections/available-options-for-policy-protection";
import { SeoMediaBlock } from "./sections/seo-media-block";
import { MoreAboutRisk } from "./sections/more-about-risk";
import { OurAddress } from "./sections/our-address";
import { InsuranceCase } from "./sections/insurance-case";
import { MarkdownWithImage } from "./sections/markdown-with-image";
import { BrandsModelsChips } from "./sections/brands-models-chips";
import { AllPartners } from "./sections/all-partners";
import { TitleTextImage } from "./sections/title-text-image";
import { PopularBrands } from "./sections/popular-brands";
import { AllBrands } from "./sections/all-brands";
import { TitleTextButton } from "./sections/title-text-button";
import { CrossProduct } from "./sections/cross-product";
import { FeedbackBanner } from "./sections/feedback-banner";
import { PartnersNew } from "./sections/partners-new";
import { BankPartners } from "./sections/bank-partners";
import { AdvantagesNew } from "./sections/advantages-new";
import { CustomerReviews } from "./sections/customer-reviews";
import { ProductComparison } from "./sections/product-comparison";
import { StepsNew } from "./sections/steps-new";
import { CallToAction } from "./sections/call-to-action";
import { LeadGenerationMotor } from "./sections/lead-generation-motor";
import { ProductDescriptionWithTabs } from "./sections/product-description-with-tabs";
import { LeadGenerationNotMotor } from "./sections/lead-generation-not-motor";
import { CrossLinks } from "./sections/cross-links";

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// Map Strapi sections to section components
/* eslint-disable @typescript-eslint/no-explicit-any */
const sectionComponents: Record<string, FunctionComponent<StrapiData<any>>> = {
  "sections.breadcrumbs": BreadcrumbsSection,
  "sections.rich-text": RichText,
  "sections.slider": Banner,
  "sections.see-also-small": SeeAlsoSmall,
  "sections.calculate-the-cost": CalculateTheCost,
  "sections.choose-product": ChooseProduct,
  "sections.lead-generation": LeadGeneration,
  "sections.safety-section": Safety,
  "sections.partners": Partners,
  "sections.more-about-risk": MoreAboutRisk,
  "sections.banner": Banner,
  "sections.app": Application,
  "sections.main-insurance-cases": MainInsuranceCases,
  "sections.advantages": Advantages,
  "sections.material-cards": MaterialCards,
  "sections.useful-links": UsefulLinks,
  "sections.qr": DownloadApp,
  "sections.script": ScriptSection,
  "sections.faq": Faq,
  "sections.policy-genuine": PolicyGenuine,
  "sections.e-policy": Epolicy,
  "sections.steps": Steps,
  "sections.mobile-app-bottom": MobileAppBottom,
  "sections.insurance-company": InsuranceCompany,
  "sections.insurance-company-tabs": InsuranceTabs,
  "sections.kasko-protect-cards": KaskoProtectCards,
  "sections.juristic-documents": JuristicDocuments,
  "sections.partners-docs-licenses": PartnersDocsLicenses,
  "sections.work-with-people": WorkWithPeople,
  "sections.history-and-development": HistoryAndDevelopment,
  "sections.contact-us": ContactUs,
  "sections.static-banner": StaticBanner,
  "sections.rich-faq": RichFaq,
  "sections.disclaimer": Disclaimer,
  "sections.product-contact-us": ProductContactUs,
  "sections.product-banner": ProductBanner,
  "sections.product-advantages": ProductAdvantages,
  "sections.available-options-for-policy-protection": AvailableOptionsForPolicyProtection,
  "sections.seo-media-block": SeoMediaBlock,
  "sections.see-also-big": SeeAlsoBig,
  "sections.insurance-case": InsuranceCase,
  "sections.all-partners": AllPartners,
  "sections.title-text-image": TitleTextImage,
  "sections.feedback": Feedback,
  "sections.our-address": OurAddress,
  "sections.markdown-with-image": MarkdownWithImage,
  "sections.brands-models-chips": BrandsModelsChips,
  "sections.title-text-button": TitleTextButton,
  "sections.cross-product": CrossProduct,
  "sections.popular-brands": PopularBrands,
  "sections.all-brands": AllBrands,
  "sections.feedback-banner": FeedbackBanner,
  "sections.bank-partners": BankPartners,
  "sections.partners-new": PartnersNew,
  "sections.advantages-new": AdvantagesNew,
  "sections.customer-reviews": CustomerReviews,
  "sections.sovcom-leadgeneration-banner": SovcomLeadgenerationBanner,
  "sections.sovcom-leadgeneration-compact": SovcomLeadgenerationCompact,
  "sections.product-comparison": ProductComparison,
  "sections.steps-new": StepsNew,
  "sections.product-comparison-cards": ProductComparisonCards,
  "sections.title-with-text": TitleWithText,
  "sections.flip-cards": FlipCards,
  "sections.call-to-action": CallToAction,
  "sections.lead-generation-motor": LeadGenerationMotor,
  "sections.product-description-with-tabs": ProductDescriptionWithTabs,
  "sections.lead-generation-not-motor": LeadGenerationNotMotor,
  "sections.cross-links": CrossLinks,
  "sections.actual-media-articles": ActualMediaArticles,
  "sections.news-by-tag": NewsByTag,
  "sections.cities-chips": CitiesChips,
  "sections.partners-pso": PartnersPSO,
  "sections.kasko-mobile": KaskoMobile,
  "sections.transfer-program": TransferProgram,
  "sections.mortgage-life": MortgageLife,
};

interface SectionsProps {
  preview?: boolean;
  sections: ContentSection[];
}

interface SectionProps {
  sectionData: ContentSection;
}

/* eslint-disable react/no-multi-comp */

// Display a section individually
const Section = memo<SectionProps>(function Section({ sectionData }) {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component];

  if (!SectionComponent) {
    return null;
  }

  // Display the section
  return <SectionComponent data={sectionData} />;
});

// Display the list of sections
export const Sections = memo<SectionsProps>(function Sections({ sections, preview }) {
  return (
    <div>
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections?.map((section) => (
        <Section sectionData={section} key={`${section.__component}${section.id}`} />
      ))}
    </div>
  );
});
