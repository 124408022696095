import { useContext } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Typography } from "@/components/shared/typography";

import { GlobalContext } from "../../../pages/_app";

import styles from "./index.module.scss";

export interface Data {
  appearance: Appearance;
}

export const AllPartners = ({ data: { appearance } }: StrapiData<Data>) => {
  const { partners } = useContext(GlobalContext);

  const sortedPartners = [...(partners || [])].sort((a, b) => a.name.localeCompare(b.name, "ru"));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        {sortedPartners.map((partner) => (
          <div key={partner.id} className={styles.partnerCardWrapper}>
            <Link href={partner.href}>
              <a className={styles.partnerCard}>
                <img src={partner.icon.url} alt="icon" className={styles.partnerImg} />
              </a>
            </Link>
            <Link href={partner.href}>
              <a>
                <Typography className={styles.cardTitle} type="littleLink" color="additional">
                  {partner.name}
                </Typography>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </AppearanceWrapper>
  );
};
