import { getStrapiMedia } from "@/utils/media";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import React from "react";

import { Appearance, Image, StrapiData, Title as ITitle } from "../types";

import styles from "./index.module.scss";

interface Data {
  rows: Array<{ row: string }>;
  image: Image;
  title?: ITitle;
  appearance: Appearance;
}

export const Epolicy = ({ data }: StrapiData<Data>) => {
  const { title, image, rows, appearance } = data;

  const content = rows.map((item, index) => (
    <HTMLFromRichTextEditor className={styles.content__row} key={`epolicy_row_${index}`} htmlString={item.row} />
  ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.epolicy}>
        <img className={styles.image} src={getStrapiMedia(image.url)} alt={title?.text} />
        <div className={styles.data}>
          {title?.text && <Title {...title} className={styles.title} />}
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
