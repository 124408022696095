import { NextSeo } from "next-seo";
import { getStrapiMedia } from "utils/media";
import { useRouter } from "next/router";
import { BASE_URL } from "@/utils/constants/paths";

/* eslint-disable complexity */
const Seo = ({ metadata, defaultMetadata }) => {
  const router = useRouter();

  // Prevent errors if no metadata was set
  if (!metadata && !defaultMetadata) return null;

  const canonical = `${BASE_URL}${router.asPath.split("?")[0]}`;
  let currentUrl;

  if (typeof window !== "undefined") {
    currentUrl = window.location.href;
  }

  const getAdditionalMetaTags = () => {
    const tags = [
      {
        property: "og:image:secure_url",
        content: metadata.shareImage
          ? getStrapiMedia(metadata.shareImage.url)
          : getStrapiMedia(defaultMetadata.shareImage.url),
      },
      {
        name: "verify-admitad",
        content: "95a2f86ece",
      },
    ];

    if (metadata.metaKeywords) {
      tags.push({
        name: "keywords",
        content: metadata.metaKeywords,
      });
    }

    return tags;
  };

  return (
    <NextSeo
      site_name="Mafin"
      title={metadata.metaTitle}
      description={metadata.metaDescription}
      canonical={canonical}
      additionalMetaTags={getAdditionalMetaTags()}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "/favicon.ico",
          type: "image/x-icon",
        },
      ]}
      noindex={metadata.noindex}
      nofollow={metadata.nofollow}
      openGraph={{
        title: metadata.ogTitle || metadata.metaTitle,
        description: metadata.ogDescription || metadata.metaDescription,
        type: "website",
        site_name: "Mafin",
        url: currentUrl,
        ...(metadata.shareImage
          ? {
              images: [
                {
                  url: getStrapiMedia(metadata.shareImage.url),
                },
              ],
            }
          : {
              images: [
                {
                  url: getStrapiMedia(defaultMetadata.shareImage.url),
                },
              ],
            }),
      }}
    />
  );
};

export default Seo;
