import { ProductCard, ProductCardProps } from "@mafin/ui-kit";

import { StrapiData } from "../types";

import styles from "./index.module.scss";

interface Title {
  text: string;
}

interface Data {
  title: Title;
  cards: ProductCardProps[];
}

export const ChooseProduct = ({ data }: StrapiData<Data>) => {
  return (
    <div className={styles.choose_product}>
      {data.title && <h2 className={styles.title}>{data.title.text}</h2>}
      <div className={styles.cards}>
        {data.cards?.map((card: ProductCardProps, index: number) => (
          <ProductCard key={`product-card${index}`} {...card} />
        ))}
      </div>
    </div>
  );
};
