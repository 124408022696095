import { Breadcrumb } from "@/components/sections/types";
import { BASE_BREAD_CRUMBS, MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";
import { IBestMaterial, IRubric } from "@/utils/api/types/mafin-media";

export const getBreadCrumbFromRubric = (rubric: IRubric): Breadcrumb => ({
  link: `${MAFIN_MEDIA_ROUTES.media}/${rubric.slug}`,
  name: rubric.title,
});

export const getArticleBreadCrumbs = (rubric: IRubric, articleBreadCrumb: string): Breadcrumb[] => [
  ...BASE_BREAD_CRUMBS,
  getBreadCrumbFromRubric(rubric),
  { link: "", name: articleBreadCrumb },
];

const isYesterday = (date: Date) => {
  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);

  return yesterday.toDateString() === date.toDateString();
};

const isToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const getDay = (date: Date) => {
  if (isToday(date)) {
    return "Сегодня";
  }

  return "Вчера";
};

export const formatPublicationDate = (publicationDate: string, withTime = false) => {
  const months: Record<string, string> = {
    "0": "января",
    "1": "февраля",
    "2": "марта",
    "3": "апреля",
    "4": "мая",
    "5": "июня",
    "6": "июля",
    "7": "августа",
    "8": "сентября",
    "9": "октября",
    "10": "ноября",
    "11": "декабря",
  };

  const date = new Date(publicationDate);
  const year = date.getFullYear();
  const month = String(date.getMonth());
  const day = date.getDate();
  const hours = date.getHours();
  const minutesDraft = date.getMinutes();
  const minutes = String(minutesDraft).length > 1 ? minutesDraft : `0${minutesDraft}`;

  if (isToday(date) || isYesterday(date)) {
    return `${getDay(date)}${withTime ? ` ${hours}:${minutes}` : ""}`;
  }

  return `${day} ${months[month]} ${year}${withTime ? ` ${hours}:${minutes}` : ""}`;
};

export const putRubricsToBestMaterials = (bestMaterials: IBestMaterial[], rubrics: IRubric[]) => {
  if (!Array.isArray(bestMaterials) || !Array.isArray(rubrics)) {
    return [];
  }

  const draft = [...bestMaterials];

  draft.forEach((i) => {
    i.rubric = rubrics.find((rubric) => rubric.id === (i.rubric as unknown as number)) || i.rubric;
  });

  return draft;
};

export const getRubricBySlug = (rubricSlug: string, rubrics: IRubric[]) => {
  for (const rubric of rubrics) {
    if (rubric.slug === rubricSlug) return rubric;
  }
};
