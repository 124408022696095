export const passAttributes = (
  script: HTMLScriptElement,
  attributes: string[][],
  container: HTMLDivElement | null
): void => {
  attributes.forEach((attribute) => {
    const [attr, value] = attribute;

    script.setAttribute(attr, value);
  });

  container?.appendChild(script);
};
