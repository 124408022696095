import { FC, MouseEvent, useState } from "react";
import { InputNumberNew, Typography } from "@mafin/ui-kit";

import { getGrzWithSpaces, isValidGrz, setGrzToLocalStorage } from "@/utils/product-calculators";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { ButtonLink } from "@/components/elements/button-link";
import { Link as ILink } from "@/components/sections/types";
import cn from "classnames";

import styles from "./index.module.scss";

interface LeadGenerationFixedProps {
  onClick?(): void;

  className?: string;
  button: ILink;
}

export const LeadGenerationFixed: FC<LeadGenerationFixedProps> = ({ onClick, button, className }) => {
  const [grz, setGrz] = useState("");

  const handleButtonClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const grzWithSpaces = getGrzWithSpaces(grz);

    if (isValidGrz(grzWithSpaces)) {
      setGrzToLocalStorage(grzWithSpaces);
    }

    window.open(
      `${window.location.protocol}//${window.location.host}${button.url || "/404"}`,
      button.newTab ? "_blank" : "_self"
    );
  };

  const handleBlur = () => null;

  return (
    <div onClick={onClick} className={cn(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.textPart}>
          <Typography variant="h4" component="paragraph">
            Введите номер авто
          </Typography>
          <Typography color="var(--additional)" variant="inputText" component="paragraph">
            Данные заполнятся автоматически
          </Typography>
        </div>
        <InputNumberNew onChange={setGrz} value={grz} className={styles.input} onBlur={handleBlur} />
        <ButtonLink {...strapiLinkComponentToAnchorProps(button)} onClick={handleButtonClick} className={styles.button}>
          {button.text}
        </ButtonLink>
      </div>
    </div>
  );
};
