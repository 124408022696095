import React, { ReactNode } from "react";

import styles from "./index.module.scss";

interface Props {
  title: string;
  iconNew?: boolean;
  children: ReactNode;
}

export const Tab: React.FC<Props> = ({ children }) => {
  return <div className={styles.tab}>{children}</div>;
};
