import { FC } from "react";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { INews, ITag } from "@/utils/api/types/mafin-media";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { News } from "@/components/mafin-media/sections/main-page/news";

import styles from "./index.module.scss";

export interface Data {
  appearance: Appearance;
  title: ITitle;
  tag: ITag;
  news: INews[];
}

export const NewsByTag: FC<StrapiData<Data>> = ({ data: { title, news = [], appearance } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        {title && <Title className={styles.title} {...title} />}
        <News onlyCards news={news} />
      </div>
    </AppearanceWrapper>
  );
};
