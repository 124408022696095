import { memo, useCallback, useEffect, useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { CollapseCard, PopUp, Toast } from "@mafin/ui-kit";
import {
  ContactInformation,
  ContactInformationForm,
} from "@/components/sections/insurance-case/contacts/contact-information";
import {
  InsuranceCaseInformation,
  InsuranceCaseInformationForm,
} from "@/components/sections/insurance-case/insurance-case-information";
import { Circumstances, CircumstancesFrom } from "@/components/sections/insurance-case/circumstances";
import { Documents } from "@/components/sections/insurance-case/documents";
import { InspectionPlace } from "@/components/sections/insurance-case/inspection-place";
import { SubmitHandler, useForm } from "react-hook-form";
import { Expert } from "@/utils/api/types/main-site";
import { sendInsuranceCase } from "@/utils/api/requests/main-site";
import { CAPTCHA } from "@/utils/constants/keys";

import styles from "./index.module.scss";

interface Data {
  appearance: Appearance;
  title: ITitle;
}

export const InsuranceCase = memo<StrapiData<Data>>(function InsuranceCase({ data: { title, appearance } }) {
  const errorTimeout = useRef<number>();
  const [contactInformation, setContactInformation] = useState<ContactInformationForm | null>(null);
  const [isContactInformationValid, setIsContactInformationValid] = useState(false);
  const [insuranceCaseInformation, setInsuranceCaseInformation] = useState<InsuranceCaseInformationForm | null>(null);
  const [isInsuranceCaseInformationValid, setIsInsuranceCaseInformationValid] = useState(false);
  const [place, setPlace] = useState<Expert | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CircumstancesFrom>();

  const handleSend: SubmitHandler<CircumstancesFrom> = (circumstancesData) => {
    const [day, month, year] = circumstancesData?.date.split(".");
    const [hour, minute] = circumstancesData?.time.split(":");

    const dataForSending = {
      phone: contactInformation?.phone,
      policynumber: `${contactInformation?.policySeries?.name}-${contactInformation?.policyNumber}`,
      accidentdate: `${year}-${month}-${day} ${hour}:${minute}`,
      description: `Количество участников: ${insuranceCaseInformation?.numberOfParticipants?.name}\n
        Оформлен: ${insuranceCaseInformation?.document?.name}\n
        Повреждено иное имущество: ${insuranceCaseInformation?.damagedProperty ? "да" : "нет"}\n
        Место происшествия: ${circumstancesData?.place}\n
        Описание: ${circumstancesData.whatHappened}\n
        Адрес осмотра: ${place?.address}
      `,
      inspectionstationid: place?.id,
    };

    setIsSubmitting(true);
    sendInsuranceCase(dataForSending)
      .then((res) => {
        if (res?.status === 200) {
          setIsSuccess(true);
        } else {
          setIsError(true);
        }
      })
      .catch(() => setIsError(true))
      .finally(() => setIsSubmitting(false));
  };

  const handleContactInformationValidChange = useCallback((valid: boolean) => setIsContactInformationValid(valid), []);

  const handleInsuranceCaseInformationValidChange = useCallback(
    (valid: boolean) => setIsInsuranceCaseInformationValid(valid),
    []
  );

  const handleSuccessModalClose = useCallback(() => {
    setIsSuccess(false);
    setPlace(null);
    setInsuranceCaseInformation(null);
    setIsContactInformationValid(false);
    setContactInformation(null);
    reset({
      place: "",
      date: "",
      time: "",
      whatHappened: "",
    });
  }, [reset]);

  useEffect(() => {
    if (isError) {
      errorTimeout.current = window.setTimeout(() => {
        setIsError(false);
      }, 3 * 1000);
    } else {
      clearTimeout(errorTimeout.current);
    }
  }, [isError]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>
      <AppearanceWrapper appearance={appearance}>
        <div className={styles.container}>
          <Title {...title} />
          <div className={styles.cardsWrapper}>
            <CollapseCard isOpened icon="/images/insurance-case/contacts.jpeg" title="Контактная информация">
              <ContactInformation
                information={contactInformation}
                onValid={handleContactInformationValidChange}
                onSubmit={setContactInformation}
              />
            </CollapseCard>
            <CollapseCard
              disabled={!contactInformation || !isContactInformationValid}
              isOpened={!!contactInformation}
              icon="/images/insurance-case/sign.jpeg"
              title="Информация о страховом случае"
            >
              <InsuranceCaseInformation
                information={insuranceCaseInformation}
                onValid={handleInsuranceCaseInformationValidChange}
                onSubmit={setInsuranceCaseInformation}
              />
            </CollapseCard>
            <CollapseCard
              isOpened={!!insuranceCaseInformation}
              disabled={!insuranceCaseInformation || !isInsuranceCaseInformationValid || !isContactInformationValid}
              icon="/images/insurance-case/doc-path.jpeg"
              title="Обстоятельства"
            >
              <Circumstances control={control} errors={errors} />
            </CollapseCard>
            <CollapseCard
              disabled={!insuranceCaseInformation || !isInsuranceCaseInformationValid || !isContactInformationValid}
              isOpened={!!insuranceCaseInformation}
              icon="/images/insurance-case/doc.jpeg"
              title="Необходимые документы"
            >
              <Documents />
            </CollapseCard>
            <CollapseCard
              disabled={!insuranceCaseInformation || !isInsuranceCaseInformationValid || !isContactInformationValid}
              isOpened={!!insuranceCaseInformation}
              icon="/images/insurance-case/place.jpeg"
              title="Место осмотра ТС"
            >
              <InspectionPlace
                submitting={isSubmitting}
                onSendButtonClick={handleSubmit(handleSend)}
                selectedExpert={place}
                onSelectExpert={setPlace}
              />
            </CollapseCard>
          </div>
        </div>
        <PopUp
          btnText="Закрыть"
          title="Мы получили ваше уведомление"
          isOpen={isSuccess}
          onClose={handleSuccessModalClose}
        >
          <div className={styles.successModal}>
            <img alt="success" src="images/insurance-case/success.jpeg" />
            <div>
              Мы получили ваше уведомление, и уже оформляем заявку на независимую экспертизу. Мы сообщим о решении по
              телефону
            </div>
          </div>
        </PopUp>
        <Toast
          isOpen={isError}
          onClose={() => setIsError(false)}
          title="Ошибка"
          value="Что-то пошло не так, попробуйте снова"
        />
      </AppearanceWrapper>
    </GoogleReCaptchaProvider>
  );
});
