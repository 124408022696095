import { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AmountInput, Button, DateInput, Dropdown, SuggestOption, Typography } from "@mafin/ui-kit";
import { FormModal } from "@/components/sections/mortgage-life/components/form-modal";
import { GENDER_SUGGESTIONS } from "@/utils/constants";
import { FormVariantType, SberOffer } from "@/components/sections/mortgage-life";
import {
  restCaseHandler,
  sberCaseHandler,
  birthdateValidator,
} from "@/components/sections/mortgage-life/components/form/helpers";
import { Bank } from "@/utils/api/types/main-site";

import styles from "./index.module.scss";

export interface FormProps {
  variant: FormVariantType;
  banks: Bank[];
  setOffer: Dispatch<SetStateAction<SberOffer | null>>;
}

export interface IForm {
  bank: string;
  gender: "Женский" | "Мужской";
  birthdate: string;
  debt: string;
  height?: string;
  weight?: string;
}

export const Form: FC<FormProps> = ({ variant, banks, setOffer }) => {
  const { control, handleSubmit, setError, setValue } = useForm<IForm>();
  const withLife = useMemo(() => ["Жизнь", "Квартира + Жизнь"].includes(variant), [variant]);

  const bankSuggestions = useMemo(
    () =>
      [...banks].sort((a, b) => b.orderWeight - a.orderWeight).map(({ idSravni, name }) => ({ id: idSravni, name })),
    [banks]
  );

  const [bank, setBank] = useState<SuggestOption>(bankSuggestions[0]);
  const [gender, setGender] = useState<SuggestOption>(GENDER_SUGGESTIONS[0]);
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const handleModalClose = () => {
    setLoading(false);
    setShowPopUp(false);
  };

  const handleChooseOption = (type: "bank" | "gender") => (option: SuggestOption | null) => {
    if (option) {
      setValue(type, option.name);
      type === "bank" ? setBank(option) : setGender(option);
    }
  };

  const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    if (value.includes("-")) {
      const [year, month, day] = value.split("-");

      value = `${day}.${month}.${year}`;
    }
    setValue("birthdate", value);
    setError("birthdate", { message: undefined });
  };

  const submit: SubmitHandler<IForm> = async (data) => {
    if (data.bank === "Сбербанк") {
      await sberCaseHandler({ variant, withLife, setShowPopUp, setOffer, data, setLoading });
    } else {
      restCaseHandler({ banks, data });
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className={styles.container} data-testid="mortgage-form">
      <Controller
        name="bank"
        control={control}
        defaultValue={banks.find((i) => i.name === "Сбербанк") ? "Сбербанк" : ""}
        rules={{ required: { value: true, message: "Выберите банк" } }}
        render={({ field: { ref, ...field } }) => (
          <Dropdown
            {...field}
            label="Ипотечный банк"
            options={bankSuggestions}
            selectedOption={bank}
            chooseOption={handleChooseOption("bank")}
            autoTestId="mortgage-health-bank"
          />
        )}
      />
      <Controller
        name="debt"
        control={control}
        rules={{
          required: { value: true, message: "Укажите остаток кредита" },
          min: { value: 100000, message: "Даже если остаток меньше 100 000 ₽, укажите 100 000 ₽" },
          max: { value: 15000000, message: "Доступно оформление для кредитов не более 15 000 000 ₽" },
        }}
        render={({ field: { onChange, ...field }, fieldState }) => (
          <AmountInput
            {...field}
            label="Остаток кредита"
            onChange={(e) => {
              setValue("debt", e.target.value.replace(/ /g, ""));
              setError("debt", { message: undefined });
            }}
            error={fieldState.error?.message}
            maxLength={10}
            autoTestId="mortgage-health-debt"
          />
        )}
      />
      <Controller
        name="gender"
        control={control}
        defaultValue="Женский"
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <Dropdown
            {...field}
            label="Пол заёмщика"
            options={GENDER_SUGGESTIONS}
            chooseOption={handleChooseOption("gender")}
            selectedOption={gender}
            autoTestId="mortgage-health-gender"
          />
        )}
      />
      <Controller
        name="birthdate"
        control={control}
        defaultValue=""
        rules={{
          required: { value: true, message: "Укажите дату рождения" },
          validate: birthdateValidator(withLife ? 50 : 65),
        }}
        render={({ field: { ref, onChange, ...field }, fieldState }) => (
          <DateInput
            {...field}
            label="Дата рождения"
            placeholder="дд.мм.гггг"
            onChange={handleChangeDate}
            error={fieldState.error?.message}
            autoTestId="mortgage-health-birthdate"
          />
        )}
      />
      {withLife && (
        <>
          <Controller
            name="height"
            control={control}
            rules={{
              required: { value: true, message: "Укажите Ваш рост" },
              min: { value: 30, message: "Минимальный рост 30 см" },
              max: { value: 300, message: "Максимальный рост 300 см" },
            }}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <AmountInput
                {...field}
                label="Рост, см"
                onChange={(e) => {
                  setValue("height", e.target.value.replace(/ /g, ""));
                  setError("height", { message: undefined });
                }}
                error={fieldState.error?.message}
                maxLength={10}
                autoTestId="mortgage-health-height"
              />
            )}
          />

          <Controller
            name="weight"
            control={control}
            rules={{
              required: { value: true, message: "Укажите Ваш вес" },
              min: { value: 30, message: "Минимальный вес 30 кг" },
              max: { value: 300, message: "Максимальный вес 300 кг" },
            }}
            render={({ field: { onChange, ...field }, fieldState }) => (
              <AmountInput
                {...field}
                label="Вес, кг"
                onChange={(e) => {
                  setValue("weight", e.target.value.replace(/ /g, ""));
                  setError("weight", { message: undefined });
                }}
                error={fieldState.error?.message}
                maxLength={10}
                autoTestId="mortgage-health-weight"
              />
            )}
          />
        </>
      )}
      <Typography variant="littleButtonText" color="var(--additional)" className={styles.insurer}>
        Полис предоставляет АО «СК «Пари»
      </Typography>
      <Button loading={loading}>Рассчитать</Button>
      <FormModal handleModalClose={handleModalClose} showPopUp={showPopUp} />
    </form>
  );
};
