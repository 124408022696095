import { SkDocuments, SkDocumentsProps } from "@/components/elements/sk-documents";

interface Data {
  data: JuristicDocumentsProps;
}

interface JuristicDocumentsProps {
  Documents: SkDocumentsProps;
}

export const JuristicDocuments = ({ data: { Documents } }: Data) => {
  return <SkDocuments data={{ ...Documents, h1Title: true }} />;
};
