import { FC } from "react";
import cn from "classnames";
import { Image, Link } from "@/components/sections/types";

import styles from "./card.module.scss";

interface IconLink {
  image: Image;
  href: string;
}

export interface CardProps {
  image: Image;
  title: string;
  link: Link;
  iconLink: IconLink[];
}

export const Card: FC<CardProps> = ({ title, image, link, iconLink }) => {
  const icons = iconLink.map(({ image, href }) => (
    <a href={href} key={href}>
      <img src={image.url} alt="icon" />
    </a>
  ));

  return (
    <div className={styles.wrapper}>
      <img src={image?.url} alt="img" className={styles.cover} />
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <a className={styles.link} href={link.url}>
          {link.text}
        </a>
        <div
          className={cn(styles.icons, {
            [styles.hidden]: !icons?.length,
          })}
        >
          {icons}
        </div>
      </div>
    </div>
  );
};
