import { FC } from "react";
import cn from "classnames";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconLink } from "@mafin/ui-kit";
import { MaterialCard, MaterialCardProps } from "@/components/elements/material-card";
import { MaterialCardMini, MaterialCardMiniProps } from "@/components/elements/material-card-mini";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { Link } from "@/components/sections/types";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

interface Data {
  big_card: MaterialCardProps;
  small_cards: MaterialCardMiniProps[];
  title: string;
  link: Link;
}

export interface MaterialCardsProps {
  data: Data;
}

export const MaterialCards: FC<MaterialCardsProps> = ({ data: { big_card, small_cards, title, link } }) => {
  const slides = [big_card, ...small_cards].map((card) => (
    <SwiperSlide key={`material_card_${card.cardTitle}`} className={styles.slide}>
      <MaterialCardMini {...card} />
    </SwiperSlide>
  ));

  return (
    <div className={styles.wrapper__container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <IconLink
            variant="secondary"
            className={cn(styles.icon_link, styles.desktopLink)}
            color="var(--color-white)"
            {...strapiLinkComponentToAnchorProps(link)}
          >
            {link.text}
          </IconLink>
        </div>
        <div className={cn(styles.container, styles.desktop)}>
          <MaterialCard {...big_card} />
          <div className={styles.mini}>
            {small_cards.map((card) => (
              <MaterialCardMini {...card} key={card.cardTitle} />
            ))}
          </div>
        </div>
        <Swiper
          spaceBetween={8}
          slidesPerView="auto"
          pagination={{
            clickable: true,
          }}
          className={styles.swiper}
        >
          {slides}
        </Swiper>
        <IconLink
          variant="secondary"
          className={cn(styles.icon_link, styles.mobileLink)}
          color="var(--color-white)"
          {...strapiLinkComponentToAnchorProps(link)}
        >
          {link.text}
        </IconLink>
      </div>
    </div>
  );
};
