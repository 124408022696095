import { FC, useState, useEffect } from "react";
import { Typography } from "@mafin/ui-kit";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Form } from "@/components/sections/mortgage-life/components/form";
import { VariantChip } from "@/components/sections/mortgage-life/components/variant-chip";
import { Offer } from "@/components/sections/mortgage-life/components/offer";
import { SberOfferIdRequest } from "@/utils/api/types/mortgage-life";
import { getStrapiMedia } from "@/utils/media";
import { Bank } from "@/utils/api/types/main-site";
import { Appearance, Image as IImage, StrapiData, Title } from "@/components/sections/types";

import SberIcon from "./img/sber.svg";
import styles from "./index.module.scss";

export interface Data {
  title: Title;
  text: string;
  image: IImage;
  banks: Bank[];
  appearance: Appearance;
}

export type SberOffer = SberOfferIdRequest["calculation"]["payload"] & {
  id: string;
  policyFullCost: number;
  policyCost: number;
};

const FORM_VARIANTS = ["Квартира", "Жизнь", "Квартира + Жизнь"] as const;

export type FormVariantType = typeof FORM_VARIANTS[number];

export const MortgageLife: FC<StrapiData<Data>> = ({ data: { title, text, image, banks, appearance } }) => {
  const [variant, setVariant] = useState<typeof FORM_VARIANTS[number]>("Квартира");
  const [offer, setOffer] = useState<SberOffer | null>(null);

  useEffect(() => {
    setOffer(null);
  }, [variant]);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.textBlock}>
            <Typography variant={title.type} className={styles.title}>
              {title.text}
              <SberIcon className={styles.iconSber} />
            </Typography>
            <Typography variant="simpleText">{text}</Typography>
          </div>
          <img src={getStrapiMedia(image.url)} alt={image.alternativeText} className={styles.image} />
        </div>
        <div className={styles.formWrapper}>
          <Typography variant="buttonText">Что нужно застраховать?</Typography>
          <div className={styles.chips}>
            {FORM_VARIANTS.map((i) => (
              <VariantChip key={i} onClick={() => setVariant(i)} active={variant === i} title={i} />
            ))}
          </div>
          <Form variant={variant} banks={banks} setOffer={setOffer} />
          {!!offer && <Offer offer={offer} variant={variant} />}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
