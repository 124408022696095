import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData, VectorImage } from "@/components/sections/types";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import {
  PartnerProgramCard,
  PartnerProgramCardProps,
} from "@/components/sections/transfer-program/components/partner-program-card";
import { Container } from "@mafin/ui-kit";

import styles from "./index.module.scss";

export interface TransferProgramData {
  title: string;
  image: VectorImage;
  partners: PartnerProgramCardProps[];
  appearance: Appearance;
}

export const TransferProgram: FC<StrapiData<TransferProgramData>> = ({
  data: { title, image, partners, appearance },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <div className={styles.title}>
          <HTMLFromRichTextEditor className={styles.text} htmlString={title} />
          <img src={image.url} alt="Transfer Program" className={styles.image} />
        </div>
        <div className={styles.cards}>
          {partners.map((i) => (
            <PartnerProgramCard {...i} key={i.partner.id} />
          ))}
        </div>
      </Container>
    </AppearanceWrapper>
  );
};
