import { Expert } from "@/utils/api/types/main-site";

const getSelectedIcon = (marker: Expert) => {
  switch (marker.expertise_type) {
    case "1":
      return "images/insurance-case/we-place-marker-checked.png";
    case "2":
      return "images/insurance-case/expert-place-marker-checked.png";
    case "3":
      return "images/insurance-case/yourself-place-marker-checked.png";
    default:
      return "images/insurance-case/we-place-marker-checked.png";
  }
};

const getIcon = (marker: Expert) => {
  switch (marker.expertise_type) {
    case "1":
      return "images/insurance-case/we-place-marker.png";
    case "2":
      return "images/insurance-case/expert-place-marker.png";
    case "3":
      return "images/insurance-case/yourself-place-marker.png";
    default:
      return "images/insurance-case/we-place-marker.png";
  }
};

export const getMarkerIcon = (marker: Expert, isSelected: boolean) => {
  if (isSelected) {
    return getSelectedIcon(marker);
  }

  return getIcon(marker);
};
