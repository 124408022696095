import React from "react";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { StrapiData } from "../types";
import { RichDropdown } from "../../elements/rich-dropdown";

import styles from "./index.module.scss";

interface IRichDropdown {
  title: string;
  main_content: string;
  warning?: string;
  additional?: "europrotocol";
}

interface Data {
  title: string;
  rich_dropdown: IRichDropdown[];
}

export const RichFaq = ({ data }: StrapiData<Data>) => {
  const content = data.rich_dropdown?.map((item, index) => {
    return (
      <RichDropdown
        key={`faq_dropdown_${index}`}
        title={item.title}
        additional={item.additional}
        warning={item.warning}
      >
        <HTMLFromRichTextEditor htmlString={item.main_content} />
      </RichDropdown>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.faq}>
        <h2 className={styles.title}>{data.title}</h2>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};
