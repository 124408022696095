import { FC } from "react";
import { Button, Typography } from "@mafin/ui-kit";
import { Divider } from "@/components/shared/divider";
import { FormVariantType, SberOffer } from "@/components/sections/mortgage-life";
import {
  SBER_OFFER,
  STRAHOVANIE_IPOTEKI_CALC_FLAT,
  STRAHOVANIE_IPOTEKI_CALC_FULL,
  STRAHOVANIE_IPOTEKI_CALC_LIFE,
} from "@/utils/constants";
import { getNumberWithSpaces } from "@/utils/get-number-with-spaces";

import styles from "@/components/sections/mortgage-life/components/offer/index.module.scss";

export interface OfferProps {
  offer: SberOffer;
  variant: FormVariantType;
}

const REDIRECT_MAP: Record<FormVariantType, string> = {
  ["Квартира"]: STRAHOVANIE_IPOTEKI_CALC_FLAT,
  ["Квартира + Жизнь"]: STRAHOVANIE_IPOTEKI_CALC_FULL,
  ["Жизнь"]: STRAHOVANIE_IPOTEKI_CALC_LIFE,
};

export const Offer: FC<OfferProps> = ({ offer, variant }) => {
  const handleButtonClick = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem(SBER_OFFER, JSON.stringify(offer));

      window.open(REDIRECT_MAP[variant], "_self");
    }
  };

  return (
    <>
      <Divider className={styles.divider} />
      <div className={styles.offerWrapper}>
        <Typography variant="h1" className={styles.cost} color="var(--corporate)">
          {getNumberWithSpaces(Math.floor(offer.policyCost))}
        </Typography>
        <Typography variant="simpleText" className={styles.currency} color="var(--corporate)">
          руб.
        </Typography>
        <Button variant="outline" className={styles.button} onClick={handleButtonClick}>
          Оформить полис
        </Button>
      </div>
    </>
  );
};
