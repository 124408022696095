import { memo } from "react";
import { Typography } from "@/components/shared/typography";
import cn from "classnames";

import Check from "./img/check.svg";
import styles from "./index.module.scss";

export interface RowProps {
  subject: string;
  left: string;
  right: string;
}

export const Row = memo<RowProps>(({ subject, left, right }) => {
  return (
    <div data-testid="productComparisonRow" className={styles.container}>
      <div className={cn(styles.card, styles.left)}>
        <Typography className={cn(styles.title, styles.left)} type="h3" useParagraph>
          {left}
        </Typography>
      </div>
      <div className={styles.subject}>
        <div className={styles.line} />
        <div className={styles.circle}>
          <Typography type="h5" useParagraph>
            {subject}
          </Typography>
        </div>
        <div className={cn(styles.line, styles.right)} />
      </div>
      <div className={cn(styles.card, styles.right)}>
        <div className={styles.iconWrapper}>
          <Check />
        </div>
        <div className={styles.titleWrapper}>
          <Typography className={cn(styles.title, styles.right)} type="h3" useParagraph>
            {right}
          </Typography>
        </div>
      </div>
    </div>
  );
});
