import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";

import { Appearance, StrapiData, Title } from "../types";

import { Card, CardProps } from "./components/card";
import styles from "./product-contact-us.module.scss";

export interface ProductContactUsProps {
  title: Title;
  cards: CardProps[];
  appearance: Appearance;
}

export const ProductContactUs: FC<StrapiData<ProductContactUsProps>> = ({ data }) => {
  const { cards, title, appearance } = data;

  const renderCards = () => {
    return cards.map((card) => <Card key={card.title} {...card} />);
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <h1 className={styles.title}>{title?.text}</h1>
        <div className={styles.cards}>{renderCards()}</div>
      </div>
    </AppearanceWrapper>
  );
};
