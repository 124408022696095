import { memo } from "react";
import { Image, Link as ILink } from "@/components/sections/types";
import { Link } from "@mafin/ui-kit";
import { getStrapiMedia } from "@/utils/media";

import styles from "./index.module.scss";

export interface SeeAlsoCardSmallProps {
  image: Image;
  link: ILink & { hidden?: boolean; newTab?: boolean };
  title: string;
}

export const SeeAlsoCardSmall = memo<SeeAlsoCardSmallProps>(({ link, title, image }) => {
  const altText = image?.alternativeText || "изображение";

  return (
    <div className={styles.wrapper}>
      <img className={styles.image} alt={altText} src={getStrapiMedia(image?.url)} />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        {!link.hidden && (
          <Link className={styles.link} target={link?.newTab ? "_blank" : "_self"} href={link?.url}>
            {link?.text}
          </Link>
        )}
      </div>
    </div>
  );
});
