import { FC } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData, Link as ILink } from "@/components/sections/types";
import { Border } from "@/components/shared/border";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { ButtonLink } from "@/components/elements/button-link";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface PolicyGenuineMobileProps {
  appearance: Appearance;
}

export const PolicyGenuineMobile: FC<StrapiData<PolicyGenuineMobileProps>> = ({ data: { appearance } }) => {
  const items: Array<{ icon: string; htmlString: string; button: ILink }> = [
    {
      icon: "/icons/burningCar.svg",
      htmlString:
        "<h4>Ущерб</h4><p>Вам организуют и оплатят ремонт авто в случае ДТП, стихийного бедствия, пожара, вандализма и нанесения механических повреждений, в том числе животными</p>",
      button: { url: "/kasko/protect/car-damage", text: "Подробнее" },
    },
    {
      icon: "/icons/overturnedСar.svg",
      htmlString:
        "<h4>Гибель</h4><p>Вы получите страховую выплату, если автомобиль не будет подлежать восстановлению или его ремонт окажется нецелесообразен</p>",
      button: { url: "/kasko/protect/car-total", text: "Подробнее" },
    },
    {
      icon: "/icons/stolenСar.svg",
      htmlString:
        "<h4>Угон</h4><p>Полис обеспечит страховую выплату, если авто будет утрачено в результате угона, кражи, грабежа или разбоя</p>",
      button: { url: "/kasko/protect/car-theft", text: "Подробнее" },
    },
  ];

  const renderItems = () =>
    items.map((i, index) => (
      <div className={style.item} key={i.icon}>
        <img src={i.icon} alt={`PolicyGenuineMobile${index}`} className={style.icon} />
        <div>
          <HTMLFromRichTextEditor htmlString={i.htmlString} className={style.text} />
          <Link href={i.button.url} passHref>
            <ButtonLink variant="secondary" className={style.button}>
              {i.button.text}
            </ButtonLink>
          </Link>
        </div>
      </div>
    ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Border>
          <Typography variant="h2" component="h2">
            К основным покрываемым рискам относят:
          </Typography>
          <div className={style.items}>{renderItems()}</div>
        </Border>
      </Container>
    </AppearanceWrapper>
  );
};
