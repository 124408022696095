import { Title } from "@/components/shared/title";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Step } from "@/components/elements/step";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";

import styles from "./index.module.scss";

export interface Data {
  title?: ITitle;
  step: Array<{
    title: string;
    content: string;
    id: number;
  }>;
  appearance: Appearance;
}

export const Steps = ({ data }: StrapiData<Data>) => {
  const { title, step, appearance } = data;
  const content = step.map((item, index) => (
    <Step key={`step_${index}`} step={index + 1} title={item.title} content={item.content} />
  ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.steps}>
        {title?.text && <Title {...title} className={styles.title} />}
        <div className={styles.content}>
          <div className={styles.line} />
          {content}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
