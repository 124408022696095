import { memo } from "react";
import { YMaps, Map as MapView, Placemark, Clusterer } from "react-yandex-maps";
import { useExperts } from "@/utils/hooks/use-experts";
import { Expert } from "@/utils/api/types/main-site";
import { getMarkerIcon } from "@/components/sections/insurance-case/inspection-place/utils";
import { Information } from "@/components/shared/information";
import { Button, Link } from "@mafin/ui-kit";
import cn from "classnames";

import styles from "./index.module.scss";

interface InspectionPlaceProps {
  submitting: boolean;
  selectedExpert?: Expert | null;

  onSelectExpert(data: Expert | null): void;

  onSendButtonClick(): void;
}

export const InspectionPlace = memo<InspectionPlaceProps>(function InspectionPlace({
  selectedExpert,
  onSendButtonClick,
  onSelectExpert,
  submitting,
}) {
  const experts = useExperts();

  const renderPlacemarks = () =>
    experts.map((marker) => {
      const isSelected = selectedExpert?.id === marker.id;
      const icon = getMarkerIcon(marker, isSelected);

      return (
        <Placemark
          onClick={() => onSelectExpert(marker)}
          key={marker.id}
          geometry={[marker.latitude, marker.longitude]}
          options={{
            iconLayout: "default#image",
            iconImageHref: icon,
            iconImageSize: isSelected ? [50, 51] : [38, 46],
          }}
        />
      );
    });

  const handleCancelButtonClick = () => onSelectExpert(null);

  return (
    <div>
      <div className={styles.symbols}>
        <div className={styles.symbol}>
          <img alt="we" src="/images/insurance-case/we-place.jpeg" />
          <div className={styles.hyphen}>–</div>
          <div className={styles.text}>Мы выдаем направление</div>
        </div>
        <div className={styles.symbol}>
          <img alt="we" src="/images/insurance-case/expert-place.jpeg" />
          <div className={styles.hyphen}>–</div>
          <div className={styles.text}>Выезжает эксперт</div>
        </div>
        <div className={styles.symbol}>
          <img alt="we" src="/images/insurance-case/yourself-place.jpeg" />
          <div className={styles.hyphen}>–</div>
          <div className={styles.text}>Самостоятельное обращение</div>
        </div>
      </div>

      <YMaps>
        <MapView
          defaultState={{
            center: [58.01045, 56.229434],
            zoom: 3,
            behaviors: ["default", "scrollZoom"],
          }}
          className={styles.map}
        >
          <Clusterer
            options={{
              preset: "islands#invertedVioletClusterIcons",
              groupByCoordinates: false,
              clusterDisableClickZoom: false,
              clusterIconColor: "#FF0550",
            }}
          >
            {renderPlacemarks()}
          </Clusterer>
        </MapView>
      </YMaps>

      {!selectedExpert && (
        <Information>
          Если в вашем регионе отсутствует точка осмотра, рекомендуем направить запрос в Страховую компанию, используя{" "}
          <span className={cn(styles.corporateLink, styles.simple)}>
            <Link
              target="_blank"
              href="https://www.absolutins.ru/strahovoj-sluchaj/fizicheskie-lica/obshchaya-informatsiya/"
            >
              форму Обратной связи
            </Link>
          </span>{" "}
          на сайте компании Абсолют Страхование в разделе Страховой случай
        </Information>
      )}

      {selectedExpert && (
        <div className={styles.selectedPlaceWrapper}>
          <div className={styles.description}>
            <div>{selectedExpert.label}</div>
            <div className={styles.address}>{selectedExpert.address}</div>
            <div>{selectedExpert.description}</div>
          </div>
          <Button onClick={handleCancelButtonClick} variant="secondary" className={styles.cancelButton}>
            Отменить выбор
          </Button>
        </div>
      )}

      <div className={styles.sendWrapper}>
        <p>
          Нажимая кнопку «Отправить», я принимаю{" "}
          <span className={styles.corporateLink}>
            <Link target="_blank" href="https://mafin.ru/legal/agreement">
              условия пользовательского соглашения
            </Link>
          </span>
          , даю{" "}
          <span className={styles.corporateLink}>
            <Link target="_blank" className={styles.corporateLink} href="/">
              согласие на обработку персональных данных
            </Link>{" "}
          </span>
          согласно{" "}
          <span className={styles.corporateLink}>
            <Link target="_blank" href="https://mafin.ru/legal/privacy">
              Политике конфиденциальности
            </Link>
          </span>
          .
        </p>
        <Button loading={submitting} disabled={!selectedExpert || submitting} onClick={onSendButtonClick}>
          Отправить
        </Button>
      </div>
    </div>
  );
});
