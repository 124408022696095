import { FC, useState } from "react";
import { Typography } from "@/components/shared/typography";
import { SliderBlock } from "@/components/sections/sovcom-leadgeneration/shared/slider-block";
import { MIN_CAR_PRICE } from "@/components/sections/sovcom-leadgeneration/shared/utils/constants";
import { getPriceOfKasko } from "@/components/sections/sovcom-leadgeneration/shared/utils/helpers";
import { ButtonLink } from "@/components/elements/button-link";

import styles from "./index.module.scss";

export const VariantB: FC = () => {
  const [value, setValue] = useState(MIN_CAR_PRICE);

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.content}>
            <Typography className={styles.title} type="h2" useParagraph>
              Оформить полис просто
            </Typography>
            <Typography type="simpleText">Для покупки нужен лишь паспорт&nbsp;и СТС/ПТС</Typography>
          </div>
          <picture>
            <source media="(max-width: 979px)" srcSet="/images/sovcom/first-mobile.png" />
            <img alt="card" src="/images/sovcom/first.png" />
          </picture>
        </div>
        <div className={styles.card}>
          <div className={styles.content}>
            <Typography type="h2" useParagraph>
              Все онлайн
            </Typography>
            <Typography className={styles.description} type="simpleText">
              Полностью удаленное оформление, включая предстраховой осмотр для&nbsp;авто с пробегом
            </Typography>
          </div>
          <picture>
            <source media="(max-width: 979px)" srcSet="/images/sovcom/second-mobile.png" />
            <img alt="card" src="/images/sovcom/second.png" />
          </picture>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <Typography type="semiboldSimpleText">Цена полиса:</Typography>
        <div className={styles.price}>
          <Typography type="h1" className={styles.policePrice} useParagraph>
            {getPriceOfKasko(value).toLocaleString("ru")}
          </Typography>
          <Typography type="simpleText">руб</Typography>
        </div>
        <SliderBlock className={styles.slider} value={value} onChange={setValue} />
        <ButtonLink href="/kasko/calc?promo=SOVKOMBANK">Оформить</ButtonLink>
      </div>
    </div>
  );
};
