const ranges: Record<number, number> = {
  700000: 21600,
  800000: 27600,
  1200000: 33600,
  1600000: 30000,
  2000000: 38400,
  3000000: 48300,
  4000000: 62400,
};

export const getPriceOfKasko = (carPrice: number) => {
  const keys: number[] = Object.keys(ranges)
    .map((i) => Number(i))
    .sort((a, b) => a - b);

  for (let i = 0; i < keys.length; i++) {
    if (carPrice <= keys[i]) {
      return ranges[keys[i]];
    }
  }

  return 0;
};
