import React, { FC, useCallback, useState } from "react";
import Link from "next/link";
import { Chip, IconLink } from "@mafin/ui-kit";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title as ITitle, StrapiData, Appearance, Link as ILink } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import cn from "classnames";

import { useRouter } from "next/router";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  link?: ILink;
  appearance: Appearance;
  newTab: boolean;
  relNofollow?: boolean;
  maxItemsCount: number;
  chipsArray: IBrandsModelsChip[];
}

export interface IBrandsModelsChip {
  id: number;
  name: string;
  slug: string;
  orderWeight: number;
  popular: boolean;
}

export const BrandsModelsChips: FC<StrapiData<Data>> = ({
  data: { title, appearance, link, newTab, relNofollow, maxItemsCount, chipsArray },
}) => {
  const router = useRouter();
  const slugArray = router.asPath.split("/");
  const currentChip = chipsArray.filter((chip) => chip.slug === slugArray[3])[0];
  const [selectedChip, setSelectedChip] = useState<number>(currentChip?.id);
  const chips = chipsArray
    .filter((chip) => chip.popular)
    .sort((a, b) => a.name.localeCompare(b.name, "en"))
    .sort((a, b) => b.orderWeight - a.orderWeight)
    .slice(0, maxItemsCount);

  const handleChipClick = useCallback((id: number) => () => setSelectedChip(id), []);

  const handleIconLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!link!.newTab) {
      e.preventDefault();
      router.push(link!.url);
    }
  };

  return chips.length ? (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <Title className={styles.title} {...title} />
        {link && (
          <IconLink
            variant="secondary"
            className={styles.link}
            {...strapiLinkComponentToAnchorProps(link)}
            onClick={handleIconLinkClick}
          >
            {link.text}
          </IconLink>
        )}
        <div className={styles.chipsContainer} data-testid="chipsContainer">
          {chips.map((i) =>
            i.id === selectedChip ? (
              <Chip key={i.id} label={i.name} className={cn(styles.chip, styles.active)} selected />
            ) : (
              <Link key={i.id} href={`${slugArray.slice(0, 3).join("/")}/${i.slug}`} scroll={false} passHref>
                <a {...strapiLinkComponentToAnchorProps({ text: "", url: "", newTab, relNofollow })}>
                  <Chip label={i.name} className={styles.chip} onClick={handleChipClick(i.id)} />
                </a>
              </Link>
            )
          )}
        </div>
      </div>
    </AppearanceWrapper>
  ) : null;
};
