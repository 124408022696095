import React, { useState } from "react";
import Link from "next/link";
import cn from "classnames";
import { Title } from "@/components/shared/title";
import { Appearance, StrapiData, LinkSet, LinkForSet } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Arrow } from "@mafin/icons";

import styles from "./index.module.scss";

export interface CrossLinksProps {
  expand: boolean;
  appearance: Appearance;
  linkSet?: LinkSet;
}

export const CrossLinks: React.FC<StrapiData<CrossLinksProps>> = ({ data: { expand, linkSet, appearance } }) => {
  const [showLinks, setShowLinks] = useState(expand);

  const handleArrowClick = () => setShowLinks(!showLinks);

  const renderLink = (link: LinkForSet, className: string) => {
    const renderText = (text: string) => <span className={className}>{text}</span>;

    if (link.page) {
      const url = `/${link.page.slug.replace(/__/g, "/")}`;

      return (
        <Link href={url}>
          <a href={url}>{renderText(link.page.pageName)}</a>
        </Link>
      );
    }

    if (link.text) {
      return link.url ? <a href={link.url}>{renderText(link.text)}</a> : renderText(link.text);
    }
  };

  return linkSet ? (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.header} onClick={handleArrowClick}>
          {linkSet?.title && <Title {...linkSet.title} className={styles.title} />}
          <Arrow direction={showLinks ? "up" : "down"} />
        </div>
        <div className={cn(styles.content, showLinks && styles.show)}>
          {linkSet?.linksBlocks.map((linksBlock) => (
            <div key={linksBlock.id} className={styles.linksBlock}>
              {(linksBlock.titleLink?.page || linksBlock.titleLink?.text) && (
                <div className={styles.titleLinkWrapper}>
                  <div className={styles.dot} />
                  {renderLink(linksBlock.titleLink, styles.titleLink)}
                </div>
              )}
              {linksBlock.links && (
                <div className={styles.links}>
                  {linksBlock.links
                    .filter((link) => link.page || (link.text && link.url))
                    .map((link) => (
                      <div key={link.id} className={styles.linkWrapper}>
                        <span className={styles.dash}>—</span>
                        {renderLink(link, styles.link)}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  ) : (
    <></>
  );
};
