import { STRAHOVKARU_MORTGAGE_PARI, STRAHOVKARU_MORTGAGE_PARI_CALCULATE } from "@/utils/constants/paths";
import { HEADERS } from "@mafin/utilities";
import {
  SberOfferIdResponse,
  SberOfferIdRequest,
  SberOfferResponse,
  SberOfferError,
} from "@/utils/api/types/mortgage-life";
import { getRoRApiUrl } from "@/utils/api/requests/main-site";
import { PARI_X_SECRET } from "@/utils/constants";

export const getSberOfferId = async (body: SberOfferIdRequest): Promise<SberOfferIdResponse | undefined> => {
  try {
    const options: RequestInit = {
      method: "POST",
      cache: "no-cache",
      headers: {
        ...HEADERS,
        "X-Secret": PARI_X_SECRET,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(`${getRoRApiUrl()}${STRAHOVKARU_MORTGAGE_PARI_CALCULATE}`, options);

    const json = await response.json();

    return json.data || json;
  } catch (e) {
    console.error("load Sber offer id error", e);
  }
};

export const getSberOffer = async (id: string): Promise<SberOfferResponse | SberOfferError | undefined> => {
  try {
    const options: RequestInit = {
      method: "GET",
      cache: "no-cache",
      headers: {
        ...HEADERS,
        "X-Secret": PARI_X_SECRET,
      },
    };

    const response = await fetch(`${getRoRApiUrl()}${STRAHOVKARU_MORTGAGE_PARI}?id=${id}`, options);

    const json = await response.json();

    return json.data || json;
  } catch (e) {
    console.error("load Sber offer error", e);
  }
};
