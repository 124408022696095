import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData } from "@/components/sections/types";
import { PartnerCard, useBankPartnerCards } from "@/utils/hooks/use-bank-partner-cards";

import styles from "./index.module.scss";

export interface Data {
  linkType: "default" | "noLinks" | "custom";
  linkPrefix: string;
  appearance: Appearance;
}

SwiperCore.use([Autoplay]);

const SWIPER_SPEED = 6000;
const SLIDES_TO_SHOW = 6;

export const BankPartners = ({ data: { appearance, ...args } }: StrapiData<Data>) => {
  const partnerCards = useBankPartnerCards(args);

  const renderCard = (card: PartnerCard, key?: string) =>
    typeof card.href === "string" ? (
      <Link key={key} href={card.href}>
        <a href={card.href} className={styles.slide}>
          <img src={card.icon.url} alt={card.name} className={styles.slideImg} />
        </a>
      </Link>
    ) : (
      <div className={styles.slide}>
        <img key={key} src={card.icon.url} alt={card.name} className={styles.slideImg} />
      </div>
    );

  const enoughForAutoplay = partnerCards.length >= SLIDES_TO_SHOW;

  const swiperParams = {
    loop: enoughForAutoplay,
    centerInsufficientSlides: !enoughForAutoplay,
    centeredSlides: enoughForAutoplay,
    allowTouchMove: false,
    autoplay: enoughForAutoplay && {
      delay: 0,
      disableOnInteraction: false,
    },
    updateOnWindowResize: true,
    breakpoints: {
      0: {
        speed: SWIPER_SPEED / 2,
      },
      980: {
        speed: SWIPER_SPEED,
      },
    },
    loopedSlides: partnerCards.length,
  };

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.swiper}>
          <Swiper
            {...swiperParams}
            slidesPerView={enoughForAutoplay ? "auto" : SLIDES_TO_SHOW}
            className={styles.swiperContainer}
          >
            {partnerCards.map((card, index) => (
              <SwiperSlide key={card.name + index} className={styles.swiperSlide}>
                {renderCard(card)}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
