import React from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { ButtonLink } from "@/components/elements/button-link";
import { Title } from "@/components/shared/title";
import { Typography } from "@/components/shared/typography";
import { Appearance, StrapiData, Title as ITitle, Link as ILink } from "@/components/sections/types";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  text: string;
  redBlockTitle: string;
  redBlockText: string;
  button: ILink;
  appearance: Appearance;
}

export const CallToAction: React.FC<StrapiData<Data>> = ({
  data: { title, text, redBlockTitle, redBlockText, button, appearance },
}) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.mainBlock}>
          <Title {...title} className={styles.mainBlockTitle} />
          <Typography type="simpleText" className={styles.mainBlockText}>
            {text}
          </Typography>
        </div>
        <div className={styles.redBlock}>
          <Title text={redBlockTitle} type="h2" className={styles.redBlockTitle} />
          <div className={styles.redBlockTextContainer}>
            <Title text={redBlockText} type="h3" className={styles.redBlockText} />
            <div className={styles.logos} />
          </div>
          <Link href={button.url} passHref>
            <ButtonLink {...strapiLinkComponentToAnchorProps(button)} variant="secondary" className={styles.button}>
              {button.text}
            </ButtonLink>
          </Link>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
