import React, { FC } from "react";
import { ButtonLink } from "@/components/elements/button-link";
import { Appearance, Image, Link, StrapiData, Title as ITitle } from "@/components/sections/types";
import { getStrapiMedia } from "@/utils/media";
import { Title } from "@/components/shared/title";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

export interface Data {
  appearance: Appearance;
  title: ITitle;
  description: string;
  link: Link;
  image: Image;
}

export const CalculateTheCost: FC<StrapiData<Data>> = ({ data: { appearance, description, image, title, link } }) => {
  const alternativeText = image?.alternativeText;

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <Title className={styles.title} {...title} />
        <img className={styles.image} src={getStrapiMedia(image?.url)} alt={alternativeText} />
        <div className={styles.descriptionWrapper}>
          <div className={styles.textPart}>
            <HTMLFromRichTextEditor className={styles.markdown} htmlString={description} />
            <ButtonLink {...strapiLinkComponentToAnchorProps(link)}>{link?.text}</ButtonLink>
          </div>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
