import { FC, MouseEvent, useState } from "react";
import Image from "next/image";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, Image as IImage, Link as ILink, StrapiData, Title as ITitle } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { ButtonLink } from "@/components/elements/button-link";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { getNextImageSize, MAX_MOBILE_WIDTH } from "@/utils/next-image-helpers";
import { getStrapiMedia } from "@/utils/media";
import { InputNumberNew } from "@mafin/ui-kit";
import { getGrzWithSpaces, isValidGrz, setGrzToLocalStorage } from "@/utils/product-calculators";
import { Typography } from "@/components/shared/typography";
import { useWindowSize } from "@/utils/hooks/use-window-size";

import styles from "./index.module.scss";

export interface Data {
  title: ITitle;
  text: string;
  textBeforeNumber: string;
  textAfterNumber: string;
  button: ILink;
  desktop: IImage;
  mobile: IImage;
  appearance: Appearance;
}

export const LeadGenerationMotor: FC<StrapiData<Data>> = ({
  data: { title, text, textBeforeNumber, textAfterNumber, button, desktop, mobile, appearance },
}) => {
  const size = useWindowSize();
  const getImageSrc = () => getStrapiMedia(Number(size.width) <= MAX_MOBILE_WIDTH ? mobile.url : desktop.url);
  const [grz, setGrz] = useState("");

  const handleButtonClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const grzWithSpaces = getGrzWithSpaces(grz);

    if (isValidGrz(grzWithSpaces)) {
      setGrzToLocalStorage(grzWithSpaces);
    }

    window.open(
      `${window.location.protocol}//${window.location.host}${button.url || "/404"}`,
      button.newTab ? "_blank" : "_self"
    );
  };

  const handleBlur = () => null;

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <div className={styles.textBlock}>
          <Title {...title} className={styles.title} />
          <Typography type="h4">{text}</Typography>
        </div>
        <div className={styles.image}>
          <Image
            src={getImageSrc()}
            alt={desktop.alternativeText}
            objectFit="cover"
            sizes={getNextImageSize(240)}
            layout="fill"
            quality={70}
            priority
          />
        </div>
        <div className={styles.inputBlock}>
          <Typography type="h4">{textBeforeNumber}</Typography>
          <InputNumberNew onChange={setGrz} value={grz} className={styles.input} onBlur={handleBlur} />
          <Typography type="h4">{textAfterNumber}</Typography>
          <ButtonLink
            {...strapiLinkComponentToAnchorProps(button)}
            onClick={handleButtonClick}
            className={styles.button}
          >
            {button.text}
          </ButtonLink>
        </div>
      </div>
    </AppearanceWrapper>
  );
};
