import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import { StrapiData, Link } from "../types";

import styles from "./index.module.scss";

export interface Data {
  title: string;
  subtitle: string;
  link: Link[];
}

export const PartnersDocsLicenses = ({ data }: StrapiData<Data>) => {
  const links = data.link.map((link, index) => {
    return (
      <a key={`partners-licenses-${index}`} {...strapiLinkComponentToAnchorProps(link)} className={styles.link}>
        {link.text}
      </a>
    );
  });

  return (
    <div className={styles.partners__docs__licenses}>
      <h2 className={styles.title}>{data.title}</h2>
      <p className={styles.subtitle}>{data.subtitle}</p>
      <div className={styles.links}>{links}</div>
    </div>
  );
};
