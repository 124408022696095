import React, { FunctionComponent } from "react";
import { passAttributes } from "@/utils/parse-attributes";

export const Realty: FunctionComponent = () => {
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const realty = document.createElement("script");
    const container = scriptContainerRef.current;

    passAttributes(
      realty,
      [
        ["src", "https://sravni.ru/widgets/loader.js"],
        ["data-product", "strahovanie-nedvizhimosti"],
        ["data-inFrame", "true"],
        ["data-filters.moving-Property-Sum", "150000"],
        ["data-filters.trim-Sum", "150000"],
        ["data-filters.civil-Responsibility-Sum", "150000"],
        ["data-theme-palette", '{"elementsColor","35, 35, 199","activeElementsColor","35, 35, 199"}'],
        ["data-aff_id", "2123"],
        ["data-offer_id", "1070"],
        ["data-aff_sub", "14"],
        ["data-source", "2855"],
      ],
      container
    );
  }, []);

  return <div ref={scriptContainerRef} />;
};
