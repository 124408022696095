import { useCallback } from "react";
import Link from "next/link";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { CompanyCard } from "@mafin/ui-kit";
import { Arrow } from "@mafin/icons";
import { StrapiData } from "@/components/sections/types";
import { PartnerCard, usePartnerCards } from "@/utils/hooks/use-partner-cards";

import styles from "./index.module.scss";

export interface Data {
  title: string;
  linkType: "default" | "noLinks" | "custom";
  linkPrefix: string;
}

SwiperCore.use([Pagination, Navigation]);

const DESKTOP_SLIDES_TO_SHOW = 6;
const MOBILE_SLIDES_TO_SHOW = 3;

export const Partners = ({ data: { title, linkType, linkPrefix } }: StrapiData<Data>) => {
  const partnerCards = usePartnerCards({ linkType, linkPrefix });

  const sliceIntoChunks = useCallback((arr: PartnerCard[], chunkSize: number): PartnerCard[][] => {
    const res = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);

      res.push(chunk);
    }

    return res;
  }, []);

  const renderCard = (card: PartnerCard, key?: number) =>
    typeof card.href === "string" ? (
      <Link key={key} href={card.href}>
        <a>
          <CompanyCard icon={card.icon.url} className={cn(styles.card, styles.link)} />
        </a>
      </Link>
    ) : (
      <CompanyCard key={key} icon={card.icon.url} className={styles.card} />
    );

  const renderCards = () =>
    partnerCards.map((card, index) => (
      <div key={`card${index}`}>
        <SwiperSlide key={card.id}>{renderCard(card)}</SwiperSlide>
      </div>
    ));

  const renderMobileCards = useCallback(
    () =>
      sliceIntoChunks(partnerCards, 2).map((slides, index) => (
        <SwiperSlide key={`mobileCard${index}`}>
          <div className={styles.col}>{slides.map((card) => renderCard(card, card.id))}</div>
        </SwiperSlide>
      )),
    [partnerCards, sliceIntoChunks]
  );

  const mobileCards = renderMobileCards();

  const needDesktopPagination = partnerCards.length > DESKTOP_SLIDES_TO_SHOW;
  const needMobilePagination = mobileCards.length > MOBILE_SLIDES_TO_SHOW;

  return (
    <div className={styles.partners}>
      <div className={styles.wrapper}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.slider}>
          <Swiper
            navigation={{
              nextEl: `.${styles.arrowNext}`,
              prevEl: `.${styles.arrowPrev}`,
            }}
            className={cn(styles.slider, styles.sliderDesktop)}
            slidesPerView={needDesktopPagination ? DESKTOP_SLIDES_TO_SHOW : partnerCards.length}
            loop={needDesktopPagination}
            updateOnWindowResize
            centerInsufficientSlides
            breakpoints={{
              0: {
                spaceBetween: 16,
              },
              1440: {
                spaceBetween: 48,
              },
            }}
          >
            {renderCards()}
          </Swiper>
          <Swiper
            pagination={needMobilePagination && { clickable: true }}
            initialSlide={1}
            watchOverflow
            className={cn(styles.slider, styles.sliderMobile, needMobilePagination && styles.withBullet)}
            slidesPerView={needMobilePagination ? MOBILE_SLIDES_TO_SHOW : mobileCards.length}
            loop={needMobilePagination}
            centeredSlides
            centeredSlidesBounds
            spaceBetween={10}
          >
            {mobileCards}
          </Swiper>
          {needDesktopPagination && (
            <>
              <div className={cn(styles.arrow, styles.arrowPrev)}>
                <Arrow direction="left" />
              </div>
              <div className={cn(styles.arrow, styles.arrowNext)}>
                <Arrow direction="right" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
