import { useState, useMemo, Fragment } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { CarBrand } from "@/utils/brandsModels/types";
import cn from "classnames";
import { Button } from "@mafin/ui-kit";
import { BRAND_ACTIVE_PRODUCT_PREFIX } from "@/utils/constants/brands";

import styles from "./index.module.scss";

export interface AllBrandsProps {
  title: ITitle;
  product: "kasko" | "osago";
  brands: CarBrand[];
  appearance: Appearance;
}

type IGroupedBrands = Record<string, CarBrand[]>;

export const AllBrands = ({ data: { title, product, brands, appearance } }: StrapiData<AllBrandsProps>) => {
  const [activeLetter, setActiveLetter] = useState<string | undefined>();

  const handleSelectActiveLetter = (groupLetter: string) => () =>
    setActiveLetter((prevState) => (prevState === groupLetter ? undefined : groupLetter));

  const handleResetActiveLetter = () => setActiveLetter(undefined);

  const { groupedBrandsLatin, groupedBrandsCyrillic } = useMemo(() => {
    const activeBrands = brands
      .filter((brand) => brand.is_active && brand[(BRAND_ACTIVE_PRODUCT_PREFIX + product) as keyof CarBrand])
      .sort((a, b) => a.name.localeCompare(b.name, "en"));

    const resultLatin: IGroupedBrands = {};
    const resultCyrillic: IGroupedBrands = {};

    activeBrands.forEach((brand) => {
      const firstLetter = brand.name[0].toUpperCase();

      if (/[А-ЯЁ]/.test(firstLetter)) {
        resultCyrillic[firstLetter] = resultCyrillic[firstLetter] ? [...resultCyrillic[firstLetter], brand] : [brand];
      } else {
        resultLatin[firstLetter] = resultLatin[firstLetter] ? [...resultLatin[firstLetter], brand] : [brand];
      }
    });

    return { groupedBrandsLatin: resultLatin, groupedBrandsCyrillic: resultCyrillic };
  }, [brands, product]);

  const renderLetters = (groupedBrands: IGroupedBrands) => {
    const alphabetType = Object.keys(groupedBrands).some((letter) => /[А-ЯЁ]/.test(letter))
      ? "cyrillicLettersContainer"
      : "latinLettersContainer";

    return (
      <div className={styles[alphabetType]} data-testid={alphabetType}>
        {Object.keys(groupedBrands).map((groupLetter) => (
          <Fragment key={groupLetter}>
            <div
              className={cn(styles.letterSelector, groupLetter === activeLetter ? styles.activeLetter : "")}
              onClick={handleSelectActiveLetter(groupLetter)}
            >
              {groupLetter}
            </div>
            <div className={styles.separator} />
          </Fragment>
        ))}
      </div>
    );
  };

  const renderGroup = (groupLetter: string) => (
    <div key={groupLetter} className={styles.groupContainer} data-testid="groupContainer">
      <span className={styles.groupLetter}>{groupLetter}</span>
      <div className={styles.groupList}>
        {{ ...groupedBrandsLatin, ...groupedBrandsCyrillic }[groupLetter as keyof IGroupedBrands].map((brand) => (
          <Link key={brand.slug} href={`/${product}/${brand.slug}`}>
            <a href={`/${product}/${brand.slug}`} className={styles.item}>
              {brand.name}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );

  const renderGroups = () =>
    Object.keys({ ...groupedBrandsLatin, ...groupedBrandsCyrillic }).map((groupLetter) => renderGroup(groupLetter));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title {...title} />
        <div className={styles.letterSelectorContainer} data-testid="firstLettersContainer">
          {!!Object.keys(groupedBrandsLatin).length && renderLetters(groupedBrandsLatin)}
          {!!Object.keys(groupedBrandsCyrillic).length && renderLetters(groupedBrandsCyrillic)}
          <div className={styles.buttonContainer}>
            {activeLetter && (
              <Button className={styles.resetButton} onClick={handleResetActiveLetter} variant="secondary">
                Сбросить
              </Button>
            )}
          </div>
        </div>
        <div className={styles.contentContainer}>{activeLetter ? renderGroup(activeLetter) : renderGroups()}</div>
      </div>
    </AppearanceWrapper>
  );
};
