import * as React from "react";
import cn from "classnames";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Arrow } from "@mafin/icons";
import { memo } from "react";
import { Phone } from "@/components/elements/ApplicationContainer";

import styles from "./index.module.scss";
import { Slide } from "./slide";

interface MobileSliderProps {
  title: string;
  phone: Phone[];
}

SwiperCore.use([Pagination, Navigation]);

export const MobileSlider = memo(function MobileSlider({ phone, title }: MobileSliderProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.slider}>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={{
            nextEl: ".swiper-arrow-next",
            prevEl: ".swiper-arrow-prev",
          }}
          pagination={{
            el: `.${styles.pagination}`,
            type: "bullets",
            bulletElement: "span",
            bulletClass: styles.pagination_bullet,
            bulletActiveClass: styles.pagination_bullet_active,
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
          }}
        >
          {phone.map((i, index) => (
            <SwiperSlide key={String(index)}>
              <Slide phone={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={cn("swiper-arrow-prev", styles.arrow, styles.arrow__prev)}>
        <Arrow direction="left" />
      </div>
      <div className={cn("swiper-arrow-next", styles.arrow, styles.arrow__next)}>
        <Arrow direction="right" />
      </div>
      <div className={cn("swiper-pagination-bullets", styles.pagination)} />
    </div>
  );
});
