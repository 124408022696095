import { FC } from "react";

import { Image, StrapiData } from "../types";

import styles from "./work-with-people.module.scss";

export type WorkWithPeopleProps = StrapiData<{
  title: string;
  subtitle: string;
  video_url: string;
  video?: Image;
}>;

export const WorkWithPeople: FC<WorkWithPeopleProps> = ({ data }) => {
  const { title, subtitle, video_url } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.description}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.video_container}>
          <iframe className={styles.video} src={video_url} title="Mafin video" />
        </div>
      </div>
    </div>
  );
};
