import { memo } from "react";
import Image from "next/image";
import { getStrapiMedia } from "@/utils/media";

import styles from "./index.module.scss";

export interface FrameProps {
  image: string;
}

export const Frame = memo(function Frame({ image }: FrameProps) {
  const fullUrl = getStrapiMedia(image);

  return (
    <div className={styles.frame}>
      <Image
        alt="phone-border"
        src="/images/mobile-app/phone-frame.png"
        layout="fill"
        sizes="(max-width: 979px) 250px, 313px"
        quality={70}
      />
      <div className={styles.top} />
      <div className={styles.inner}>
        <Image
          alt="phone"
          className={styles.slide}
          src={fullUrl}
          layout="fill"
          sizes="(max-width: 979px) 220px, (max-width: 1440px) 264px, 277px"
          quality={70}
        />
      </div>
    </div>
  );
});
