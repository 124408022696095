/* eslint-disable complexity */
import React, { FunctionComponent, useState } from "react";

import { Warning } from "../../elements/warning";
import { EuroprotocolTemplate } from "../europrotocol-template";

import styles from "./index.module.scss";

export interface RichDropdownProps {
  title: string;
  children: React.ReactNode;
  warning?: string;
  isHidden?: boolean;
  additional?: 'europrotocol';
}

export const RichDropdown: FunctionComponent<RichDropdownProps> = ({ title, children, warning = '', additional = null, isHidden }) => {
  const [display, setDisplay] = useState(true);

  let itemContentStyle;
  let itemArrowStyle;
  let itemVisibilityStyle;
  let titleStyle;

  if (isHidden) {
    itemVisibilityStyle = {
      display: `none`,
    };
  } else {
    itemVisibilityStyle = {
      display: `block`,
    };
  }

  if (display) {
    itemContentStyle = {
      opacity: 1,
      height: "auto",
      marginTop: 24,
    };
    itemArrowStyle = {
      transform: `rotate(180deg)`,
    };

  } else {
    itemContentStyle = {
      opacity: 0,
      height: 0,
      overflow: "hidden",
    };
  }

  const toggleItem = () => {
    setDisplay((prev) => !prev);
  };

  return (
    <div className={styles.item_box} style={itemVisibilityStyle}>
      <div className={styles.item_wrapper}>
        <div className={styles.item}>
          <div className={styles.content}>
            <div className={styles.item__top} onClick={toggleItem}>
              <h3 className={styles.item__title} style={titleStyle}>
                {title}
              </h3>
              <div className={styles.item__icon}>
                <div className={styles.item__icon__arrow} style={itemArrowStyle} />
              </div>
            </div>
            <div className={styles.item__content} style={itemContentStyle}>
              {children}
              {(warning || additional) && <div className={styles.delimeter_16} />}
              {warning && <Warning warning={warning} />}
              {additional === "europrotocol" && <EuroprotocolTemplate />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
