import React from "react";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

interface WarningProps {
  warning: string;
}

export const Warning: React.FC<WarningProps> = ({ warning }) => {
  return (
    <div className={styles.warning}>
      <div className={styles.warning__image} />
      <div className={styles.warning__text}>
        <HTMLFromRichTextEditor htmlString={warning} />
      </div>
    </div>
  );
};
