import { FC, useState } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Button, Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Border } from "@/components/shared/border";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface ActualMediaArticlesMobileProps {
  appearance: Appearance;
}

interface ILink {
  url: string;
  title: string;
  external?: boolean;
}

export const UsefulLinksMobile: FC<StrapiData<ActualMediaArticlesMobileProps>> = ({ data: { appearance } }) => {
  const [disclosed, setDisclosed] = useState(false);

  const links: ILink[] = [
    { url: "/osago", title: "Оформить полис ОСАГО" },
    { url: "/osago/calc", title: "Калькулятор ОСАГО", external: true },
    { url: "/osago/2022", title: "ОСАГО в 2022 году" },
    { url: "/osago/europrotocol", title: "Как заполнить Европротокол" },
    { url: "/osago/otkaz-v-osago", title: "Отказали в ОСАГО" },
    { url: "/kasko", title: "Оформить КАСКО онлайн" },
    { url: "/kasko/calc", title: "Калькулятор КАСКО", external: true },
    { url: "/kasko/franchise", title: "КАСКО с франшизой" },
    { url: "/strahovye", title: "Страховые партнеры Mafin" },
    { url: "/kasko/cars", title: "КАСКО по марке авто" },
    { url: "/strahovanie-ipoteki", title: "Страхование ипотеки" },
    { url: "/strahovanie-ns", title: "Страхование здоровья" },
    { url: "/strahovanie-vzr/calc", title: "Туристическая страховка онлайн" },
    { url: "/strahovanie-imushchestva/calc", title: "Рассчитать страховку квартиры" },
    { url: "/strahovanie-imushchestva", title: "Страхование недвижимости" },
    { url: "/strahovanie-vzr", title: "Страхование путешествий" },
  ];

  const renderLink = (title: string) => (
    <Border className={style.item} shadow>
      <Typography variant="h4">{title}</Typography>
    </Border>
  );

  const renderLinks = (links: ILink[]) =>
    links.map((i) =>
      i.external ? (
        <a href={i.url} key={i.url}>
          {renderLink(i.title)}{" "}
        </a>
      ) : (
        <Link href={i.url} key={i.url} passHref>
          <a>{renderLink(i.title)} </a>
        </Link>
      )
    );

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Typography variant="h2" component="h2" className={style.title}>
          Полезные ссылки
        </Typography>
        <div className={style.items}>
          {renderLinks(links.slice(0, 3))} {disclosed && renderLinks(links.slice(3))}
        </div>
        <Button onClick={() => setDisclosed(!disclosed)} variant="secondary" className={style.button}>
          {disclosed ? "Показать меньше" : "Все ссылки"}
        </Button>
      </Container>
    </AppearanceWrapper>
  );
};
