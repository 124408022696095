import React, { FunctionComponent } from "react";
import { passAttributes } from "@/utils/parse-attributes";

export const Vrz: FunctionComponent = () => {
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const vzr = document.createElement("script");
    const container = scriptContainerRef.current;

    passAttributes(
      vzr,
      [
        ["src", "https://sravni.ru/widgets/loader.js"],
        ["data-product", "vzr"],
        ["data-inframe", "true"],
        ["data-theme", `palette='{"elementsColor":"35, 35, 199","activeElementsColor":"35, 35, 199"}'`],
        ["data-aff_id", "2123"],
        ["data-offer_id", "1068"],
        ["data-aff_sub", "10"],
        ["data-source", "2855"],
        ["data-dontshow", "7900"],
      ],
      container
    );
  }, []);

  return <div ref={scriptContainerRef} id={`vrz_script-container_${Math.random().toString(36).substr(2, 9)}`} />;
};
