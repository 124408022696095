import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import classnames from "classnames";
import { Breadcrumbs } from "@mafin/ui-kit";
import { Arrow } from "@mafin/icons";
import { Breadcrumb, Slide as ISlide, StrapiData } from "@/components/sections/types";

import styles from "./index.module.scss";
import { Slide } from "./components/slide";

export interface Data {
  breadcrumbs: Breadcrumb[];
  slides: ISlide[];
}

SwiperCore.use([Pagination, Navigation, Autoplay]);

export const Banner = ({ data }: StrapiData<Data>) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const breadcrumbs = data.breadcrumbs?.map((breadcrumb: Breadcrumb) => {
    return { link: breadcrumb.link, name: breadcrumb.name };
  });

  return (
    <div className={styles.container}>
      {!!breadcrumbs.length && (
        <div className={styles.content}>
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
      )}
      {data?.slides?.length > 1 ? (
        <>
          <Swiper
            loop
            pagination={{
              el: `.${styles.pagination}`,
              type: "bullets",
              bulletElement: "span",
              bulletClass: styles.pagination_bullet,
              bulletActiveClass: styles.pagination_bullet_active,
              clickable: true,
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            onActiveIndexChange={(swiper) => setActiveSlide(swiper.activeIndex)}
            navigation={{
              nextEl: ".banner-swiper-arrow-next",
              prevEl: ".banner-swiper-arrow-prev",
            }}
            className={styles.slider}
            slidesPerView={1}
          >
            {data.slides?.map((slide, index) => {
              return (
                <SwiperSlide key={`advantage_slidecard_${index}`} className={styles.slide}>
                  <Slide {...slide} isActive={index === activeSlide} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={classnames("banner-swiper-arrow-prev", styles.arrow, styles.arrow__prev)}>
            <Arrow direction="left" />
          </div>
          <div className={classnames("banner-swiper-arrow-next", styles.arrow, styles.arrow__next)}>
            <Arrow direction="right" />
          </div>
          <div className={classnames(styles.slide__inner, styles.pagination__container)}>
            <div className={classnames("banner-swiper-pagination-bullets", styles.pagination)} />
          </div>
        </>
      ) : (
        data?.slides?.length && <Slide {...data.slides[0]} />
      )}
    </div>
  );
};
