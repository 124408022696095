import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { sendSmsCode, verifySmsCode } from "@/utils/api/requests/main-site";

export const useSmsCode = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginChallenge, setLoginChallenge] = useState<string>("");
  const [retryTime, setRetryTime] = useState<number>(0);
  const [retryTimer, setRetryTimer] = useState<number>(0);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [codeWasSent, setCodeWasSent] = useState(false);
  const replyInterval = useRef<number>();

  const sendCode = useCallback(
    (phone: string) => {
      if (!executeRecaptcha) return;

      setSendCodeLoading(true);

      executeRecaptcha()
        .then((token: string) => {
          sendSmsCode(phone, token).then((res) => {
            setCodeWasSent(true);

            if (res?.data?.retry_time) {
              setRetryTime(res.data.retry_time);
              setLoginChallenge(res.data.login_challenge);
            }
          });
        })
        .finally(() => {
          setSendCodeLoading(false);
        });
    },
    [executeRecaptcha]
  );

  const validateCode = useCallback(
    async (phone: string, code: string) => {
      setValidationLoading(true);

      try {
        const res = await verifySmsCode(phone, loginChallenge, code);

        if (res?.data?.access_token) {
          return true;
        }
      } finally {
        setValidationLoading(false);
      }

      return false;
    },
    [loginChallenge]
  );

  const resetCodeWasSent = useCallback(() => {
    setCodeWasSent(false);
  }, []);

  useEffect(() => {
    if (retryTime) {
      const getTimeDiff = () => Math.round((Number(new Date(retryTime * 1000)) - Number(new Date())) / 1000) + 1;

      setRetryTimer(getTimeDiff());

      replyInterval.current = window.setInterval(() => {
        const timeDiff = getTimeDiff();

        setRetryTimer(timeDiff);

        if (timeDiff <= 0) {
          clearInterval(replyInterval.current);
        }
      }, 1000);
    }

    return () => clearInterval(replyInterval.current);
  }, [retryTime]);

  return useMemo(
    () => ({
      sendCode,
      validateCode,
      sendCodeLoading,
      retryTimer,
      validationLoading,
      codeWasSent,
      resetCodeWasSent,
    }),
    [sendCode, validateCode, sendCodeLoading, retryTimer, validationLoading, codeWasSent, resetCodeWasSent]
  );
};
