import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import cn from "classnames";
import { Appearance, StrapiData, Link as ILink } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { ButtonLink } from "@/components/elements/button-link";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";

import styles from "./index.module.scss";

export interface TitleTextButtonProps {
  text: string;
  button?: ILink;
  appearance: Appearance;
}

export const TitleTextButton: React.FC<StrapiData<TitleTextButtonProps>> = ({ data: { text, button, appearance } }) => {
  const {
    query: { slug },
  } = useRouter();
  const isMainPage = !Array.isArray(slug);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={cn(styles.sectionContainer, isMainPage && styles.mainPage)}>
        <HTMLFromRichTextEditor htmlString={text} className={styles.text} />
        <div className={styles.buttonContainer}>
          {button && (
            <Link href={button.url} passHref>
              <ButtonLink className={styles.button} variant="secondary" {...strapiLinkComponentToAnchorProps(button)}>
                {button.text}
              </ButtonLink>
            </Link>
          )}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
