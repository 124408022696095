import { Arrow } from "@mafin/icons";
import { IconLink } from "@mafin/ui-kit";
import classNames from "classnames";
import React, { FC } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { useWindowSize } from "@/utils/hooks/use-window-size";

import { Appearance, Link, StrapiData } from "../types";

import { Card, CardProps } from "./components";
import styles from "./product-advantages.module.scss";

export interface ProductAdvantages {
  title: string;
  link: Link;
  cards: CardProps[];
  appearance: Appearance;
}

SwiperCore.use([Navigation]);

export const ProductAdvantages: FC<StrapiData<ProductAdvantages>> = ({ data }) => {
  const { title, cards, link, appearance } = data;

  const size = useWindowSize();
  const mobileBreakpoint = 979;

  const dontShowArrows = cards?.length <= 3;

  const getIsLoop = () => {
    if (size.width! < mobileBreakpoint) {
      return true;
    }

    return !dontShowArrows;
  };

  const slides = cards.map((card, index) => (
    <SwiperSlide key={`${card.title}-${index}`} className={styles.slide}>
      <Card {...card} />
    </SwiperSlide>
  ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <IconLink href={link.url} variant="primary" className={classNames(styles.icon_link, styles.desktop)}>
            {link.text}
          </IconLink>
        </div>
      </div>

      <div className={styles.container}>
        <Swiper
          slidesPerView={1.05}
          spaceBetween={1}
          loop={getIsLoop()}
          className={classNames(styles.slider)}
          updateOnWindowResize
          navigation={{
            nextEl: ".swiper-arrow-next",
            prevEl: ".swiper-arrow-prev",
          }}
          pagination={{
            el: `.${styles.pagination}`,
            type: "bullets",
            bulletElement: "span",
            bulletClass: styles.pagination_bullet,
            bulletActiveClass: styles.pagination_bullet_active,
            clickable: true,
          }}
          breakpoints={{
            980: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            386: {
              slidesPerView: 1.15,
              spaceBetween: 8,
            },
            368: {
              slidesPerView: 1.08,
              spaceBetween: 1,
            },
            351: {
              slidesPerView: 1.033,
              spaceBetween: 1,
            },
            343: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            320: {
              slidesPerView: 1.05,
              spaceBetween: 1,
            },
          }}
        >
          {slides}
        </Swiper>
        <div
          className={classNames(
            "swiper-arrow-prev",
            styles.arrow,
            styles.arrow__prev,
            dontShowArrows && styles.displayNone
          )}
        >
          <Arrow direction="left" />
        </div>
        <div
          className={classNames(
            "swiper-arrow-next",
            styles.arrow,
            styles.arrow__next,
            dontShowArrows && styles.displayNone
          )}
        >
          <Arrow direction="right" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={classNames("swiper-pagination-bullets", styles.pagination)} />
        <IconLink href={link.url} variant="primary" className={classNames(styles.icon_link, styles.mobile)}>
          {link.text}
        </IconLink>
      </div>
    </AppearanceWrapper>
  );
};
