import { FC } from "react";
import { Image as IImage } from "@/components/sections/types";
import { Typography } from "@/components/shared/typography";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import Image from "next/image";
import { getStrapiMedia } from "@/utils/media";

import styles from "./index.module.scss";

export interface BannerProps {
  title: string;
  subtitle: string;
  image: IImage;
}

export const Banner: FC<BannerProps> = ({ title, subtitle, image }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <Typography type="h1">{title}</Typography>
          <HTMLFromRichTextEditor htmlString={subtitle} />
        </div>
        <div className={styles.image}>
          <Image alt={image?.alternativeText} src={getStrapiMedia(image)} objectFit="contain" layout="fill" />
        </div>
      </div>
    </div>
  );
};
