import { useContext } from "react";
import { Partner } from "@/utils/globalContext/types";
import { useRouter } from "next/router";
import { Data } from "@/components/sections/partners-new";

import { GlobalContext } from "../../pages/_app";

interface PartnerFromGlobal extends Omit<Partner, "kaskoPsoCities"> {}

export interface PartnerCard extends Omit<PartnerFromGlobal, "href"> {
  href: string | null;
}

export const usePartnerCards = ({ linkType, linkPrefix }: Omit<Data, "appearance">) => {
  const { partners, products, turboPageSlug } = useContext(GlobalContext);
  const router = useRouter();
  const slug = router?.query.slug || turboPageSlug;

  const getPartnersForCurrentProduct = (): PartnerFromGlobal[] => {
    const currentProduct = Array.isArray(slug) ? slug[0] : null;
    const isProductPage = products.some(({ slug }) => slug === currentProduct);

    return currentProduct && isProductPage
      ? partners.filter((partner) => partner.products.products.some((product) => product.slug === currentProduct))
      : partners.filter((partner) => partner.showOnMainPage);
  };

  const setHrefByLinkType = (partnerCards: PartnerFromGlobal[]): PartnerCard[] => {
    if (linkType === "noLinks") {
      return partnerCards.map((partner) => ({ ...partner, href: null }));
    } else if (linkType === "custom") {
      return partnerCards.map((partner) => ({ ...partner, href: `${linkPrefix}/${partner.slug}` }));
    }

    return partnerCards.map((partner) => ({ ...partner, href: partner.href }));
  };

  return setHrefByLinkType(getPartnersForCurrentProduct().sort((a, b) => b.orderWeight - a.orderWeight));
};
