import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface TextBannerProps {
  appearance: Appearance;
}

export const TextBanner: FC<StrapiData<TextBannerProps>> = ({ data: { appearance } }) => {
  const richText =
    '<h1 style="text-align:center;">Купите КАСКО<br>не выходя из дома</h1><h4 style="text-align:center;"><br>Помогаем сравнить предложения<br>и сделать правильный выбор</h4>';

  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <HTMLFromRichTextEditor htmlString={richText} className={style.text} />
      </Container>
    </AppearanceWrapper>
  );
};
