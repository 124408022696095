import React from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { CarBrand } from "@/utils/brandsModels/types";
import { BRAND_ACTIVE_PRODUCT_PREFIX } from "@/utils/constants/brands";

import styles from "./index.module.scss";

export interface PopularBrandsProps {
  mainTitle: ITitle;
  title: ITitle;
  product: "kasko" | "osago";
  brands: CarBrand[];
  appearance: Appearance;
}

export const PopularBrands = ({
  data: { mainTitle, title, product, brands, appearance },
}: StrapiData<PopularBrandsProps>) => {
  const activeBrands = brands
    .filter(
      (brand) =>
        brand.logo &&
        brand.popular &&
        brand.is_active &&
        brand[(BRAND_ACTIVE_PRODUCT_PREFIX + product) as keyof CarBrand]
    )
    .sort((a, b) => a.name.localeCompare(b.name, "en"))
    .sort((a, b) => b.orderWeight - a.orderWeight);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title {...mainTitle} className={styles.mainTitle} />
        <Title {...title} className={styles.title} />
        <div className={styles.cardsContainer}>
          {activeBrands.map((brand) => (
            <Link key={brand.id} href={`/${product}/${brand.slug}`}>
              <a href={`/${product}/${brand.slug}`} className={styles.cardContainer}>
                <div className={styles.imageContainer}>
                  <img src={brand.logo?.url} alt={brand.logo?.alternativeText || "logo"} className={styles.image} />
                </div>
                <div className={styles.name}>{brand.name}</div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
