import { FC } from "react";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { ButtonLink } from "@/components/elements/button-link";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface MobileAppMobileProps {
  appearance: Appearance;
}

export const MobileAppMobile: FC<StrapiData<MobileAppMobileProps>> = ({ data: { appearance } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Typography variant="h2" component="h2" className={style.title}>
          Мобильное приложение Mafin
        </Typography>
        <Typography variant="simpleText" className={style.title}>
          Ваши полисы всегда под рукой
        </Typography>
        <ButtonLink href="/mobile-app" className={style.button}>
          Скачать
        </ButtonLink>
      </Container>
    </AppearanceWrapper>
  );
};
