import * as React from "react";
import classNames from "classnames";
import { memo, useCallback } from "react";

import { Phone } from "../ApplicationContainer";

import styles from "./index.module.scss";

export interface ButtonsProps {
  title: string;
  slides: Phone[];
  activeSlide: number;
  onSelectSlide: (selectedSlide: number) => void;
}

export const DesktopSliderNavigation = memo<ButtonsProps>(function Buttons({
  activeSlide,
  onSelectSlide,
  title,
  slides,
}) {
  const handleSelect = useCallback((index: number) => () => onSelectSlide(index), [onSelectSlide]);

  return (
    <div className={styles.buttons}>
      <h2 className={styles.title}>{title}</h2>
      {slides.map((slide, index) => (
        <div
          className={classNames(styles.button, {
            [styles.active]: activeSlide === index,
          })}
          key={String(index)}
          onClick={handleSelect(index)}
        >
          {slide.button}
        </div>
      ))}
    </div>
  );
});
