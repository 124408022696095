import { memo } from "react";

import { Frame } from "../../frame";
import { Step } from "../../step";
import { Phone } from "../../ApplicationContainer";

import styles from "./index.module.scss";

export interface SlideProps {
  phone: Phone;
}

export const Slide = memo(function Slide({ phone }: SlideProps) {
  return (
    <div className={styles.slide}>
      <div className={styles.frame}>
        <div className={styles.phone}>
          <Frame image={phone.image.url} />
        </div>
      </div>
      <div className={styles.content}>
        <Step content={phone.content} />
      </div>
    </div>
  );
});
