import { FC } from "react";
import cn from "classnames";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { KaskoProtectCard, KaskoProtectCardProps } from "@/components/elements/kasko-protect-card";
import { Appearance, StrapiData, Title } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

export interface Data {
  cards: KaskoProtectCardProps[];
  title: Title;
  appearance: Appearance;
}

export const AvailableOptionsForPolicyProtection: FC<StrapiData<Data>> = ({ data: { cards, title, appearance } }) => {
  const slides = cards.map((card) => (
    <SwiperSlide className={styles.slide} key={`available-options-${card.title}`}>
      <KaskoProtectCard className={styles.card} {...card} />
    </SwiperSlide>
  ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <h2 className={cn(styles.title, styles.content)}>{title?.text}</h2>

        <div className={cn(styles.container, styles.desktop, styles.content)}>
          <div className={styles.desktopCards}>
            {cards.map((card) => (
              <KaskoProtectCard className={styles.card} {...card} key={card.title} />
            ))}
          </div>
        </div>

        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          className={styles.swiper}
        >
          {slides}
        </Swiper>
      </div>
    </AppearanceWrapper>
  );
};
