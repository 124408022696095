import { FC } from "react";
import { TextBanner } from "@/components/sections/kasko-mobile/components/TextBanner";
import { LeadGeneration } from "@/components/sections/lead-generation";
import { AdvantagesMobile } from "@/components/sections/kasko-mobile/components/AdwantagesMobile";
import { StepsMobile } from "@/components/sections/kasko-mobile/components/StepsMobile";
import { PolicyGenuineMobile } from "@/components/sections/kasko-mobile/components/PolicyGenuineMobile";
import { CrossProductMobile } from "@/components/sections/kasko-mobile/components/CrossProductMobile";
import { ActualMediaArticlesMobile } from "@/components/sections/kasko-mobile/components/ActualMediaArticlesMobile";
import { IArticle } from "@/utils/api/types/mafin-media";
import { UsefulLinksMobile } from "@/components/sections/kasko-mobile/components/UsefulLinksMobile";
import { MobileAppMobile } from "@/components/sections/kasko-mobile/components/MobileAppMobile";
import { StrapiData } from "@/components/sections/types";

interface Data {
  articles: IArticle[] | null;
}

export const KaskoMobile: FC<StrapiData<Data>> = ({ data: { articles } }) => {
  return (
    <>
      <TextBanner
        data={{
          appearance: { background: "White", paddingTop: true, paddingBottom: true },
        }}
      />
      <LeadGeneration
        data={{
          background: "White",
          offset: "halfOfHeight",
          tab_auto: [
            {
              button_url: "/kasko/calc",
              link_url: "/kasko/calc",
              auto: true,
              col_1: "Введите госномер — данные заполнятся автоматически ",
              col_2: "или нажмите «Рассчитать»",
              link_title: "",
              col_3: ", если пока не получили его",
              title: "Калькулятор КАСКО",
              button_text: "Рассчитать",
              type: "kasko",
            },
          ],
          tab_insure: [],
          lightPage: true,
        }}
      />
      <AdvantagesMobile
        data={{
          appearance: { background: "White", paddingTop: false, paddingBottom: true },
        }}
      />
      <StepsMobile
        data={{
          appearance: { background: "White", paddingTop: false, paddingBottom: true },
        }}
      />
      <PolicyGenuineMobile
        data={{
          appearance: { background: "White", paddingTop: false, paddingBottom: true },
        }}
      />
      <CrossProductMobile
        data={{
          appearance: { background: "White", paddingTop: true, paddingBottom: true },
        }}
      />
      <ActualMediaArticlesMobile
        data={{
          appearance: { background: "White", paddingTop: true, paddingBottom: true },
          articles,
        }}
      />
      <UsefulLinksMobile
        data={{
          appearance: { background: "White", paddingTop: true, paddingBottom: true },
        }}
      />
      <MobileAppMobile
        data={{
          appearance: { background: "Bg", paddingTop: true, paddingBottom: true },
        }}
      />
    </>
  );
};
