import { FC, useState } from "react";
import { Image, StrapiData } from "@/components/sections/types";
import { Typography } from "@/components/shared/typography";
import { ButtonLink } from "@/components/elements/button-link";
import { SliderBlock } from "@/components/sections/sovcom-leadgeneration/shared/slider-block";
import { MIN_CAR_PRICE } from "@/components/sections/sovcom-leadgeneration/shared/utils/constants";
import { getPriceOfKasko } from "@/components/sections/sovcom-leadgeneration/shared/utils/helpers";

import { Banner } from "@/components/sections/sovcom-leadgeneration/sovcom-leadgeneration-banner/components/banner";

import Arrows from "./img/arrows.svg";
import styles from "./index.module.scss";

export interface SovcomLeadgenerationBannerData {
  bannerTitle: string;
  bannerSubtitle: string;
  bannerImage: Image;
}

export const SovcomLeadgenerationBanner: FC<StrapiData<SovcomLeadgenerationBannerData>> = ({
  data: { bannerImage, bannerTitle, bannerSubtitle },
}) => {
  const [value, setValue] = useState<number>(MIN_CAR_PRICE);

  return (
    <div className={styles.container}>
      <Banner {...{ title: bannerTitle, subtitle: bannerSubtitle, image: bannerImage }} />
      <div className={styles.leadGeneration}>
        <div className={styles.leftPart}>
          <div className={styles.content}>
            <Typography useParagraph type="h5">
              Справедливый <br /> и доступный тариф
            </Typography>
            <Typography type="littleText" color="additional">
              Цена полиса
            </Typography>
            <Typography useParagraph type="h3" color="corporate">
              От 21 600₽
            </Typography>
            <Typography type="h5" useParagraph>
              Онлайн оформление
            </Typography>
            <Typography type="h3" useParagraph color="corporate">
              По двум документам&nbsp;— паспорт и СТС/ПТС
            </Typography>
          </div>
          <div className={styles.iconWrapper}>
            <Arrows />
          </div>
        </div>
        <div className={styles.rightPart}>
          <ButtonLink className={styles.mobileButton} href="/kasko/calc?promo=SOVKOMBANK">
            Оформить
          </ButtonLink>
          <div className={styles.sliderWrapper}>
            <Typography className={styles.sliderTitle} type="h5" useParagraph>
              Укажите стоимость вашего авто:
            </Typography>
            <SliderBlock className={styles.slider} value={value} onChange={setValue} />
          </div>
          <div className={styles.buttonWrapper}>
            <Typography type="semiboldSimpleText">Цена полиса:</Typography>
            <div className={styles.price}>
              <Typography type="h1" className={styles.policePrice} useParagraph>
                {getPriceOfKasko(value).toLocaleString("ru")}
              </Typography>
              <Typography type="simpleText">руб</Typography>
            </div>
            <ButtonLink className={styles.desktopButton} href="/kasko/calc?promo=SOVKOMBANK">
              Оформить
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
