import React, { FC } from "react";
import { Appearance, Link, StrapiData, Title as ITitle } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { ButtonLink } from "@/components/elements/button-link";

import styles from "./index.module.scss";

export interface TitleWithTextData {
  appearance: Appearance;
  title: ITitle;
  text: string;
  button?: Link;
}

export const TitleWithText: FC<StrapiData<TitleWithTextData>> = ({ data: { appearance, text, title, button } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <Title className={styles.title} {...title} />
        <div className={styles.text}>
          <HTMLFromRichTextEditor htmlString={text} />
          {button && (
            <ButtonLink href={button.url} variant="primary" className={styles.button}>
              {button.text}
            </ButtonLink>
          )}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
