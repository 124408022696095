import { FC } from "react";
import { Typography } from "@/components/shared/typography";
import { RangeSlider } from "@/components/shared/range-slider";
import { MAX_CAR_PRICE, MIN_CAR_PRICE } from "@/components/sections/sovcom-leadgeneration/shared/utils/constants";
import cn from "classnames";

import styles from "./index.module.scss";

export interface SliderBlockProps {
  className?: string;
  value: number;

  onChange(value: number): void;
}

export const SliderBlock: FC<SliderBlockProps> = ({ className, value, onChange }) => {
  return (
    <div className={cn(styles.container, className)}>
      <Typography type="inputTitle">Стоимость автомобиля</Typography>
      <div className={styles.price}>
        <Typography type="h4" useParagraph>
          {`${value.toLocaleString("ru")} ₽`}
        </Typography>
        <Typography type="inputTitle" color="additional">
          Макс. стоимость: 4 000 000₽
        </Typography>
      </div>
      <RangeSlider
        value={value}
        onChange={(event) => onChange(parseInt(event.currentTarget.value, 10))}
        min={MIN_CAR_PRICE}
        max={MAX_CAR_PRICE}
        step={100000}
      />
    </div>
  );
};
