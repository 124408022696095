import { differenceInYears, isValid, parse } from "date-fns";

export const birthdateValidator = (value: string) => {
  const MAX_AGE = 75;
  const MIN_AGE = 18;
  const birthdate = parse(value, "dd.MM.yyyy", new Date());

  if (!isValid(birthdate)) {
    return "Некорректная дата";
  }

  const ageOfInsurer = differenceInYears(new Date(), birthdate);

  if (ageOfInsurer >= MAX_AGE - 1) {
    return `Максимальный возраст на дату окончания полиса – ${MAX_AGE} лет`;
  }

  if (ageOfInsurer < MIN_AGE) {
    return `Минимальный возраст – ${MIN_AGE} лет`;
  }
};
