import { memo, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Dropdown, Input, Button, Link } from "@mafin/ui-kit";
import {
  INSURANCE_COMPANIES,
  SERIES,
} from "@/components/sections/insurance-case/contacts/contact-information/constants";
import { Information } from "@/components/shared/information";
import { useSmsCode } from "@/utils/hooks/use-sms-code";

import styles from "./index.module.scss";

const PHONE_REG = /^\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2}/;
const POLICY_NUMBER_REG = /[0-9]{10}/;

export interface ContactInformationForm {
  insuranceCompany: { id: string; name: string };
  phone: string;
  code: string;
  boughtInMafin: boolean;
  policySeries: { id: string; name: string };
  policyNumber: string;
}

interface ContactInformationProps {
  information: ContactInformationForm | null;

  onSubmit(data: ContactInformationForm): void;

  onValid(valid: boolean): void;
}

// eslint-disable-next-line complexity
export const ContactInformation = memo<ContactInformationProps>(function ContactInformation({
  onSubmit,
  onValid,
  information,
}) {
  const {
    control,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<ContactInformationForm>();
  const boughtInMafin = watch("boughtInMafin", true);
  const insuranceCompany = watch("insuranceCompany", INSURANCE_COMPANIES[0]);
  const phone = watch("phone");
  const code = watch("code", "");
  const policyNumber = watch("policyNumber", "");

  const [isCodeValid, setIsCodeValid] = useState(false);
  const { sendCode, sendCodeLoading, retryTimer, resetCodeWasSent, validateCode, validationLoading, codeWasSent } =
    useSmsCode();

  // Можем продолжить только если полис куплен в мафин и страховая компания это абсолют страхование
  const canContinue = boughtInMafin && insuranceCompany?.id === INSURANCE_COMPANIES[0].id;

  const isPhoneValid = PHONE_REG.test(phone);

  const handleFormSubmit: SubmitHandler<ContactInformationForm> = (data) => onSubmit(data);

  const handleSendSmsButtonClick = () => sendCode(phone);

  const renderInformation = () => {
    if (!boughtInMafin) {
      return <Information>Пожалуйста, обратитесь в свою страховую компанию</Information>;
    }

    if (boughtInMafin && insuranceCompany?.id !== INSURANCE_COMPANIES[0].id) {
      return (
        <Information className={styles.information}>
          Вы можете заявить событие через службу поддержки Mafin <Link href="tel:88005556439">8 (800) 555-64-39</Link>
        </Information>
      );
    }
  };

  const validateCodeInput = useCallback(() => {
    if (code.replaceAll("_", "").length === 4) {
      validateCode(phone, code).then((res) => {
        if (res) {
          setIsCodeValid(true);
          clearErrors("code");
        } else {
          setError("code", {
            message: "Неверный код",
          });
        }
      });
    }
  }, [clearErrors, code, phone, setError, validateCode]);

  useEffect(() => {
    validateCodeInput();
  }, [validateCodeInput]);

  useEffect(() => {
    if (!information) {
      setIsCodeValid(false);
      resetCodeWasSent();
      reset({
        code: "",
        policyNumber: "",
        boughtInMafin: true,
        insuranceCompany: INSURANCE_COMPANIES[0],
      });
    }
  }, [information, reset, resetCodeWasSent]);

  useEffect(() => {
    if (canContinue && isCodeValid && POLICY_NUMBER_REG.test(policyNumber)) {
      onValid(true);
    } else {
      onValid(false);
    }
  }, [canContinue, isCodeValid, onValid, policyNumber]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Controller
          name="insuranceCompany"
          control={control}
          defaultValue={INSURANCE_COMPANIES[0]}
          render={({ field }) => (
            <Dropdown
              options={INSURANCE_COMPANIES}
              selectedOption={field.value}
              chooseOption={(value) => {
                console.log("onchange", value);
                field.onChange(value);
              }}
              label="Выберите свою страховую компанию"
            />
          )}
        />
        <Controller
          name="boughtInMafin"
          control={control}
          defaultValue
          render={({ field }) => <Checkbox {...field} label="Полис куплен в Mafin" checked={field.value} />}
        />
      </div>

      {!canContinue && renderInformation()}
      {canContinue && (
        <>
          <div className={styles.row}>
            <Controller
              name="phone"
              rules={{
                pattern: {
                  value: PHONE_REG,
                  message: "Неверный формат",
                },
                required: { value: true, message: "Обязательно" },
              }}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  error={errors.phone?.message}
                  maskedProps={{
                    mask: "+7(999)999-99-99",
                  }}
                  readOnly={isCodeValid}
                  label="Контактный номер телефона"
                  {...field}
                />
              )}
            />
            <div className={styles.codeWrapper}>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    maskedProps={{ mask: "9999" }}
                    error={errors.code?.message}
                    loading={validationLoading}
                    label="Проверочный код"
                    disabled={!codeWasSent}
                    readOnly={isCodeValid}
                    {...field}
                  />
                )}
              />
              {!isCodeValid && retryTimer > 0 && (
                <p>
                  Отправить еще раз <span>через {retryTimer} сек</span>
                </p>
              )}
            </div>
            {!isCodeValid && retryTimer <= 0 && (
              <Button
                loading={sendCodeLoading}
                disabled={!isPhoneValid}
                onClick={handleSendSmsButtonClick}
                className={styles.codeButton}
              >
                Получить код
              </Button>
            )}
          </div>

          <div className={styles.captcha}>
            Этот сайт защищен reCAPTCHA от Google.{" "}
            <Link href="https://mafin.ru/legal/privacy">Политика конфиденциальности</Link> и{" "}
            <Link href="https://mafin.ru/legal/agreement">Условия использования</Link>
          </div>

          {isCodeValid && (
            <>
              <h5>Ваш полис ОСАГО</h5>

              <div className={styles.row}>
                <Controller
                  name="policySeries"
                  control={control}
                  defaultValue={SERIES[0]}
                  render={({ field }) => (
                    <Dropdown
                      options={SERIES}
                      label="Серия полиса"
                      selectedOption={field.value}
                      chooseOption={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="policyNumber"
                  rules={{
                    pattern: { value: /^\d{10}$/, message: "Неверный формат" },
                    required: { value: true, message: "Обязательно" },
                  }}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      error={errors.policyNumber?.message}
                      maskedProps={{ mask: "9999999999" }}
                      label="Номер полиса"
                      {...field}
                    />
                  )}
                />
                <Button
                  disabled={!POLICY_NUMBER_REG.test(policyNumber)}
                  onClick={handleSubmit(handleFormSubmit)}
                  className={styles.nextButton}
                >
                  Далее
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
});
