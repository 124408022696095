import * as React from "react";
import classNames from "classnames";

import { useCallback } from "react";

import { Image, StrapiData } from "../../sections/types";

import styles from "./index.module.scss";
import { DesktopSlider } from "./desktop-slider";
import { MobileSlider } from "./mobile-slider";

export interface Phone {
  content: string;
  button: string;
  image: Image;
}

export interface ActionsProps {
  title: string;
  phone: Phone[];
}

const PHONE_CONTENT_HEIGHT = 775;

export function ApplicationContainer({ data: { phone, title } }: StrapiData<ActionsProps>) {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const breakpoints = React.useRef<number[]>([]);
  const [activeSlide, setActiveSlide] = React.useState<number>(0);

  const slidesCount = phone.length;
  const contentHeight = PHONE_CONTENT_HEIGHT * slidesCount + PHONE_CONTENT_HEIGHT;

  React.useEffect(() => {
    const content = contentRef.current;

    if (!content) return;

    const updateBreakpoints = () => {
      let startPoint = content.getBoundingClientRect().top + window.pageYOffset;

      if (breakpoints.current.length === 0) {
        startPoint =
          content.getBoundingClientRect().top + window.pageYOffset + content.clientHeight / (slidesCount * 2);
      }

      breakpoints.current! = [startPoint];

      for (let i = 1; i <= slidesCount; i++) {
        const prev = breakpoints.current![i - 1];

        breakpoints.current!.push(prev + PHONE_CONTENT_HEIGHT);
      }
    };

    const detectActiveSlide = () => {
      const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + 100;

      for (let i = 0; i < slidesCount; i++) {
        const isActive = scrollTop >= breakpoints.current[i] && scrollTop < breakpoints.current[i + 1];

        if (isActive) {
          setActiveSlide(i);
        }
      }
    };

    updateBreakpoints();
    window.addEventListener("resize", updateBreakpoints);
    window.addEventListener("scroll", detectActiveSlide);

    return () => {
      window.removeEventListener("resize", updateBreakpoints);
      window.removeEventListener("scroll", detectActiveSlide);
    };
  }, [slidesCount]);

  const goToSlide = useCallback((slide: number) => {
    const top = breakpoints.current[slide];

    window.scrollTo({
      top: top,
    });
  }, []);

  return (
    <div className={styles.container}>
      <div style={{ height: contentHeight }} ref={contentRef} className={classNames(styles.wrapper, styles.desktop)}>
        <div className={styles.sticky}>
          <div className={styles.content}>
            <DesktopSlider activeSlide={activeSlide} onSelectSlide={goToSlide} title={title} slides={phone} />
          </div>
        </div>
      </div>
      <div className={styles.laptop}>
        <MobileSlider phone={phone} title={title} />
      </div>
    </div>
  );
}
