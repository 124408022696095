import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@/components/shared/typography";
import { Title } from "@/components/shared/title";
import { IconLink } from "@mafin/ui-kit";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { getStrapiMedia } from "@/utils/media";
import { useWindowSize } from "@/utils/hooks/use-window-size";
import { getNextImageSize, MAX_MOBILE_WIDTH, MAX_LAPTOP_WIDTH } from "@/utils/next-image-helpers";
import { Title as ITitle, Image as IImage, Link as ILink, StrapiData, Appearance } from "@/components/sections/types";

import styles from "./index.module.scss";

interface ICard {
  text: string;
  image: IImage;
}

export interface Data {
  title: ITitle;
  link?: ILink;
  cards: ICard[];
  appearance: Appearance;
}

SwiperCore.use([Pagination]);

export const AdvantagesNew = ({ data: { title, link, cards, appearance } }: StrapiData<Data>) => {
  const size = useWindowSize();
  const router = useRouter();

  const handleIconLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!link!.newTab) {
      e.preventDefault();
      router.push(link!.url);
    }
  };

  const desktopCards = cards?.map((card: ICard, index: number) => (
    <div key={`card_${index}`} className={styles.card}>
      <Typography type={size.width! > MAX_LAPTOP_WIDTH ? "h4" : "h5"} className={styles.cardText}>
        {card.text}
      </Typography>
      <Image
        className={styles.cardImage}
        src={getStrapiMedia(card.image.url)}
        alt={card.text}
        sizes={getNextImageSize(180, MAX_LAPTOP_WIDTH) + getNextImageSize(211)}
        objectFit="cover"
        layout="fill"
        quality={70}
        priority
      />
    </div>
  ));

  const mobileCards = (
    <Swiper
      className={styles.swiperContainer}
      spaceBetween={20}
      slidesPerView="auto"
      pagination
      data-testid="advantageNewSwiper"
    >
      {cards?.map((card, index) => (
        <SwiperSlide key={`advantageNewCard_${index}`} className={styles.card}>
          <Typography type="h4" className={styles.cardText}>
            {card.text}
          </Typography>
          <Image
            className={styles.cardImage}
            src={getStrapiMedia(card.image.url)}
            alt={card.text}
            sizes={getNextImageSize(211)}
            objectFit="cover"
            layout="fill"
            quality={70}
            priority
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title className={styles.title} {...title} />
        {link && (
          <IconLink
            className={styles.link}
            {...strapiLinkComponentToAnchorProps(link)}
            variant="primary"
            onClick={handleIconLinkClick}
          >
            {link.text}
          </IconLink>
        )}
        <div className={styles.cardsContainer}>
          {size.width! > MAX_MOBILE_WIDTH && desktopCards}
          {size.width! <= MAX_MOBILE_WIDTH && mobileCards}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
