import { memo } from "react";
import { Typography } from "@mafin/ui-kit";
import Image from "next/image";
import { Image as IImage } from "@/components/sections/types";
import { getStrapiMedia } from "@/utils/media";
import cn from "classnames";
import { formatPublicationDate } from "@/components/mafin-media/utils/helpers";
import Link from "next/link";
import { MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";

import styles from "./index.module.scss";

interface CardProps {
  title: string;
  description: string;
  slug: string;
  publicationDate: string;
  wide?: boolean;
  bannerImage?: IImage;
}

export const Card = memo<CardProps>(({ publicationDate, title, description, bannerImage, wide, slug }) => {
  return (
    <Link href={`${MAFIN_MEDIA_ROUTES.news}/${slug}`}>
      <a data-testid="NewsListCard">
        <div className={cn(styles.container, bannerImage && styles.withImage, wide && styles.wide)}>
          {bannerImage && (
            <div className={styles.imageWrapper}>
              <Image
                src={getStrapiMedia(bannerImage)}
                placeholder="blur"
                blurDataURL={getStrapiMedia(bannerImage, "thumbnail")}
                objectFit="cover"
                objectPosition="right"
                layout="fill"
                quality={30}
              />
            </div>
          )}
          <div className={cn(styles.content, bannerImage && styles.withImage)}>
            <div className={styles.textWrapper}>
              <Typography variant="h3" className={styles.title}>
                {title}
              </Typography>
              <Typography variant="littleText">{`${description.slice(0, 120)}...`}</Typography>
            </div>
            <Typography color="additionalLight" variant="inputTitle" suppressHydrationWarning>
              {formatPublicationDate(publicationDate, true)}
            </Typography>
          </div>
        </div>
      </a>
    </Link>
  );
});
