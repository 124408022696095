import React from "react";
import classnames from "classnames";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import { Link, StrapiData } from "../../sections/types";
import { Dropdown } from "../dropdown";

import styles from "./index.module.scss";

interface Licenses {
  title: string;
  content: string;
  link: Link;
}

export interface SKLicensesProps {
  title: string;
  license: Licenses[];
}

export const SkLicenses = ({ data: { title, license } }: StrapiData<SKLicensesProps>) => {
  const licenses = license!.map((item, index) => {
    return (
      <Dropdown key={`dropdown_license${index}`} title={item.title}>
        <HTMLFromRichTextEditor className={styles.license__content} htmlString={item.content} />
        <a className={styles.license__link} {...strapiLinkComponentToAnchorProps(item.link)}>
          {item.link.text}
        </a>
      </Dropdown>
    );
  });

  return (
    <div className={classnames(styles.sk_licenses)}>
      <p className={styles.title}>{title}</p>
      {licenses}
    </div>
  );
};
