import { FC } from "react";
import cn from "classnames";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { KaskoProtectCard, KaskoProtectCardProps } from "@/components/elements/kasko-protect-card";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Appearance, StrapiData, Title as ITitle } from "@/components/sections/types";
import { Title } from "@/components/shared/title";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

interface Data {
  cards: KaskoProtectCardProps[];
  title: ITitle;
  appearance: Appearance;
}

export const KaskoProtectCards: FC<StrapiData<Data>> = ({ data: { cards, title, appearance } }) => {
  const slides = cards.map((card) => (
    <SwiperSlide className={styles.slide} key={`kasko_protect_card_${card.title}`}>
      <KaskoProtectCard {...card} />
    </SwiperSlide>
  ));

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title {...title} className={styles.content} />

        <div className={cn(styles.container, styles.desktop, styles.content)}>
          <div className={styles.desktopCards}>
            {cards.map((card) => (
              <KaskoProtectCard {...card} key={card.title} />
            ))}
          </div>
        </div>

        <Swiper
          slidesPerView={1.8}
          spaceBetween={4}
          breakpoints={{
            375: {
              slidesPerView: 2.3,
              spaceBetween: 28,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 26,
            },
            365: {
              slidesPerView: 2.2,
              spaceBetween: 8,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 26,
            },
            345: {
              slidesPerView: 2.1,
              spaceBetween: 8,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
            320: {
              slidesPerView: 1.9,
              spaceBetween: 8,
              slidesOffsetBefore: 10,
              slidesOffsetAfter: 16,
            },
          }}
          pagination={{
            clickable: true,
          }}
          className={styles.swiper}
        >
          {slides}
        </Swiper>
      </div>
    </AppearanceWrapper>
  );
};
