import React, { FC } from "react";
import Image from "next/image";
import cn from "classnames";
import { IconLink } from "@mafin/ui-kit";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title as ITitle, StrapiData, Image as IImage, Appearance, Link } from "@/components/sections/types";
import { Title } from "@/components/shared/title";
import { getStrapiMedia } from "@/utils/media";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import { useBreakpoints } from "@/utils/hooks/use-window-size";

import styles from "./index.module.scss";

export interface Data {
  title?: ITitle;
  text: string;
  image: IImage;
  imagePosition: "left" | "right";
  appearance: Appearance;
  link?: Link;
}

export const MarkdownWithImage: FC<StrapiData<Data>> = ({
  data: { title, image, appearance, text, imagePosition, link },
}) => {
  const imagePositionStyle = imagePosition === "left" ? styles.imageLeft : styles.imageRight;
  const imageStyle = imagePosition === "left" ? styles.left : styles.right;
  const { desktopOrLaptop, mobileOrMobileMini } = useBreakpoints();

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={cn(styles.container, imagePositionStyle)}>
        {title && mobileOrMobileMini && <Title className={styles.mobileTitle} {...title} />}
        <div className={styles.textPart}>
          {title && desktopOrLaptop && <Title className={styles.title} {...title} />}
          <HTMLFromRichTextEditor className={styles.markdown} htmlString={text} />
          {link && (
            <IconLink variant="tertiary" className={styles.link} {...strapiLinkComponentToAnchorProps(link)}>
              {link.text}
            </IconLink>
          )}
        </div>
        <div className={cn(styles.imageWrapper, imageStyle)}>
          <Image
            className={styles.image}
            alt={image.alternativeText}
            src={getStrapiMedia(image.url)}
            sizes="(max-width: 979px) 340px, 460px"
            objectFit="cover"
            layout="fill"
            quality={70}
          />
        </div>
      </div>
    </AppearanceWrapper>
  );
};
