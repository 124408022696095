import { FC } from "react";
import Link from "next/link";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Typography } from "@mafin/ui-kit";
import { Appearance, StrapiData } from "@/components/sections/types";
import { Border } from "@/components/shared/border";
import { ButtonLink } from "@/components/elements/button-link";
import { IArticle } from "@/utils/api/types/mafin-media";
import { MAFIN_MEDIA_ROUTES } from "@/components/mafin-media/utils/constants";
import { Container } from "@/components/shared/container";

import style from "./index.module.scss";

interface ActualMediaArticlesMobileProps {
  appearance: Appearance;
  articles: IArticle[] | null;
}

export const ActualMediaArticlesMobile: FC<StrapiData<ActualMediaArticlesMobileProps>> = ({
  data: { appearance, articles },
}) => {
  const renderArticles = () =>
    articles?.map((i) => (
      <Link href={`${MAFIN_MEDIA_ROUTES.media}/${i.rubric.slug}/${i.slug}`} key={i.id} passHref>
        <a>
          <Border className={style.item} shadow>
            <Typography variant="h4">{i.title}</Typography>
          </Border>
        </a>
      </Link>
    ));

  return articles?.length ? (
    <AppearanceWrapper appearance={appearance}>
      <Container>
        <Typography variant="h2" component="h2" className={style.title}>
          Актуальное от Mafin Media
        </Typography>
        <div className={style.items}>{renderArticles()}</div>
        <Link href={MAFIN_MEDIA_ROUTES.media} passHref>
          <ButtonLink variant="secondary" className={style.button}>
            Читать -&gt;
          </ButtonLink>
        </Link>
      </Container>
    </AppearanceWrapper>
  ) : null;
};
