import { memo, useCallback, useState } from "react";
import { Typography } from "@/components/shared/typography";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import cn from "classnames";
import { Cross, Question } from "@mafin/icons";

import styles from "./index.module.scss";

export interface CardProps {
  title?: string;
  frontText: string;
  backText: string;
  big?: boolean;
}

export const Card = memo<CardProps>(({ title, frontText, backText, big }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = useCallback(() => setIsFlipped((old) => !old), []);

  const renderTitle = useCallback(
    () =>
      title ? (
        <Typography useParagraph className={styles.title} type="h2">
          {title}
        </Typography>
      ) : null,
    [title]
  );

  return (
    <div
      data-testid="flipCardsCard"
      onClick={handleCardClick}
      className={cn(styles.container, isFlipped && styles.flipped, big && styles.big)}
    >
      <div className={styles.animationWrapper}>
        <div className={styles.front}>
          {renderTitle()}
          <HTMLFromRichTextEditor className={styles.text} htmlString={frontText} />
          <Question size="small" color="#FFFFFF" className={styles.icon} />
        </div>
        <div className={styles.back}>
          {renderTitle()}
          <HTMLFromRichTextEditor className={styles.text} htmlString={backText} />
          <Cross size="small" color="#FF0550" className={styles.icon} />
        </div>
      </div>
    </div>
  );
});
