import { FC, useEffect, useState } from "react";
import { Image, Link } from "@/components/sections/types";

import styles from "./card.module.scss";

export interface CardProps {
  title: string;
  image: Image;
  description: string;
  showMore: Link;
}

export const Card: FC<CardProps> = ({ title, showMore, description, image }) => {
  const [imageUrl, setImageUrl] = useState<string>();

  // Исправляет баг в swiper, при рендере на стороне сервера в loop режиме слайдера изображения в карточках не обновляются,
  // приходится перендеривать компонент ручками когда он отображается на стороне клиента
  useEffect(() => {
    setImageUrl(image?.url);
  }, [image.url]);

  const renderLink = () => {
    if (!showMore) return null;

    return (
      <a
        href={showMore.url}
        className={styles.showMore}
        rel={`noreferrer ${showMore.relNofollow ? "nofollow" : undefined}`}
        target={showMore.newTab ? "_blank" : "_self"}
      >
        {showMore.text}
      </a>
    );
  };

  return (
    <div className={styles.wrapper}>
      <img src={imageUrl} alt="icon" className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.description}>{description}</div>
      {renderLink()}
    </div>
  );
};
