import { FC } from "react";
import { INews } from "@/utils/api/types/mafin-media";
import cn from "classnames";
import { Card } from "@/components/mafin-media/sections/shared/news-list/components/card";
import { useWindowSize } from "@/utils/hooks/use-window-size";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

interface NewsListProps {
  className?: string;
  sliderInMobile?: boolean;
  news: INews[];
}

export const NewsList: FC<NewsListProps> = ({ news, className, sliderInMobile }) => {
  const size = useWindowSize();
  const mobileBreakpoint = 979;

  const renderSlider = () => {
    return (
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        className={styles.slider}
        pagination={{
          clickable: true,
        }}
      >
        {news.map((i) => (
          <SwiperSlide className={styles.slide} key={i.id}>
            <Card {...i} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const renderCards = () => {
    if (sliderInMobile && Number(size.width) <= mobileBreakpoint) {
      return renderSlider();
    }

    return (
      <div className={styles.desktopCards}>
        {news.map((i) => (
          <Card wide key={i.id} {...i} />
        ))}
      </div>
    );
  };

  return <div className={cn(styles.container, className)}>{renderCards()}</div>;
};
