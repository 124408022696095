import { FC } from "react";
import { Typography } from "@mafin/ui-kit";
import { Card } from "@/components/mafin-media/sections/shared/relevant-materials/components/Card";
import cn from "classnames";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import { IArticle } from "@/utils/api/types/mafin-media";
import { useBreakpoints } from "@/utils/hooks/use-window-size";

import styles from "./index.module.scss";

SwiperCore.use([Pagination]);

interface RelevantMaterialsProps {
  materials: IArticle[];
  title?: string;
  titleType?: "h2" | "h3";
  className?: string;
  openMaterialsInNewTab?: boolean;
}

export const RelevantMaterials: FC<RelevantMaterialsProps> = ({
  materials,
  className,
  title,
  titleType = "h3",
  openMaterialsInNewTab,
}) => {
  const { desktopOrLaptop, mobileMini, mobile } = useBreakpoints();

  const cards = materials.map((i) => (
    <Card
      inNewTab={openMaterialsInNewTab}
      articleSlug={i.slug}
      rubricSlug={i.rubric?.slug}
      key={i.id}
      publicationDate={i.publicationDate}
      image={i.bannerImage}
      title={i.shortTitle || i.title}
    />
  ));

  return (
    <div className={cn(className, styles.container)}>
      {title && (
        <Typography className={styles.title} variant={titleType || "h2"} component="h2">
          {title}
        </Typography>
      )}
      {(desktopOrLaptop || mobile) && <div className={styles.desktopCards}>{cards}</div>}
      {mobileMini && (
        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          className={styles.mobileCards}
          pagination={{
            clickable: true,
          }}
        >
          {cards.map((i, index) => (
            <SwiperSlide className={styles.slide} key={`relevant-${index}`}>
              {i}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
