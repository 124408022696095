import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import React, { FC } from "react";
import { Appearance, StrapiData } from "@/components/sections/types";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";

import styles from "./index.module.scss";

export interface Data {
  content: string;
  appearance: Appearance;
}

export const RichText: FC<StrapiData<Data>> = ({ data: { content, appearance } }) => {
  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.container}>
        <div className={styles.markdown}>
          <HTMLFromRichTextEditor htmlString={content} />
        </div>
      </div>
    </AppearanceWrapper>
  );
};
