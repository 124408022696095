import React from "react";
import { Breadcrumbs } from "@mafin/ui-kit";

import { Breadcrumb, Image, StrapiData } from "../types";

import styles from "./index.module.scss";

interface ProductBannerProps {
  breadcrumbs: Breadcrumb[];
  image: Image;
  title: string;
}

export const ProductBanner = ({ data }: StrapiData<ProductBannerProps>) => {
  const breadcrumbs = data.breadcrumbs?.map((breadcrumb: Breadcrumb) => {
    return { link: breadcrumb.link, name: breadcrumb.name };
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div className={styles.container}>
          <div>
            <h1 className={styles.title}>{data.title}</h1>
          </div>
          <div className={styles.logoWrapper}>
            <img src={data.image.url} alt="logo" className={styles.logo} />
          </div>
        </div>
      </div>
    </div>
  );
};
