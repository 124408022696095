export const NUMBER_OF_PARTICIPANTS = [
  {
    id: "2",
    name: "2 участника",
  },
  {
    id: ">2",
    name: "более 2-х участников",
  },
  {
    id: "1",
    name: "1 участник",
  },
];

export const DOCUMENTS = [
  {
    id: "европротокол",
    name: "европротокол",
  },
  {
    id: "расширенный европротокол",
    name: "расширенный европротокол",
  },
  {
    id: "протокол (постановление/определение)",
    name: "протокол (постановление/определение)",
  },
  {
    id: "иной документ",
    name: "иной документ",
  },
];
